import { counterView } from "../../../../apis/articles";
import style from "./card.module.scss";
import { NavLink } from "react-router-dom";

export default function Card({ data }) {
  // Calcule le timestamp seconds de data.date pour le rendre au format de lecture français dd/MM/YYY
  const date = new Date(data.date.seconds * 1000);
  const options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const publishDate = date.toLocaleDateString("fr-FR", options);

  const urlRead = data.url.replace(/\s/g, "-");

  const handleCount = (id) => {
    data.view++;
    counterView(id, data.view);
  };

  return (
    <article className={`${style.card}`} onClick={() => handleCount(data.url)}>
      <NavLink to={`/blog/${urlRead}`}>
        {data.banner && (
          <img src={data.banner} alt={`bannière ${data.title}`} />
        )}
        <div className={`${style.resume} d-flex f-column`}>
          <p>
            Posté le {publishDate} par {data.publish}
          </p>
          <h2>{data.title}</h2>
        </div>
      </NavLink>
    </article>
  );
}
