import { Link } from "react-router-dom";
import style from "./rs.module.scss";

export default function RS() {
  return (
    <nav className={`${style.rs}`}>
      <ul>
        <li>
          <Link
            to={"https://www.facebook.com/profile.php?id=61563242825617"}
            title="Facebook"
            target="_blank"
          >
            <i className="fa-brands fa-facebook"></i>
          </Link>
        </li>
        <li>
          <Link
            to={"https://www.linkedin.com/in/frédéric-szymañski"}
            title="LinkedIn"
            target="_blank"
          >
            <i className="fa-brands fa-linkedin"></i>
          </Link>
        </li>
        <li>
          <Link
            to={"https://www.instagram.com/coursdeguitareetbassedequalite/"}
            title="Instagram"
            target="_blank"
          >
            <i className="fa-brands fa-instagram"></i>
          </Link>
        </li>
        <li className={`${style.pro}`}>
          <Link
            to={"https://www.youtube.com/@fredericszymanski-coursdeg1568"}
            title="Youtube PRO"
            target="_blank"
          >
            <i className="fa-brands fa-youtube"></i>
          </Link>
        </li>
        <li>
          <Link
            to={"https://www.youtube.com/fredericszymanski"}
            title="Youtube"
            target="_blank"
          >
            <i className="fa-brands fa-youtube"></i>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
