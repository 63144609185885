export const guitar = {
  level1: [
    {
      page: [
        <strong>Page 1 : </strong>,
        "Premières notes et premiers rythmes.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 2 : </strong>,
        "Vos 2 premiers accords avec une première rythmique.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 4 : </strong>,
        "Début des exercices de rythmiques arpégées.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 10 : </strong>,
        "Etude d'un premier morceau en entier. (grille d'accords)",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        "La méthode prend ensuite ce rythme :",
        <br />,
        <strong>- 1 page de solfège/technique appliquée </strong>,
        "directement sur la guitare avec une nouvelle note et un nouveau rythme à chaque fois et de temps en temps une nouvelle technique.",
        <br />,
        <strong>- 1 page de grilles d'accords avec rythmiques </strong>,
        "avec un nouvel accord et plusieurs nouvelles rythmiques à chaque fois.",
        <br />,
        <strong>De 1 jusqu'à 8 morceaux </strong>,
        "entiers adaptés au niveau des techniques, notes et rythmes déja appris.",
        <br />,
        "- À la fin de chaque morceau, ",
        <strong>5 questions théoriques </strong>,
        "qui évoluent jusque la fin de la méthode vous aident à entrenir votre savoir.",
        <br />,
        <strong>- Un travail progressif des intervalles sur le manche </strong>,
        "va petit à petit vous aider à repérer les notes sur votre guitare, développer votre connaissance théorique et aiguiser votre oreille musicale.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 19a : </strong>,
        "Premier morceau de ",
        <strong>Nirvana : Molly's lips.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux de ",
        <strong>Nirvana </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 31a : </strong>,
        "Premier morceau des ",
        <strong>Rolling Stones : Satisfaction.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 34 : </strong>,
        "Premier morceau de ",
        <strong>Bob Marley & The Wailers: Redemption Song</strong>,
        <br />,
        "Il y a environ une dixaine de morceaux des ",
        <strong>Wailers </strong>,
        "disséminés dans la méthode.",
        <br />,
        "Il s'agit du premier morceau entièrement lu en notes de la méthode (après quelques hybrides solfège/grilles d'accords).",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 36 : </strong>,
        "Début du travail des découpes rythmiques en ",
        <strong>ternaire.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 41 : </strong>,
        "Premier morceau des ",
        <strong>Beatles: Let it be.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux des Beatles disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 44b : </strong>,
        "Premier morceau d'",
        <strong>AC/DC : Bonny.</strong>,
        <br />,
        "Il y a environ une dixaine de morceaux d'",
        <strong>AC/DC </strong>,
        "disséminés dans la méthode.",
        <br />,
        "C'est également un des premiers ",
        <strong>solos faciles </strong>,
        "présent dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 50r : </strong>,
        "Premier morceau de ",
        <strong>John Lee Hooker : Boom Boom.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
  ],
  level2: [
    {
      page: [
        <strong>Page 57 : </strong>,
        "Début de l'étude du blues dans toutes les tonalités et de l'étude et développement des ",
        <strong>solos improvisés.</strong>,
        <br />,
        "Méthode unique qui vous force petit à petit à vous ",
        <strong>
          développer l'oreille, improviser en rythme et faire sonner vos solos.
        </strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: "Le travail de solos improvisés continue de vous procurer un développement passif de l'oreille musicale.",
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 58e : </strong>,
        "Premier morceau de ",
        <strong>System of a Down: Lonely Day</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 060a : </strong>,
        "Premier morceau de ",
        <strong>Pantera: Walk.</strong>,
        <br />,
        "Il y a environ une dixaine de morceaux de ",
        <strong>Pantera </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 61e : </strong>,
        "Premier morceau de ",
        <strong>ZZ Top : La grange</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 63 : </strong>,
        "Début de la lecture avec positions et début du travail des ",
        <strong>découpes rythmiques à base de doubles croches.</strong>,
        <br />,
        "Le répertoire devient beaucoup plus funky à partir d'ici.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 065h : </strong>,
        "Premier morceau de ",
        <strong>Dire straits : Sultans of swing</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 65p : </strong>,
        "Premier morceau de ",
        <strong>Metallica : Jump in the fire.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux de ",
        <strong>Metallica </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 70 : </strong>,
        "Premier Morceau de ",
        <strong>The Doors : Break on Through</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 73e : Aerosmith & Run DMC - Walk this way</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 73i : </strong>,
        "Premier morceau de ",
        <strong>Led Zeppelin: Communication Breakdown.</strong>,
        <br />,
        "Il y a environ une dixaine de morceaux de ",
        <strong>Led Zeppelin </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 073y : </strong>,
        "Premier morceau de ",
        <strong>The Police: Every Breath you Take</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 079u : </strong>,
        "Premier morceau de ",
        <strong>Stevie Wonder: Master blaster</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 84a : </strong>,
        "Premier morceau des ",
        <strong>Guns n' Roses: Sweet Child O Mine</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 90j : </strong>,
        "Premier morceau de ",
        <strong>Sepultura : Roots bloody roots.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 90l : </strong>,
        "Premier morceau de ",
        <strong>Muse : Hysteria</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
  ],
  level3: [
    {
      page: [
        "Approximativement à partir d'ici et pour toute la durée du niveau avancé, le niveau des morceaux travaillés se stabilise autour d'un ",
        <strong>niveau pro basique (pro non virtuose si vous préférez) </strong>,
        "ce qui vous permet de très progressivement vous rapprocher de ce niveau.",
        <br />,
        "À cet endroit, la méthode est littéralement bourrée de morceaux, vous prennez de la bouteille en développant votre répertoire.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        "Le travail du répertoire en plus de tout le reste continue de vous procurer un développement passif de ",
        <strong>l'oreille musicale.</strong>,
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 90q : </strong>,
        "Premier morceau de ",
        <strong>Rage Against the Machine : Bulls on Parade.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 90y : </strong>,
        "Premier morceau de ",
        <strong>Queen: We will rock you</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 95a : </strong>,
        "Premier morceau des ",
        <strong>Red hot chili peppers : Higher ground.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux des ",
        <strong>Red Hot Chili Peppers </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 98w - Solo de Let it be des Beatles</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 110e : Stairway to heaven de Led Zeppelin.</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 110v : </strong>,
        "Premier morceau de ",
        <strong>Pink Floyd: Comfortably Numb.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 115k : </strong>,
        "Premier morceau de ",
        <strong>Jimi Hendrix: All along the watchtower.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux de ",
        <strong>Jimi Hendrix </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 116 : </strong>,
        "Début du travail des découpes rythmiques à base de ",
        <strong>sextolets.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [<strong>Page 119 : Début de l'étude du trinaire.</strong>],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 123 : </strong>,
        "Début de l'étude des voicings en triades (applicables au rythmiques ",
        <strong>funk et jazz </strong>,
        "mais aussi aux ",
        <strong>solos de rock</strong>,
        ").",
        <br />,
        "Début de l'ajout progressif de blue notes dans ",
        <strong>vos solos improvisés en gammes pentatoniques.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 131a : </strong>,
        "Premier morceau d'",
        <strong>Iron Maiden : Aces High.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>
          Page 134b : Guns & roses - Knocking on heaven's door (avec les solos)
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
  ],
  level4: [
    {
      page: [
        <strong>Page 137 : </strong>,
        `Fin de l'apprentissage de la lecture solfègique sur l'instrument et début du travail `,
        <strong>“gammes et technique”.</strong>,
        <br />,
        <strong>Mode "Conservatoire" </strong>,
        `de la méthode: Votre niveau est grosso-modo équivalent à un élève en 1ère année de conservatoire à rayonnement régional mais avec des bases BEAUCOUP PLUS SOLIDES, de plus, nous continuons d'avancer avec un apprentissage hybride rock / jazz / classique là où les CRR vous forcent à vous spécialiser vous coupant du savoir venant des familles musicales qui ne sont pas les votres.`,
        <br />,
        `L'étude théorique et l'analyse de morceaux/compositions se développe beaucoup plus vite à partir d'ici.`,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 138 : </strong>,
        "Début des exercices de ",
        <strong>vélocité fingerpicking, </strong>,
        "début de la phase d'initiation au ",
        <strong>sweeping.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        "Début de jeux et exercices progressifs visant à développer activement votre ",
        <strong>oreille musicale.</strong>,
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 139 : </strong>,
        "Début de l'étude de ",
        <strong>transcriptions des plus célèbres solos de guitare.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 146 : Solo de Europa de Carlos Santana</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 149 : </strong>,
        "Début du travail des mélanges entre les ",
        <strong>
          gammes pentatoniques blues et majeures/mineures naturelles dans vos
          solos improvisés.
        </strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 160 : Solo de Parisienne walkways de Gary moore.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>
          Page 161 : Début du travail des ornements sur les voicings de triades
          (typiques des accompagnements funk ou à la Jimi Hendrix).
        </strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>
          Page 162 : Début du travail approfondi du démanché sur les gammes et
          les arpèges.
        </strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>
          Page 163 : Début des exercices progressifs de sweeping.
        </strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>
          Page 164b : Solo de Stairway to Heaven de led Zeppelin (Jimmy Page).
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 164d : </strong>,
        "Début de l'étude de ",
        <strong>préludes et suites de J.S.Bach.</strong>,
        <br />,
        "Beaucoup de morceaux du ",
        <strong>
          répertoire classique adaptés pour la guitare électrique{" "}
        </strong>,
        "vont ensuite faire leur apparition dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 164w : </strong>,
        "Premier morceau de ",
        <strong>Joe Satriani: Always with Me, Always with you.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 179 : Début des exercices progressifs de tapping.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>
          Page 180 : Thunderstruck d'ACDC (partie d'Angus Young).
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 183 : Début des exercices progressifs de legato.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",  
    },
    {
      page: [
        <strong>Page 184x : </strong>,
        "Début du travail d'études de styles (genres musicaux, morceaux à trous avec variantes à improviser) + ",
        <strong>
          Étude d'accompagnements improvisés en voicings sur des grilles de type
          Motown/Beatles.
        </strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 185 : </strong>,
        "Début de l'étude des ",
        <strong>voicings en tétrades </strong>,
        "(applicables au rythmiques ",
        <strong>funk et jazz</strong>,
        ").",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 196 : </strong>,
        "Début de l'étude et du travail méticuleux des ",
        <strong>solos improvisés </strong>,
        "sur les modes et de l'accompagnement modal.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>
          Page 203a : Sweet child O mine des Guns & Roses : Partie et solo de
          Slash.
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 209g : Premier morceau de Meshuggah : Sublevels</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 210 : </strong>,
        "Début de l'étude approfondie des ",
        <strong>solos improvisés </strong>,
        "avec modulations et notes cibles.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>
          Page 211 : Début de l'étude du comping (accompagnement jazz).
        </strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 214 : </strong>,
        "Début de l'étude de gammes, improvisations et progressions d'accords en ",
        <strong>mineur harmonique.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 230 : </strong>,
        "Introduction au ",
        <strong>jazz </strong>,
        "avec ",
        <strong>So What de Miles Davis.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 240 : </strong>,
        "Début de l'étude du ",
        <strong>vrai phrasé blues </strong>,
        "(qui vous donnera accès au vrai phrasé bebop plus tard).",
      ],
      type: "impro / compo",
      color: "brown",
    },
  ],
  level5: [
    {
      page: [
        <strong>Page 251i : Antonio Vivaldi - Printemps - Largo.</strong>,
        <br />,
        "La méthode comporte les 12 mouvements des ",
        <strong>4 saisons de Vivaldi </strong>,
        "adaptés par mes soins pour guitare électrique.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 265 : </strong>,
        "Début du travail des standards de ",
        <strong>jazz </strong>,
        "avec à chaque fois une transcription de comping d'un grand guitariste, une transcription de chorus d'un grand jazzman à travailler et des exercices précis et progressifs à appliquer dans vos improvisations.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>
          Page 312e : Jimi Hendrix - Voodoo child (slight return)
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 321 : </strong>,
        "Début de l'étude de gammes, improvisations et progressions d'",
        <strong>accords en mineur mélodique.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 323 : </strong>,
        "Premier morceau et improvisation dans le style ",
        <strong>Jazz Manouche: Minor Swing.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [<strong>Page 323d : Joe Satriani - Midnight</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 331 : </strong>,
        "À partir de cet endroit vous avez ",
        <strong>
          une transcription de morceau rock à réaliser régulièrement
        </strong>,
        ", ces transcriptions sont corrigées par mes soins et alimentent la méthode.",
        <br />,
        "Vous aurez tout d'abord 10 transcriptions imposées (progressivité de l'exercice oblige) et pourrez ensuite avoir le libre choix des morceaux que vous voulez retranscrire et ajouter à la méthode.",
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [<strong>Page 333c : Buckethead - Earth heals herself</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 346 : </strong>,
        "Les études de styles se transforment en exercices où vous devez composer dans le style d'un groupe donné (imiter).",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [<strong>Page 352 : Stevie Ray Vaughan - Texas Flood</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 370 : </strong>,
        "Oups! J'ai oublié de retranscrire le comping sur ce standard de jazz, on dirait que ça va être à vous de le faire et ce jusqu'à la fin de la méthode.",
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 379d : Dire straits - Sultans of swing (solo)</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 388 : </strong>,
        "Ma transcription du ",
        <strong>solo de Scrapple from the Apple de Charlie Parker </strong>,
        "comporte 8 mesures manquantes, vous devez les retranscrire.",
        <br />,
        "Le nombre de mesures manquantes augmentera à chaque transcription jusqu'à ce que vous puisssiez ",
        <strong>retranscrire un solo de jazz entièrement et seul.</strong>,
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [<strong>Page 397b : Al Di Meola - Egyptian Danza</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 397 : Joaquin Rodriguo - Concerto d'Aranjuez - I.</strong>,
        <br />,
        "La méthode comporte les 3 mouvements du ",
        <strong>Concerto D'Aranjuez </strong>,
        "adaptés par mes soins pour guitare électrique.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 417 : Pat Metheny - Bright size life.</strong>],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [<strong>Page 428d : Ozzy Osbourne - Mr. Crowley</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>
          Page 501a : Michael Jackson - Beat It (solo de Van Halen)
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 541k : Pantera - Walk (solo)</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 541l : Van Halen - Eruption</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 553a : Yngwie Malmsteen - Black Star</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 555 : Dernière page de la méthode</strong>],
      type: "technique / théorie",
      color: "yellow",
    },
  ],
};
