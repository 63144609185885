import { NavLink } from "react-router-dom";
import style from "./burger.module.scss";
import { useState } from "react";

export default function Burger() {
  const [burger, setBurger] = useState(false);

  return (
    <nav className={`${style.nav} d-flex aic`}>
      {!burger ? (
        <i onClick={() => setBurger(!burger)} className="fa-solid fa-bars"></i>
      ) : (
        <div className={`${style.ulContainer}`}>
          <i
            onClick={() => setBurger(!burger)}
            className="fa-solid fa-xmark"
          ></i>
          <ul className={`${style.ul}`}>
            <li>
              <NavLink
                title="ACCUEIL"
                to={"/"}
                onClick={() => setBurger(!burger)}
              >
                ACCUEIL
              </NavLink>
            </li>
            <li>
              <NavLink
                title="PRÉSENTATION"
                to={"/biographie"}
                onClick={() => setBurger(!burger)}
              >
                PRÉSENTATION
              </NavLink>
            </li>  
            <li>
              <NavLink
                title="COURS ET MÉTHODES"
                to={"/cours"}
                onClick={() => setBurger(!burger)}
              >
                MÉTHODES
              </NavLink>
            </li>
            <li>
              <NavLink
                title="AVIS"
                to={"/avis"}
                onClick={() => setBurger(!burger)}
              >
                AVIS
              </NavLink>
            </li>
            <li>
              <NavLink
                title="INTERACTIF"
                to={"/interactif"}
                onClick={() => setBurger(!burger)}
              >
                INTERACTIF
              </NavLink>
            </li>
            <li>
              <NavLink
                title="ARTICLES"
                to={"/blog"}
                onClick={() => setBurger(!burger)}
              >
                ÉCRITS
              </NavLink>
            </li>   
            <li>
              <NavLink
                title="CONTACT"
                to={"/contact"}
                onClick={() => setBurger(!burger)}
              >
                CONTACT
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}
