import style from "./rate.module.scss";
import mainStyle from "../../homepage.module.scss";
import { rate } from "../../../../json/rate/rate";
import { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import Scroller from "../../../../components/scroller/scroller";
import CarouselRate from "./components/carousel";
import ShortDivider from "../../../../components/divers/shortDiver";
import BigCard from "./components/cards/bigCard";

export default function Rate() {
  const [randomRate, setRandomRate] = useState([]);
  const [readMore, setReadMore] = useState(false);
  const [container, setContainer] = useState(null);

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // générateur d'étoile -> function appelée dans l'HTML
  function createRate(n) {
    const star = [];
    const avoid = [];
    // création des étoiles pleines
    for (let i = 0; i < n; i++) {
      star.push(<i className="fa-solid fa-star"></i>);
    }
    // création des étoile vides
    for (let i = 0; i < 5 - n; i++) {
      avoid.push(<i className="fa-regular fa-star"></i>);
    }
    // fusion des deux tableaux
    return new Array(star, avoid);
  }

  const notes = rate.map((a) => a.rate);
  const moyenne = calculerMoyenne(notes);

  function calculerMoyenne(nombres) {
    let somme = 0;
    let nombreElements = nombres.length;

    for (let nombre of nombres) {
      somme += nombre;
    }

    if (nombreElements === 0) {
      return 0;
    }

    return somme / nombreElements;
  }

  useEffect(() => {
    const randomArray = () => {
      const shuffleObjects = [...rate];
      shuffle(shuffleObjects);

      const randomThree = shuffleObjects.slice(0, 5);
      setRandomRate(randomThree);
    };
    randomArray();
  }, []);

  return (
    <section
      id={"avis"}
      className={`${style.rate} ${mainStyle.section} d-flex jcc`}
    >
      <div className={`${style.informations} d-flex f-column aic`}>
        <h2
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="300"
        >
          LES AVIS
        </h2>
        <ShortDivider />
        <div
          className={style.h3}
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="300"
        >
          <h3>{rate.length} élèves en parlent !</h3>
          <div className={`${style.stars} d-flex`}>
            Une moyenne de {createRate(moyenne)} !
          </div>
        </div>
      </div>
      <div
        className={`${style.container}`}
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <CarouselRate setReadMore={setReadMore} setContainer={setContainer} />
      </div>
      <div className={`${style.scroll} d-flex f-column aic`}>
        {/* <NavLink
          to={"/avis"}
          title="AVIS"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="300"
        >
          Voir tous les avis !
        </NavLink> */}
        <Scroller section={"news"} text={"Quoi de neuf ?"} />
      </div>
      {readMore && <BigCard data={container} readMore={setReadMore} />}
    </section>
  );
}
