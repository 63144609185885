import { Suspense, useEffect, useState } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import ShortHeader from "./components/short Header/shortHeader";
import Footer from "./components/footer/footer";
import Provider from "./services/provider/provider";
import ArticlesProvider from "./services/provider/articlesProvider";

function App() {
  const [loc, setLoc] = useState();
  const location = useLocation();

  useEffect(() => {
    setLoc(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Suspense>
        {loc !== "/" && <ShortHeader />}
        <ArticlesProvider>
          <Outlet />
        </ArticlesProvider>
        <Footer />
        <ScrollRestoration />
      </Suspense>
    </>
  );
}

export default App;
