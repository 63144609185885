import { useReducer } from "react";
import style from "./probabilité.module.scss";
import * as songs from "./bdd.js";
import { Helmet } from "react-helmet";
const majorSong = songs.default.majorSong;
const minorSong = songs.default.minorSong;

var accord; // Tonique de l'accord
var aaccord; // Type de l'accord
var babasse; // Basse de l'accord
var tonalite; // Tonalité dont l'accord fait partie
var atonalite; // Type de Tonalité dont l'accord fait partie (majeure ou mineure)

// HELPER FUNCTIONS

function GetAllIndexes(arr, val) {
  var indexes = [],
    i;
  for (i = 0; i < arr.length; i++) {
    if (arr[i] === val && i != -1) {
      indexes.push(i + 1);
    }
  }
  return indexes;
}

function refSort(targetData, refData) {
  // Create an array of indices [0, 1, 2, ...N].
  var indices = Object.keys(refData);

  // Sort array of indices according to the reference data.
  indices.sort(function (indexA, indexB) {
    if (refData[indexA] < refData[indexB]) {
      return -1;
    } else if (refData[indexA] > refData[indexB]) {
      return 1;
    }
    return 0;
  });

  // Map array of indices to corresponding values of the target array.
  return indices.map(function (index) {
    return targetData[index];
  });
}

// DA BIG BOY

function DisplayResults() {
  if (!accord) {
    return;
  }

  {
    /*  2 échelles de la gamme chromatique */
  }
  var Td = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  var Tb = [
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "Gb",
    "G",
    "Ab",
    "A",
    "Bb",
    "B",
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "Gb",
    "G",
    "Ab",
    "A",
    "Bb",
    "B",
  ];

  {
    /*  On y cherche la tonalité et l'accord */
  }
  var Nt = Td.indexOf(tonalite);
  var Na = Td.indexOf(accord);
  var Nbb = Td.indexOf(babasse);

  if (Na < 0) {
    Na = Tb.indexOf(accord);
  }
  if (Nbb < 0) {
    Nbb = Tb.indexOf(babasse);
  }

  var transpo;
  var Ct;
  var Ca;
  var Cbb;
  var Cbasse;
  var Ctonalite;
  var Caccord;

  {
    /* tonalités avec # ou sans altérations */
  }
  if (Nt >= 0 && Nt != 5) {
    {
      /* transpo en C majeur */
    }
    if (atonalite == "majeur") {
      transpo = 12 - Nt;
      Ct = Nt + transpo;
      Ca = Na + transpo;
      Cbb = Nbb + transpo;
      Cbasse = Td[Cbb];
      Ctonalite = Td[Ct];
      Caccord = Td[Ca];
    }

    {
      /* transpo en A mineur */
    }
    if (atonalite == "mineur") {
      transpo = 9 - Nt;
      Ct = Nt + transpo;
      Ca = Na + transpo;
      Cbb = Nbb + transpo;
      Cbasse = Td[Cbb];
      Ctonalite = Td[Ct];
      Caccord = Td[Ca];
    }
  }

  {
    /*  tonalités avec b */
  }
  if (Nt < 0 || Nt == 5) {
    Nt = Tb.indexOf(tonalite);

    {
      /* transpo en C majeur */
    }
    if (atonalite == "majeur") {
      transpo = 12 - Nt;
      Ct = Nt + transpo;
      Ca = Na + transpo;
      Cbb = Nbb + transpo;
      Cbasse = Tb[Cbb];
      Ctonalite = Tb[Ct];
      Caccord = Tb[Ca];
    }

    {
      /* transpo en A mineur */
    }
    if (atonalite == "mineur") {
      transpo = 9 - Nt;
      Ct = Nt + transpo;
      Ca = Na + transpo;
      Cbb = Nbb + transpo;
      Cbasse = Tb[Cbb];
      Ctonalite = Tb[Ct];
      Caccord = Tb[Ca];
    }
  }

  {
    /* Concaténation accord et attribut accord pour recherche */
  }
  var Faccord;
  if (babasse == "0" || babasse == Caccord || typeof babasse == "undefined") {
    Faccord = Caccord + aaccord;
  } else {
    Faccord = Caccord + aaccord + "/" + Cbasse;
  }

  var Ftonalite = tonalite + atonalite;

  // alert("Equivaut à " + Faccord + " en " + Ctonalite + " " + atonalite + '<br>');

  {
    /* On choisit la bonne bdd */
  }
  var song;
  if (atonalite == "majeur") {
    song = majorSong;
  } else if (atonalite == "mineur") {
    song = minorSong;
  }

  {
    /* boucle de recherche */
  }
  var liste = [];
  for (var prog in song) {
    {
      /* on évite le dernier accord de la liste */
    }
    var dernier = song[prog].length - 1;
    var testons = song[prog].lastIndexOf(Faccord);
    if (testons == dernier) {
      song[prog].pop();
    }

    var search = GetAllIndexes(song[prog], Faccord);
    for (var xx in search) {
      var found = search[xx];
      var dispc = song[prog][found];
      liste.push(dispc);
    }
  }

  {
    /* début du tableau html */
  }
  var Oaccord;
  if (babasse == "0" || babasse == accord || typeof babasse == "undefined") {
    Oaccord = accord + aaccord;
  } else {
    Oaccord = accord + aaccord + "/" + babasse;
  }

  var resultat;
  if (liste.length == 0 && typeof accord != "undefined") {
    return (
      <table>
        <tr>
          <th colspan="3">
            <font size="+2">
              Probabilités pour l'accord succédant {Oaccord} en tonalité de{" "}
              {tonalite} {` `} {atonalite} :
            </font>
          </th>
        </tr>
        <tr>
          <th>
            <br />
          </th>
        </tr>
        <tr>
          <th colspan="3">
            <font color="red">
              Aucun résultat pour l'instant, j'y travaille...
            </font>
          </th>
        </tr>
      </table>
    );
  } else if (liste.length == 1 && typeof accord != "undefined") {
    resultat = <>résultat</>;
  } else if (typeof accord != "undefined") {
    resultat = <>résultats</>;
  }

  liste.sort();
  var current = null;
  var cnt = 0;
  var total = liste.length;
  let fpercent = [];
  let fpercentNumbers = [];
  for (var i = 0; i <= liste.length; i++) {
    var pourcentage = (cnt / total) * 100;
    var pourcentagearr = Math.round(pourcentage * 100) / 100;
    if (liste[i] != current) {
      if (cnt > 0) {
        var curr1;
        var curr2;
        if (current.indexOf("#") == 1) {
          curr1 = current.substring(0, 2);
          curr2 = current.substring(2);
        } else {
          curr1 = current.substring(0, 1);
          curr2 = current.substring(1);
        }

        if (curr2.indexOf("/") != -1) {
          var slash = curr2.indexOf("/") + 1;
          var currm = curr2.substring(0, slash);
          curr2 = curr2.substring(slash);
          var icurr2 = Td.lastIndexOf(curr2);
          if (icurr2 < 0) {
            icurr2 = Tb.lastIndexOf(curr2);
          }

          {
            /*  tonalités avec b */
          }
          if (
            Ftonalite.indexOf("b") != -1 ||
            Ftonalite == "Fmajeur" ||
            Ftonalite == "Dmineur" ||
            Ftonalite == "Gmineur" ||
            Ftonalite == "Cmineur" ||
            Ftonalite == "Fmineur"
          ) {
            {
              /* retranspo à partir de C majeur */
            }
            if (atonalite == "majeur") {
              var itrans2 = icurr2 - transpo;
              curr2 = Tb[itrans2];
            }

            {
              /* retranspo à partir de A mineur */
            }
            if (atonalite == "mineur") {
              var itrans2 = icurr2 - transpo;
              curr2 = Tb[itrans2];
            }

            {
              /* tonalités avec # ou sans altérations */
            }
          } else {
            {
              /* retranspo à partir de C majeur */
            }
            if (atonalite == "majeur") {
              var itrans2 = icurr2 - transpo;
              curr2 = Td[itrans2];
            }

            {
              /* retranspo à partir de A mineur */
            }
            if (atonalite == "mineur") {
              var itrans2 = icurr2 - transpo;
              curr2 = Td[itrans2];
            }
          }

          curr2 = currm + curr2;
        }
        var icurr1 = Td.lastIndexOf(curr1);
        if (icurr1 < 0) {
          icurr1 = Tb.lastIndexOf(curr1);
        }

        {
          /* tonalités avec b */
        }
        if (
          Ftonalite.indexOf("b") != -1 ||
          Ftonalite == "Fmajeur" ||
          Ftonalite == "Dmineur" ||
          Ftonalite == "Gmineur" ||
          Ftonalite == "Cmineur" ||
          Ftonalite == "Fmineur"
        ) {
          {
            /* retranspo à partir de C majeur */
          }
          if (atonalite == "majeur") {
            var itrans = icurr1 - transpo;
            var curr1 = Tb[itrans];
          }

          {
            /*  retranspo à partir de A mineur */
          }
          if (atonalite == "mineur") {
            var itrans = icurr1 - transpo;
            var curr1 = Tb[itrans];
          }

          {
            /*  tonalités avec # ou sans altérations */
          }
        } else {
          {
            /*  retranspo à partir de C majeur */
          }
          if (atonalite == "majeur") {
            var itrans = icurr1 - transpo;
            curr1 = Td[itrans];
          }

          {
            /*   retranspo à partie de A mineur  */
          }
          if (atonalite == "mineur") {
            var itrans = icurr1 - transpo;
            curr1 = Td[itrans];
          }
        }
        curr2 = curr2.replace("Â°", "°");
        if (pourcentagearr < 10) {
          pourcentagearr = <> {pourcentagearr}</>;
        }
        fpercentNumbers.push(cnt);
        fpercent.push(
          <tr>
            <td align="right">
              <font size="+2">{pourcentagearr}%</font>
            </td>
            <td align="center">
              <font size="-2">
                <i>pour le</i>
              </font>
            </td>
            <td align="left">
              <font size="+3">
                {curr1}
                {curr2}
              </font>
            </td>
          </tr>
        );
      }
      current = liste[i];
      cnt = 1;
    } else {
      cnt++;
    }
  }

  let output = refSort(fpercent, fpercentNumbers);
  output.reverse();

  return (
    <table>
      <tr>
        <th>
          <br />
        </th>
      </tr>
      <tr>
        <th colspan="3">
          <font size="+2">
            Probabilités pour l'accord succédant {Oaccord} en tonalité de{" "}
            {tonalite} {` `} {atonalite} :
          </font>
        </th>
      </tr>
      <tr>
        <th>
          <br />
        </th>
      </tr>
      <tr>
        <th colspan="3">
          Sur <font color="red">{liste.length} </font>
          {resultat} :
        </th>
      </tr>
      {output}
    </table>
  );
}

function GetDataFromForm({ forceUpdate }) {
  const a = document.getElementById("a");
  accord = a.value;
  const aa = document.getElementById("aa");
  aaccord = aa.value;
  const bs = document.getElementById("bs");
  babasse = bs.value;
  const t = document.getElementById("t");
  tonalite = t.value;
  const at = document.getElementById("at");
  atonalite = at.value;

  forceUpdate();
}

function GenerateForm({ forceUpdate }) {
  return (
    <>
      <b>Votre accord : </b>
      <select name="a" id="a">
        <option value="C">C</option>
        <option value="C#">C#</option>
        <option value="Db">Db</option>
        <option value="D">D</option>
        <option value="D#">D#</option>
        <option value="Eb">Eb</option>
        <option value="E">E</option>
        <option value="F">F</option>
        <option value="F#">F#</option>
        <option value="Gb">Gb</option>
        <option value="G">G</option>
        <option value="G#">G#</option>
        <option value="Ab">Ab</option>
        <option value="A">A</option>
        <option value="A#">A#</option>
        <option value="Bb">Bb</option>
        <option value="B">B</option>
      </select>

      <select name="aa" id="aa">
        <option value="">majeur</option>
        <option value="m">mineur</option>
        <option value="+">+</option>
        <option value="°">°</option>
        <option value="m7">m7</option>
        <option value="Δ">Δ</option>
        <option value="7">7</option>
        <option value="m7b5">m7b5</option>
        <option value="°7">°7</option>
        <option value="mΔ">mΔ</option>
        <option value="Δ#5">Δ#5</option>
        <option value="6">6</option>
        <option value="m6">m6</option>
        <option value="sus4">sus4</option>
        <option value="sus2">sus2</option>
        <option value="7sus4">7sus4</option>
        <option value="7#5">7#5</option>
        <option value="9">9</option>
        <option value="7#9">7#9</option>
        <option value="7b9">7b9</option>
      </select>
      <b> / </b>
      <select name="bs" id="bs">
        <option value="0"> </option>
        <option value="C">C</option>
        <option value="C#">C#</option>
        <option value="Db">Db</option>
        <option value="D">D</option>
        <option value="D#">D#</option>
        <option value="Eb">Eb</option>
        <option value="E">E</option>
        <option value="F">F</option>
        <option value="F#">F#</option>
        <option value="Gb">Gb</option>
        <option value="G">G</option>
        <option value="G#">G#</option>
        <option value="Ab">Ab</option>
        <option value="A">A</option>
        <option value="A#">A#</option>
        <option value="Bb">Bb</option>
        <option value="B">B</option>
      </select>

      <br />
      <b>La tonalité globale du morceau : </b>
      <select name="t" id="t">
        <option value="C">C</option>
        <option value="C#">C#</option>
        <option value="Db">Db</option>
        <option value="D">D</option>
        <option value="D#">D#</option>
        <option value="Eb">Eb</option>
        <option value="E">E</option>
        <option value="F">F</option>
        <option value="F#">F#</option>
        <option value="Gb">Gb</option>
        <option value="G">G</option>
        <option value="G#">G#</option>
        <option value="Ab">Ab</option>
        <option value="A">A</option>
        <option value="A#">A#</option>
        <option value="Bb">Bb</option>
        <option value="B">B</option>
      </select>

      <select name="at" id="at">
        <option value="majeur">majeur</option>
        <option value="mineur">mineur</option>
      </select>

      <br />
      <button
        className={`${style.inactive}`}
        onClick={() => GetDataFromForm({ forceUpdate })}
      >
        Résultat
      </button>
    </>
  );
}

export default function Proba() {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const form = GenerateForm({ forceUpdate });
  const results = DisplayResults();

  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Interactif : probabilités</title>
      </Helmet>
      <main className={`${style.proba} d-flex`}>
        <section>
          <h1>AIDE À LA COMPOSITION PAR LA PROBABILITÉ</h1>
          <p>
            Entrez votre accord et la tonalité globale du morceau, des
            probabilités pour l'accord suivant (basées sur un nombre important
            d'œuvres existantes) vous seront données.
          </p>
          {form}
          {results}
        </section>
      </main>
    </>
  );
}
