import style from "./divers.module.scss";

export default function ShortDivider() {
  return (
    <div
      className={`${style.shortDivider}`}
      data-aos="fade-up"
      data-aos-anchor-placement="bottom-bottom"
      data-aos-delay="300"
    ></div>
  );
}
