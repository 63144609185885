import { NavLink } from "react-router-dom";
import style from "./shortHeader.module.scss";
import logo from "../../assests/images/header logo Fred 4 color.png";
import Burger from "./components/burger";
import { useEffect, useState } from "react";
import methodeGuitare from "../../assests/images/methodeguitare.jpg";
import methodeBasse from "../../assests/images/methodebasse.jpg";
import impro from "../../assests/images/impro.png";
import ear from "../../assests/images/eartrainer.png";
import proba from "../../assests/images/proba.png";
import Constants from "../../oldies/stats.js";

export default function ShortHeader() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className={`${style.header}`}>
      <div className={`${style.headerContainer} d-flex aic jcsb`}>
        <NavLink className={`${style.linkLogo}`}>
          <img className={`${style.logo}`} src={logo} alt="" />
        </NavLink>
        {pageWidth > 768 ? (
          <nav className={`${style.nav}`}>
            <ul className={`${style.ul} d-flex aic jcc`}>
              <li>
                <NavLink title="PRÉSENTATION" to={"/biographie"}>
                  PRÉSENTATION
                </NavLink>
              </li>
              <li className={`${style.wallet}`}>
                <NavLink title="COURS ET MÉTHODES" to={"/cours"}>
                  MÉTHODES
                </NavLink>
                <ul className={`${style.course}`}>
                  <li>
                    <NavLink title="GUITARE" to={"/cours/guitare"}>
                      <h2>GUITARE</h2>
                      <div
                        className={`${style.content} d-flex f-column aic jcc`}
                      >
                        <div className="d-flex aic jcc">
                          <img
                            className={`${style.methode}`}
                            src={methodeGuitare}
                            alt="Continuum Guitare"
                          />
                          <p className="m5">
                            Les cours avec Continuum guitare, une méthode de{" "}
                            {Constants.pagesguitare} pages qui a fait ses
                            preuves depuis 2009,
                            <br />
                            elle convient aussi bien à des débutants sans
                            prérequis voulant apprendre correctement qu'à des
                            musiciens professionnels pouvant l'utiliser comme
                            "daily grind".
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink title="BASSE" to={"/cours/basse"}>
                      <h2>BASSE</h2>
                      <div
                        div
                        className={`${style.content} d-flex f-column aic jcc`}
                      >
                        <div className="d-flex aic jcc">
                          <img
                            className={`${style.methode}`}
                            src={methodeBasse}
                            alt="Continuum Basse"
                          />
                          <p className="m5">
                            Les cours avec Continuum basse, une méthode de{" "}
                            {Constants.pagesbasse} pages qui a fait ses preuves
                            depuis 2009,
                            <br />
                            elle convient aussi bien à des débutants sans
                            prérequis voulant apprendre correctement qu'à des
                            musiciens professionnels pouvant l'utiliser comme
                            "daily grind".
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink title="AVIS" to={"/avis"}>
                  AVIS
                </NavLink>
              </li>
              <li className={`${style.wallet}`}>
                <NavLink title="INTERATIF" to={"/interactif"}>
                  INTERACTIF
                </NavLink>
                <ul className={`${style.course} d-flex jcse`}>
                  <li>
                    <NavLink title="GUITARE" to={"/interactif/improvisation"}>
                      <h2>IMPRO</h2>
                      <div
                        className={`${style.content} d-flex f-column aic jcc`}
                      >
                        <div className="d-flex f-column aic jcc">
                          <img
                            className={`${style.methode}`}
                            src={impro}
                            alt="impro image"
                          />
                          <p className="m5">IMPROVISATION JOURNALIÈRE</p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink title="BASSE" to={"/interactif/eartrainer"}>
                      <h2>EAR TRAINER</h2>
                      <div
                        div
                        className={`${style.content} d-flex f-column aic jcc`}
                      >
                        <div className="d-flex f-column aic jcc">
                          <img
                            className={`${style.methode}`}
                            src={ear}
                            alt="Ear Trainer image"
                          />
                          <p className="m5">TRAVAIL DE L'OREILLE MUSICALE</p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink title="BASSE" to={"/interactif/proba"}>
                      <h2>PROBA</h2>
                      <div
                        div
                        className={`${style.content} d-flex f-column aic jcc`}
                      >
                        <div className="d-flex f-column aic jcc">
                          <img
                            className={`${style.methode}`}
                            src={proba}
                            alt="Proba image"
                          />
                          <p className="m5">COMPOSITION & PROBABILITÉS.</p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink title="BLOG" to={"/blog"}>
                  ÉCRITS
                </NavLink>
              </li>             
              <li>
                <NavLink title="CONTACT" to={"/contact"}>
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </nav>
        ) : (
          <Burger />
        )}
      </div>
    </header>
  );
}
