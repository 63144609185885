import { Helmet } from "react-helmet";
import About from "./components/about/about";
import Header from "./components/header/header";
import Interaction from "./components/interactions/interaction";
import Methode from "./components/methode/methode";
import News from "./components/news/news";
import Rate from "./components/rate/rate";
import style from "./homepage.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function Homepage() {
  return (
    <>
      <Helmet>
        <title>
          Frédéric Szymañski - Cours de guitare et basse de qualité !
        </title>
      </Helmet>
      <div className={`${style.container}`}>
        <Header />
        <main id="main" className={`${style.main}`}>
          <About />
          <Methode />
          <Interaction />
          <Rate />
          <News />
        </main>
      </div>
    </>
  );
}
