import style from "../../../../../rate/components/card/card.module.scss";
import { useNavigate } from "react-router-dom";

export default function Card({ data, css, setReadMore, setContainer }) {
  // const [readMore, setReadMore] = useState(false);
  const navigate = useNavigate();

  // générateur d'étoile -> function appelée dans l'HTML
  function createRate(n) {
    const star = [];
    const avoid = [];
    // création des étoiles pleines
    for (let i = 0; i < n; i++) {
      star.push(<i className="fa-solid fa-star"></i>);
    }
    // création des étoile vides
    for (let i = 0; i < 5 - n; i++) {
      avoid.push(<i className="fa-regular fa-star"></i>);
    }
    // fusion des deux tableau
    return new Array(star, avoid);
  }

  return (
    <>
      <article
        onClick={() => data.name === "see more" && navigate("/avis")} // css && navigate("/avis")
        className={`${style.card}
        ${css && style.crsl}
        ${data.name === "see more" && style.more}
         d-flex f-column jcc flex-fill`}
      >
        {data.name === "see more" ? (
          <div className={`${style.moreRate} d-flex aic jcc`}>
            <strong>{data.text}</strong>
          </div>
        ) : (
          <>
            <div className={`${style.head} d-flex aic`}>
              <div className={`${style.img}`}>
                <img src={process.env.PUBLIC_URL + "/img/" + data.img} alt="" />
              </div>
              <div>
                <h2>{data.name}</h2>
                <h2 className={`${style.instru}`}>{data.instru}</h2>
              </div>
            </div>
            <div className={`${style.stars} d-flex`}>
              {createRate(data.rate)}
            </div>
            <div className={`${style.rate}`}>
              <p className={style.shortText}>
                {data.text}
                <div className={style.gradient}></div>
              </p>
            </div>
            <div className={`${style.readMore} d-flex jce`}>
              <p
                onClick={() => {
                  setReadMore(true);
                  setContainer(data);
                }}
              >
                Lire plus
              </p>
            </div>
          </>
        )}
      </article>
    </>
  );
}
