export const albums = [
  {
    strong: "Wattage",
    flag: "FR",
    texte: "Reprises rock (chant puis basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Punky Funky Crew",
    flag: "FR",
    texte: "Fusion metal/funk (basse et composition)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Super Salt Peanuts",
    flag: "FR",
    texte: "Reprises rock (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Lamia",
    flag: "FR",
    texte: `Trip-hop (basse & contrebasse, arrangements) entre autres un concert aux 4écluses (dunkerque), un clip tourné pour une TV belge et enregistrement d'un CD "Into the Number of the Nameless Tides" (2004) en studio.`,
    ul: [
      {
        li: "Lamia - Into the Number of the Nameless Tides - 2004",
        img: "lamia",
        title: "Lamia - Into the Number of the Nameless Tides - 2004",
      },
    ],
  },
  {
    strong: "Original Smokaz",
    flag: "FR",
    texte:
      "Reggae (basse) entre autres un concert à l'Aéronef (Lille), plusieurs enregistrements en studio titre par titre, immersion dans la culture et la musique reggae.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Gork",
    flag: "FR",
    texte: "Math-metal (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Trio Évidence",
    flag: "FR",
    texte: "Standards jazz (contrebasse)",
    img: "",
  },
  {
    strong: "Quintet ????",
    flag: "FR",
    texte: `Accompagnement de la piece de théatre "Histoires courtes mais vraies ou… presque" de Vincent Goethals (1999-2000) au bateau feu à Dunkerque (contrebasse)`,
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Quintet Évidence",
    flag: "FR",
    texte:
      "Jazz moderne (basse) tournée en Allemagne et festival de jazz d'Erfurt.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Astatic Jazz Trio",
    flag: "FR",
    texte:
      "Jazz moderne (basse & composition) enregistrement d'un CD 4 titres en studio, première partie de Pifarelly-Couturier-Corneloup au Jazz Club de Dunkerque, prestation en compagnie des frères Moutin au Tourcoing Jazz Festival.",
    ul: [
      {
        li: "Astatic Jazz Trio",
        img: "ajt",
        title: "Astatic Jazz Trio",
      },
    ],
  },
  {
    strong: "The Hip Hop Quintet",
    flag: "FR",
    texte:
      "Funk/jungle (basse & composition) enregistrement d'un CD/Bande son en studio pour une troupe de danse Hip Hop.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Fred Z Quartet",
    flag: "FR",
    texte: "Standards jazz (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Axone",
    flag: "FR",
    texte: "Reprises de Joe Satriani, Jeff Beck ... (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Le Quartet",
    flag: "FR",
    texte:
      "Standards de jazz (basse) entre autres un concert à l'Opéra de Lille, festival Jazz à Aix.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Tobaboo",
    flag: "FR",
    texte: "Musique africaine & blues (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Secteur Blues",
    flag: "FR",
    texte: "Reprises blues rock (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Tropical Combo",
    flag: "FR",
    texte: "Standards Guadeloupe Martinique en créole (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Les Magnolias",
    flag: "FR",
    texte: "Spectacle Claude François (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Fire Moon Buddah",
    flag: "FR",
    texte:
      "Tribute to Jimi Hendrix (basse), divers concerts dans la région et 1ere partie de Noel Redding (bassiste de Jimi Hendrix) de John Coghlan (batteur de Status Quo) et d'Eric Bell (guitariste de Thin Lizzy) à Lillers.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Colonel Tchang",
    flag: "FR",
    texte:
      "free metal (même principe que le free jazz mais en metal) (batterie)",
    ul: [
      {
        li: "Colonel Tchang",
        img: "tchang",
        title: "Colonel Tchang",
      },
    ],
  },
  {
    strong: "Gregory Bell",
    flag: "US",  
    texte:
      "Duo guitare / chant avec le chanteur Nord Américain Gregory Bell (originaire de San Francisco) : Standards de jazz et Motown (guitare, basse, choeurs) & enregistrements d'une maquette de quelques morceaux.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Cannibalfest",
    flag: "FR",
    texte:
      "Death metal spontané & humoristique (guitare 8 cordes, basse, vocaux, claviers) 2 CDs enregistrés en studio :",
    ul: [
      {
        li: "Cannibalfest - Sector 26 - 2004 (enregistré à Grenoble)",
        img: "",
      },
      {
        li: "Cannibalfest - La Grande Suzanne Trankille - 2005 (enregistré à Genève dans un studio d'enregistrement situé dans un ancien bâtiment militaire)",
        img: "cannibalfest",
        title: "Cannibalfest - La Grande Suzanne Trankille - 2005",
      },
    ],
  },
  {
    strong: "E-doll",
    flag: "FR",
    texte:
      "Rock indé (basse) remplacement d'une élève qui avait deux concerts le même soir pour la 1ere partie des Fatal Picards à Liévin.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "+guests",
    flag: "FR",
    texte: "Reprises rock (guitare 8 cordes, arrangements pour flûte)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Hexagone",
    flag: "FR",
    texte: "Reprises rock (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Ompfa",
    flag: "FR",
    texte: "Rock progressif et expérimental sans le style de Magma (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Bertrand Bialy",
    flag: "FR",
    texte: `Chanteur variété / rock (basse) enregistrement en studio de parties de basse jazz sur 2 titres de l'album "toutes ces histoires"`,
    ul: [
      {
        li: "Bertrand Bialy - Toutes ces histoires - 2002",
        img: "bialy",
        title: "Bertrand Bialy - Toutes ces histoires - 2002",
      },
    ],
  },
  {
    strong: "Amicalement Votre",
    flag: "FR",
    texte: "Reprises rock (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Lèpre",
    flag: "FR",
    texte:
      "(collectif de musiciens) N'importequoi core (vocaux, basse, guitare, batterie) enregistrement d'un CD",
    ul: [
      {
        li: "Lèpre - Karone vol.1",
        img: "lepre",
        title: "Lèpre - Karone vol.1",
      },
    ],
  },
  {
    strong: "????",
    flag: "FR",
    texte:
      "Chanteur variété (basse, guitare, arrangements, réalisation d'une maquette)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "La belle affaire",
    flag: "FR",
    texte: "Chanson française sur fond de musique tzigane (contrebasse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "????",
    flag: "FR",
    texte: "Reprises de Béla Fleck and the Flecktones (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "????",
    flag: "FR",
    texte: "Groupe de death metal (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Les moines singe",
    flag: "FR",
    texte:
      "Standards jazz (Thelonious Monk principalement) (basse ou contrebasse ... sais plus ..) enregistrement d'une demo",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "????",
    flag: "FR",
    texte: "Reprises otis redding (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "????",
    flag: "FR",
    texte: "Pop rock / electro (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "????",
    flag: "FR",
    texte: "Reggae / dub (basse/boucles)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "????",
    flag: "FR",
    texte:
      "Duo basse ou guitare / piano pour jouer dans les restaurants (basse et guitare)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "L'Ensemble de Guitares de Gérard Marais",
    flag: "FR",
    texte: "(basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "The Jam Messengers",
    flag: "FR",
    texte: "Reprises standards jazz (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Workshop du CNR de Lille",
    flag: "FR",
    texte: "Jazz moderne (basse)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "The Arrogance",
    flag: "FR",
    texte: `Projet solo (batterie, guitare 8 cordes, basse, claviers, contrebasse, programmation, voix, composition, prise de son & mixage) enregistrement d'un CD "Shushingura" en 2004.`,
    ul: [
      {
        li: "The Arrogance - Shushingura - 2004",
        img: "shushingura",
        title: "The Arrogance - Shushingura - 2004",
      },
    ],
  },
  {
    strong: "Frédéric Szymañski",
    flag: "FR",
    texte: `Projet solo (tous instruments, prise de son & mixage) Enregistrement d'un titre d'un style different par semaine pendant 4 ans, titres en téléchargement en ligne classés plusieurs fois 1ers de leur catégorie sur vitaminic.fr, le titre "The Depths of Hell" a été utilisé dans le court métrage "Le Manuscrit d'un fou" de Weird Fantasy Productions.`,
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Viveka Eriksson",
    flag: "SE",
    texte:
      "Travail avec la chanteuse Suédoise Viveka Eriksson, arrangement et enregistrement de l'intégralité des parties instrumentales des titres « Less is more » en octobre 2008, « Wildfire » en Janvier 2009.",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Vincent Bergeron",
    flag: "CA",
    texte:
      "Travail régulier avec le compositeur Québécois Vincent Bergeron (musique contemporaine), discographie commune :",
    ul: [
      {
        li: "Berger Rond - Ma Vie EST ici - 2008",
        img: "savant2008",
        title: "Berger Rond - Ma Vie EST ici - 2008",
      },
      {
        li: "Berger Rond - Le savant devait arriver avant songeur - 2009",
      },
      {
        li: "Berger Rond - Instruit Mental - 2009",
        img: "instruit2009",
        title: "Berger Rond - Instruit Mental - 2009",
      },
      {
        li: "Berger Rond - Audiomachie / Logomachie - 2009",
        img: "audiomachie2009",
        title: "Berger Rond - Audiomachie / Logomachie - 2009",
      },
      {
        li: "Berger Rond - Forecast Proof - 2009",
      },
      {
        li: "Berger Rond - The Stairs of the Unsteady - 2010",
      },
      {
        li: "Berger Rond - Permanent Dust - 2010",
      },
      {
        li: "Berger Rond - Etrange en compagnie - 2010",
        img: "etrange2010",
        title: "Berger Rond - Etrange en compagnie - 2010",
      },
      {
        li: "Berger Rond - Elle avait raison Hathor - 2012",
      },
      {
        li: "Berger Rond - En dehors de tout - Partie 1 - 2013",
        img: "endehors2013",
        title: "Berger Rond - En dehors de tout - Partie 1 - 2013",
      },
      {
        li: "Berger Rond - En dehors de tout - Partie 2 - 2013",
        img: "endehors22013",
        title: "Berger Rond - En dehors de tout - Partie 2 - 2013",
      },
      {
        li: "Berger Rond - Il y a seulement des apparitions - 2013",
      },
      {
        li: "Berger Rond - Game overtime - 2014",
        img: "game2013",
        title: "Berger Rond - Game overtime - 2014",
      },
      {
        li: "Berger Rond - La répétition du même acte - 2016",
        img: "repetition2016",
        title: "Berger Rond - La répétition du même acte - 2016",
      },
      {
        li: "Berger Rond - Plages d'Intrus Mental - 2016",
        img: "plages2016",
        title: "Berger Rond - Plages d'Intrus Mental - 2016",
      },
      {
        li: "Berger Rond - Simulacre Occidental - 2017",
        img: "simulacre2017",
        title: "Berger Rond - Simulacre Occidental - 2017",
      },
      {
        li: "Berger Rond - Elle avait raison Hathor (2012;18) - 2018",
        img: "hathor2018",
        title: "Berger Rond - Elle avait raison Hathor - 2018",
      },
      {
        li: "Berger Rond - inQUIETudes - 2018",
        img: "inquietudes2018",
        title: "Berger Rond - inQUIETudes - 2018",
      },
      {
        li: "Berger Rond - En Dehors de Tout (2019) - 2019",
        img: "endehors2019",
        title: "Berger Rond - En Dehors de Tout (2019) - 2019",
      },
      {
        li: "Berger Rond - Etrange en compagnie (remasterisé)- 2022",
      },
    ],
  },
  {
    strong: "Amazing band",
    flag: "FR",
    texte: "Big band jazz/funk (guitare)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "Dante",
    flag: "FR",
    texte: "Sludge metal / math metal (batterie)",
    ul: [
      {
        img: "",
      },
    ],
  },
  {
    strong: "XHOHX",
    flag: "FR",
    texte: "Rock in opposition / Nowave (batterie)",
    ul: [
      {
        img: "",
      },
    ],
  },
];
