const sliderData = [
  {
    id: 1,
    description: "Step1",
  },
  {
    id: 2,
    description: "Step2",
  },
  {
    id: 3,
    description: "Step3",
  },
  {
    id: 4,
    description: "Step4",
  },
];
export default sliderData;
