import style from "./sidebar.module.scss";

export default function Sidebar({ data }) {
  const scrollToSection = (sectionId) => {
    console.log(sectionId);

    const element = document.getElementById(sectionId);

    if (element) {
      const marginTop = 85;

      const scrollToY =
        element.getBoundingClientRect().top + window.scrollY - marginTop;
      window.scrollTo({ top: scrollToY, behavior: "smooth" });
    }
  };

  return (
    <aside className={`${style.sidebar}`}>
      <div className={`${style.sticky}`}>
        <h2>Navigation rapide :</h2>
        <ul className={`${style.primaryUl}`}>
          <li
            className={style.boldLi}
            onClick={() => scrollToSection("introduction")}
          >
            Introduction
          </li>
          {data.map((a, i) => (
            <li className={style.boldLi} key={i}>
              <span
                className={style.liSpan}
                onClick={() => scrollToSection(`bloc${i + 2}`)}
              >
                {a.title}
              </span>
              <ul className={style.secondaryUl}>
                {a.content.map(
                  (b, ind) =>
                    b.type === "title" && (
                      <li
                        className={style.normalLi}
                        key={ind}
                        id={ind}
                        onClick={() => scrollToSection(`title${i + 2}.${ind}`)}
                      >
                        {b.content}
                      </li>
                    )
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}
