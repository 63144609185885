export const bass = {
  level1: [
    {
      page: [
        <strong>Page 1 : </strong>,
        "Premières notes et premiers rythmes.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 5a : </strong>,
        "Etude d'un premier morceau en entier.",
        <br />,
        "Premiere ligne de basse de",
        <strong> Krist Novoselic : </strong>,
        "Something in the way de ",
        <strong>Nirvana.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux de ",
        <strong>Nirvana</strong>,
        " disséminés dans la méthode",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 8a : Rammstein - Du Hast</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 13 : </strong>,
        "Début de l'étude des lignes de basse improvisées",
        <br />,
        "La méthode prend ensuite ce rythme :",
        <br />,
        <strong>- 1 page de solfège/technique </strong>,
        "appliquée directement sur la basse avec une nouvelle note et un nouveau rythme à chaque fois et de temps en temps une nouvelle technique.",
        <br />,
        <strong>- 1 page de pratique de l'accompagnement improvisé</strong>,
        <br />,
        <strong>- De 1 jusqu'à 8 morceaux </strong>,
        "entiers adaptés au niveau des techniques, notes et rythmes déja appris.",
        <br />,
        "- À la fin de chaque morceau,",
        <strong> 5 questions théoriques </strong>,
        "qui évoluent jusque la fin de la méthode vous aident à entrenir votre savoir.",
        <br />,
        <strong>- Un travail progressif des intervalles sur le manche</strong>,
        " va petit à petit vous aider à repérer les notes sur votre basse, développer votre connaissance théorique et aiguiser votre oreille musicale.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>
          Le fait de travailler des morceaux correctement retranscrits par mes
          soins et des exercices de lignes de basse improvisées vous procure un
          développement passif de l'oreille musicale qui est suffisant à ce
          stade.
        </strong>,
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 14b : </strong>,
        "Premiere ligne de basse de ",
        <strong>James Jamerson : </strong>,
        "Ain't too proud to beg des Temptations.",
        <br />,
        "Il y a environ une quarantaine de morceaux de type Motown/Atlantic/Soul disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 22 : </strong>, "Ben E. King - Stand by Me"],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 24c : </strong>,
        "Premiere ligne de basse de ",
        <strong>Paul McCartney : Because des Beatles.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux des Beatles disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: <strong>Page 039 : Deep purple - Smoke on the Water</strong>,
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 44 : </strong>,
        "Début du travail des découpes rythmiques en ternaire et début du travail de lecture avec positions.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
  ],
  level2: [
    {
      page: [
        <strong>Page 49a : </strong>,
        "Premiere ligne de basse de ",
        <strong>Cliff Burton : Jump in the Fire de Metallica.</strong>,
        <br />,
        "Il y a environ une quinzaine de morceaux de ",
        <strong>Metallica </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 058h : </strong>,
        "Premier morceau de ",
        <strong>Bob Marley & the Wailers: Buffalo Soldier.</strong>,
        <br />,
        "Il y a environ une dixaine de morceaux des ",
        <strong>Wailers </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 58e : </strong>,
        "Premier morceau de ",
        <strong>System of a Down: Lonely Day</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>
          Page 58o : Début de l'étude de lignes de basse improvisées sur des
          grilles de blues et des grilles tonales, étude des 12 tonalités.
        </strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: "Le travail de lignes de basse improvisées dans des tonalités précises continue de vous procurer un développement passif de l'oreille musicale.",
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [<strong>Page 63q : Peggy Lee - Fever</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>
          Page 68o : Début de l'étude de lignes de basse improvisées avec
          tétrades.
        </strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 70a : </strong>,
        "Première ligne de basse de ",
        <strong>Flea : Lovin' And Touchin' </strong>,
        "des ",
        <strong>Red Hot Chili Peppers.</strong>,
        <br />,
        "Il y a environ une vingtaine de morceaux des Red Hot Chili Peppers disséminés dans la méthode.",
      ],
      type: "moceaux",
      color: "orange",    
    },
    {
      page: [
        <strong>Page 73 : </strong>,
        "Début du travail des découpes rythmiques à base de ",
        <strong>doubles croches.</strong>,
        <br />,
        "Le répertoire devient beaucoup plus ",
        <strong>funky</strong>,
        " à partir d'ici.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [<strong>Page 074p : Premier morceau de Muse: Hysteria</strong>],
      type: "moceaux",
      color: "orange",   
    },
    {
      page: [<strong>Page 76i : Metallica - For Whom the Bell Tolls.</strong>],
      type: "moceaux",
      color: "orange",   
    },
    {
      page: [
        <strong>Page 81a : </strong>,
        "Début de l'étude du ",
        <strong>slap</strong>,
        " (pouce uniquement dans un premier temps) et du jeu au ",
        <strong>médiator.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 82 : </strong>,
        "Début de l'étude de lignes de basse improvisées avec ",
        <strong>gammes pentatoniques.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [<strong>Page 84p : The Beatles - Come together</strong>],
      type: "moceaux",
      color: "orange",   
    },
    {
      page: [<strong>Page 88p : Free - All right now</strong>],
      type: "moceaux",
      color: "orange",   
    },
    {
      page: [<strong>Page 91c : Guns n' Roses - Sweet Child O Mine</strong>],
      type: "moceaux",
      color: "orange",   
    },
    {
      page: [<strong>Page 91p : Pink floyd - Money</strong>],
      type: "moceaux",
      color: "orange",   
    },
  ],
  level3: [
    {
      page: [
        "Approximativement à partir d'ici et pour toute la durée du niveau avancé, le niveau des morceaux travaillés se stabilise autour d'un ",
        <strong>niveau pro basique</strong>,
        " (pro non virtuose si vous préférez) ce qui vous permet de très progressivement vous rapprocher de ce niveau.",
        <br />,
        "À cet endroit, la méthode est littéralement bourrée de morceaux, vous prennez de la bouteille en développant votre répertoire.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: "Le travail du répertoire en plus de tout le reste continue de vous procurer un développement passif de l'oreille musicale.",
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 91u : </strong>,
        "Premier morceau de ",
        <strong>Queen: We are the Champions</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 94u : Royal Blood - Come on Over</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 97 : Breeders - Cannonball</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 99o : </strong>,
        "Début de l'étude des ",
        <strong>solos de basse improvisés.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 99z : </strong>,
        "Première ligne de basse de ",
        <strong>Jack Bruce : Sunshine of your love de Cream.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 100 : </strong>,
        "Début de l'étude complète du ",
        <strong>slap & pop.</strong>,
        <br />,
        "À partir de cet endroit vous aurez au minimum un morceau de répertoire avec la ",
        <strong>technique du slap </strong>,
        "entre les pages de solfège / technique & travail de l'improvisation",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 107 : </strong>,
        "Premier morceau de ",
        <strong>Rage Against The Machine: Bombtrack</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 116 : RATM - Take the power back</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 118j : Queen - Another one bites the dust</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 125n : </strong>,
        "Début du travail des découpes rythmiques à base de ",
        <strong>sextolets</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 135y : </strong>,
        "Première ligne de basse de ",
        <strong>Robeert Trujillo : Infectious Grooves - Spreck.</strong>,
        <br />,
        "Il y a environ une dixaine de morceaux d'infectious grooves dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 136 : Début de l'étude du trinaire.</strong>],
      type: "impro / compo",
      color: "brown",    
    },
    {
      page: [<strong>Page 137l : Black Sabbath - N.I.B</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>
          Page 140p : Four tops - Reach out I'll be there (James Jamerson).
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
  ],
  level4: [
    {
      page: [
        <strong>Page 144 : </strong>,
        `Fin de l'apprentissage de la lecture et début du travail`,
        <strong> “gammes et technique”.</strong>,
        <br />,
        <strong>Mode "Conservatoire" de la méthode : </strong>,
        "Votre niveau est grosso-modo équivalent à un élève en 1ère année de conservatoire à rayonnement régional mais avec des bases ",
        <strong>BEAUCOUP PLUS SOLIDES</strong>,
        " de plus, nous continuons d'avancer avec un apprentissage hybride ",
        <strong> rock / jazz / classique </strong>,
        "là où les CRR vous forcent à vous spécialiser vous coupant du savoir venant des familles musicales qui ne sont pas les votres.",
        <br />,
        `L'étude théorique et l'analyse de morceaux/compositions se développe beaucoup plus vite à partir d'ici.`,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: "Début de jeux et exercices progressifs visant à développer activement votre oreille musicale.",
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>
          Page 147 : Dazed & confused de Led Zeppelin (John Paul Jones).
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 155 : </strong>,
        "Début de l'étude de préludes et suites de ",
        <strong>J.S.Bach.</strong>,
        <br />,
        "Beaucoup de morceaux du répertoire classique adaptés pour la basse électrique vont ensuite faire leur apparition dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 165 : </strong>,
        "Début du travail du démanché sur les gammes et les arpèges.",
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 171 : </strong>,
        "Début de l'étude du ",
        <strong>slap en ternaire.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 172a : </strong>,
        "Première ligne de basse de ",
        <strong>Jaco pastorius : Come on, come over.</strong>,
        <br />,
        "Il y a environ une dixaine de morceaux de ",
        <strong>Jaco pastorius </strong>,
        "disséminés dans la méthode.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 180 : </strong>,
        "Début du travail d'études de styles (genres musicaux, morceaux à trous avec variantes à improviser) + morceaux",
        <strong> Motown/soul </strong>,
        "avec parties à improviser dans le même style.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 184 : Coffee shop des Red hot chili peppers.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 184h : </strong>,
        "Première ligne de basse de ",
        <strong>Steve Harris : Powerslave d'Iron Maiden.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 188 : </strong>,
        "Début de l'étude du ",
        <strong>double pop.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 189 : </strong>,
        "Début des exercices progressifs de ",
        <strong>legato.</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>
          Page 190 : Infectious grooves - Don't Stop, Spread the Jam
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 191 : </strong>,
        "Début de l'étude et du travail méticuleux des ",
        <strong>solos improvisés </strong>,
        "sur les modes et de l'accompagnement modal.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 192b : </strong>,
        "Début du travail du jeu en accords à la basse",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 196 : </strong>,
        "Début des exercices progressifs de ",
        <strong>tapping.</strong>,
      ],
      type: "technique / morceaux",
      color: "yellow",  
    },
    {
      page: [
        <strong>Page 211 : </strong>,
        "Début de l'étude des ",
        <strong>solos improvisés </strong>,
        "avec modulation et notes cibles.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 212 : </strong>,
        "Début de l'étude progressive du ",
        <strong>walking bass.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 217 : </strong>,
        "Première ligne de basse de ",
        <strong>Marcus Miller: Jean-Pierre de Miles Davis.</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 230a : </strong>,
        "Introduction au jazz avec ",
        <strong>So What de Miles Davis.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>
          Page 232 : Début de l'étude de gammes, improvisations et lignes de
          basse en mineur harmonique.
        </strong>,
        ,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>
          Page 237 : Metallica : Anasthesia (pulling teeth) (solo de basse de
          Cliff Burton).
        </strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 238 : </strong>,
        "Début de l'étude du vrai ",
        <strong>phrasé blues </strong>,
        "(qui vous donnera accès au vrai phrasé bebop plus tard).",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 244 : </strong>,
        "Début de l'étude du ",
        <strong>double slap (double thumb)</strong>,
      ],
      type: "technique / théorie",
      color: "yellow",
    },
    {
      page: [
        <strong>Page 245 : </strong>,
        "Début de l'étude et du travail d'imprégnation du style de ligne de basse à la ",
        <strong>Jaco Pastorius </strong>,
        "à travers le morceau The chicken fragmenté en une 20aine de sessions de travail au long de la méthode.",
      ],
      type: "impro / compo",
      color: "brown",
    },
  ],
  level5: [
    {
      page: [
        <strong>Page 254g : Antonio Vivaldi - Printemps - Largo.</strong>,
        <br />,
        "La méthode comporte les 12 mouvements des 4 saisons de ",
        <strong>Vivaldi </strong>,
        "adaptés par mes soins pour basse électrique.",
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 252a : Jaco Pastorius : Portrait of Tracy.</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 260a : Brothers Johnson - Stomp</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 268 : </strong>,
        "Début du travail des ",
        <strong>standards de jazz </strong>,
        "avec à chaque fois une transcription de ",
        <strong>walking </strong>,
        "d'un grand contrebassiste ou bassiste, une transcription de chorus d'un grand jazzman à travailler et des exercices précis et progressifs à appliquer dans vos improvisations.",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 308e : </strong>,
        "Première ligne de basse de ",
        <strong>Les Claypool : Southbound Pachyderm de Primus</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 328c : </strong>,
        "premier morceau de ",
        <strong>Stuart Hamm : Moonlight sonata - Adagio Sostenuto</strong>,
      ],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [
        <strong>Page 335 : </strong>,
        "Début de l'étude de gammes, improvisations et progressions d'accords en ",
        <strong>mineur mélodique.</strong>,
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 338 : </strong>,
        "Oups! J'ai oublié de retranscrire la ligne de basse sur ce standard de jazz, on dirait que ça va être à vous de le faire et ce jusqu'à la fin de la méthode.",
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 344 : </strong>,
        "Les études de styles se transforment en exercices où vous devez composer dans le style d'un groupe donné (imiter).",
      ],
      type: "impro / compo",
      color: "brown",
    },
    {
      page: [
        <strong>Page 357 : </strong>,
        "À partir de cet endroit vous avez une transcription de morceau rock à réaliser régulièrement, ces transcriptions sont corrigées par mes soins et alimentent la méthode.",
        <br />,
        "Vous aurez tout d'abord 10 transcriptions imposées (progressivité de l'exercice oblige) et pourrez ensuite avoir le libre choix des morceaux que vous voulez retranscrire et ajouter à la méthode",
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [
        <strong>Page 386 : </strong>,
        "Ma transcription du ",
        <strong>solo de Scrapple from the Apple de Charlie Parker </strong>,
        "comporte 8 mesures manquantes, vous devez les retranscrire. Le nombre de mesures manquantes augmentera à chaque transcription jusqu'à ce que vous puisssiez retranscrire un ",
        <strong>solo de jazz </strong>,
        "entièrement et seul.",
      ],
      type: "développement de l'oreille",
      color: "pink",
    },
    {
      page: [<strong>Page 386p : VULFPECK - Dean Town</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 426d : Jaco Pastorius - Donna lee</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 486 : Weather Report - Teen Town</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 513 : Viktor Wooten - Me & My Bass Guitar</strong>],
      type: "morceaux",
      color: "orange",
    },
    {
      page: [<strong>Page 555 : Dernière page de la méthode</strong>],
      type: "technique / théorie",
      color: "yellow",
    },
  ],
};
