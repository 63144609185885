import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { readArticles } from "../../apis/articles";
import { ArticlesContext } from "../context/articlesContext";

export default function ArticlesProvider({ children }) {
  const initialArticles = useLoaderData();
  const [art, setArt] = useState(initialArticles);

  function readArt(credentials) {
    readArticles(credentials).then((res) => {
      setArt(res);
    });
  }

  // Tout les articles !== "FAQ" -> destination à blog / articles / homepage
  const blog = art.filter(
    (obj) => obj.category !== "FAQ" && obj.enable === true
  );

  // Ordre chronologique inversé
  function orderArrayByDate(array) {
    return array.sort((a, b) => Number(b.date.seconds) - Number(a.date.seconds));
  }
    orderArrayByDate(blog);

  // NEWS FRAME => HOMEPAGE
  // Article le plus récent
  const first = [blog[0], blog[1], blog[2]];

  const blogCopy = blog.slice();
  // MORE VIEW PER 3
  const moreView = blogCopy.valueOf()
    .sort((a, b) => Number(b.view) - Number(a.view))
    .slice(0, 3);

  // RANDOM PER 3
  const shuffleBlog = art.filter(
    (obj) => obj.category !== "FAQ" && obj.enable === true
  );
  const filterShuffle = shuffleBlog.filter(
    (obj) =>
      !first.some((item) => item.title === obj.title) &&
      !moreView.some((item) => item.title === obj.title)
  );

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  shuffleArray(filterShuffle);

  const randomBlog = filterShuffle.slice(0, 3);

  const blogHome = [first, moreView, randomBlog];

  // FAQ
  const FaQ = art.filter(
    (obj) => obj.category === "FAQ" && obj.enable === true
  );

  const FAQ = FaQ.sort((a, b) => Number(b.view) - Number(a.view));

  return (
    <ArticlesContext.Provider value={{ art, blog, blogHome, FAQ, readArt}}>
      {children}
    </ArticlesContext.Provider>
  );
}
