import style from "./footer.module.scss";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assests/images/logo n°1(blanc sur transparent) 2 . 2 .png";
import RS from "../RS/rs";

export default function Footer() {
  const year = new Date().getFullYear();

  const resolution = window.innerWidth;
  return (
    <footer className={`${style.footer}`}>
      <div className={`${style.content} d-flex jcsb`}>
        <div className={`${style.container} ${style.description}`}>
          <h2>FRÉDÉRIC SZYMAÑSKI</h2>
          <p>
            Cours de guitare et basse électrique de qualité à{" "}
            <strong>Noeux-les-mines</strong> ou par webcam via{" "}
            <Link to={"https://discord.com/"} title="Discord" target="_blank">
              <i className="fa-brands fa-discord"></i>
              <strong> Discord</strong>
            </Link>
          </p>
          <p>
            Professeur d'instrument depuis 1995, Frédéric a enseigné la guitare
            ou la basse électrique à plusieurs centaines de personnes:
            Débutants, confirmés ou déjà musiciens professionnels / professeurs
            de guitare ou basse.
          </p>
          <p>
            <strong>Lens</strong> - <strong>Liévin</strong> -{" "}
            <strong>Béthune</strong> - <strong>Noeux-les-mines</strong> &
            environs.
          </p>
        </div>

        <div className={`${style.pages}`}>
          <h2>PAGES</h2>
          <ul className={`d-flex f-wrap`}>
            <li>
              <NavLink to={"/"} title="Accueil">
                ACCUEIL
              </NavLink>
            </li>
            <li>
              <NavLink to={"/biographie"} title="Présentation">
                PRÉSENTATION
              </NavLink>
            </li>
            <li>
              <NavLink to={"/cours"} title="Méthodes">
                MÉTHODES
              </NavLink>
            </li>
            <li>
              <NavLink to={"/avis"} title="Avis">
                AVIS
              </NavLink>
            </li>
            <li>
              <NavLink to={"/interactif"} title="Interactif">
                INTERACTIF
              </NavLink>
            </li>
            <li>
              <NavLink to={"/blog"} title="Écrits">
                ÉCRITS
              </NavLink>
            </li>
            <li>
              <NavLink to={"/contact"} title="Contact">
                CONTACT
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={`${style.container}`}>
          <img src={logo} alt="logo FREDERIC SZYMANSKI" />
        </div>
      </div>

      <div className={`${style.content} d-flex jcsb`}>
        <p>
          Site conçu et développé par{" "}
          <strong>
            <Link
              to={"https://florentin-crespel.fr"}
              target="_blank"
              title="florentin-crespel.fr"
            >
              Florentin CRESPEL
            </Link>
          </strong>{" "}
          | COPYRIGHT © {year} <strong>Frédéric Szymañski</strong>
        </p>
        <ul className={`${style.social} d-flex aic jcc`}>
          <RS />
        </ul>
      </div>
    </footer>
  );
}
