import style from "../../../../../rate/components/card/card.module.scss";

export default function BigCard({ data, readMore, css }) {
  // générateur d'étoile -> function appelée dans l'HTML
  function createRate(n) {
    const star = [];
    const avoid = [];
    // création des étoiles pleines
    for (let i = 0; i < n; i++) {
      star.push(<i className="fa-solid fa-star"></i>);
    }
    // création des étoile vides
    for (let i = 0; i < 5 - n; i++) {
      avoid.push(<i className="fa-regular fa-star"></i>);
    }
    // fusion des deux tableau
    return new Array(star, avoid);
  }

  return (
    <article
      className={`${style.backdrop} ${css && style.crsled} d-flex aic jcc`}
    >
      <div
        className={`${style.card} ${
          readMore && style.bigCard
        } d-flex f-column jcc`}
      >
        <div className={`${style.head} d-flex aic`}>
          <div className={style.img}>
            <img src={process.env.PUBLIC_URL + "/img/" + data.img} alt="" />
          </div>
          <h2>
            {data.name} - {data.instru}
          </h2>
        </div>
        <div className={`${style.stars} d-flex`}>{createRate(data.rate)}</div>
        <div className={`${style.rate}`}>
          <p className={!readMore && style.shortText}>
            {data.text}
            {!readMore && <div className={style.gradient}></div>}
          </p>
        </div>
        <div className={`${style.readMore} d-flex jce`}>
          <p
            onClick={() => {
              readMore(false);
            }}
          >
            Réduire
          </p>
        </div>
      </div>
    </article>
  );
}
