import Constants from "../../../oldies/stats.js";
import style from "../cours.module.scss";
import methodeBasse from "../../../assests/images/methodebasse.jpg";

export default function BassCourse() {
  return (
    <section className={`${style.description}`}>
      <h1>COURS DE BASSE ÉLECTRIQUE</h1>
      <p>
        J'enseigne la basse électrique et ses différents modes de jeu et
        techniques depuis maintenant <b>{Constants.anneesdexperience} ans</b>.
        <br />
        Après avoir enseigné dans plusieurs écoles de musique pendant plus d'une{" "}
        <b>dixaine d'années</b> en travaillant avec des{" "}
        <b>dixaines de méthodes différentes</b>, je dispense depuis 2009 des
        cours sur <b>Noeux-les-mines</b> ou par
        <b> webcam</b> avec ma propre méthode: <b>Continuum Basse</b>, mes
        élèves viennent principalement du secteur de Lens, Béthune, Liévin et
        leur péripherie et plus rarement de la région lilloise.
      </p>
      <p>
        Les cours sont des <b>cours individuels d'une heure</b> à prendre{" "}
        <b>hebdomadairement</b>, l'âge minimum est de <b>8 ans</b>, le nombre de
        créneaux horaires est limité, il y a actuellement{" "}
        <b>{30 - Constants.nombredeleves} places libres</b> pour accueillir de
        nouveaux élèves.
        {30 - Constants.nombredeleves === 0
          ? ` Vous pouvez toutefois me contacter pour être mis(e) sur une liste d'attente.`
          : ``}
        <br />
        Les tarifs et les conditions sont disponibles dans le formulaire de
        contact.
      </p>
      <h1>LA MÉTHODE CONTINUUM BASSE</h1>
      <p>
        Ma méthode <b>Continuum Basse</b> finit symboliquement page 555 mais
        comporte en fait <b>{Constants.pagesbasse} pages</b> car de nouvelles
        transcriptions, actuellement <b>plus de {Constants.morceauxbasse}</b>{" "}
        morceaux, y sont régulièrement intercallées, parfois même par les{" "}
        <b>élèves les plus avancés</b>, aboutissant sur un <b>co-autorat</b>:
        <br />
        <br />
        <table cellSpacing="0" cellPadding="0">
          <tr>
            <td>
              <img
                className={`${style.methode}`}
                src={methodeBasse}
                alt="Continuum Basse"
              />
            </td>
            <td>
              <table
                cellSpacing="0"
                cellPadding="0"
                className={`${style.table}`}  
              >
                <tr>
                  <td>
                    <b> Frédéric Szymañski </b>
                  </td>
                  <td>
                    <b>{Constants.pagesbassefred} pages</b>
                  </td>
                  <td>
                    <meter
                      low="20"
                      high="80"
                      max="100"
                      value={Constants.percbassefred}
                    ></meter>
                  </td>
                  <td>
                    <b>{Constants.percbassefred}%</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b> François Blarel </b>
                  </td>
                  <td>
                    <b>{Constants.pagesbassefrancois} pages</b>
                  </td>
                  <td>
                    <meter
                      low="20"
                      high="80"
                      max="100"
                      value={Constants.percbassefrancois}
                    ></meter>
                  </td>
                  <td>
                    <b>{Constants.percbassefrancois}%</b>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <br />
        Elle convient aussi bien à des <b>débutants</b> sans prérequis voulant{" "}
        <b>apprendre correctement</b> qu'à des <b>musiciens professionnels</b>{" "}
        pouvant l'utiliser comme "daily grind".
        <br />
        En voici les grandes étapes:
      </p>
    </section>
  );
}
