import style from "./about.module.scss";
import mainStyle from "../../homepage.module.scss";
import img from "../../../../assests/images/bio/1.webp";
import { NavLink } from "react-router-dom";
import ShortDivider from "../../../../components/divers/shortDiver";
import Scroller from "../../../../components/scroller/scroller";

export default function About() {
  return (
    <section id={"about"} className={`${style.about} ${mainStyle.section}`}>
      <h2
        className={style.title}
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-delay="100"
      >
        À PROPOS DE FRÉDÉRIC SZYMAÑSKI
      </h2>
      <ShortDivider />
      <NavLink
        to={"/biographie"}
        title="BIOGRAPHIE"
        className={`${style.container}`}
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="100"
      >
        <div className={`${style.img}`}>
          <img src={img} alt={img} />
        </div>
        <div className={`${style.content}`}>
          <p
            className={`${style.text}`}
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-delay="100"
          >
            Musicien multi-instrumentiste, professeur d'intrument depuis 1995,
            Frédéric a enseigné la guitare ou la basse électrique à plusieurs
            centaines de personnes: Débutants, confirmés ou déjà musiciens
            professionnels / professeurs de guitare ou basse.
            <br />
            <br />
            Depuis 2009, Il enseigne la guitare et la basse électrique à l'aide
            des méthodes Continuum qu'il a lui même élaboré, un savoir-faire
            maintenant en rupture avec l'enseignement habituel de ces
            instruments...
          </p>
        </div>
      </NavLink>
      <Scroller section={"methode"} text={"Découvrez sa méthode Continuum"} />
    </section>
  );
}
