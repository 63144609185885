import { Helmet } from "react-helmet";
import style from "./contact.module.scss";
import Form from "./form/formMobile";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Contact</title>
      </Helmet>
      <main>
        <section className={`${style.contact} d-flex f-column aic jcc`}>
          <h1>PRENDRE CONTACT</h1>
          <div className={`${style.form} form d-flex f-column aic jcc`}>
            <section className={`${style.title} d-flex f-column aic`}>
              <div className={`${style.banner}`}>
                <h2>
                  Frédéric Szymañski - Cours de guitare et basse de qualité !
                </h2>
                <p>
                  Cours de guitare électrique et basse électrique sur
                  Noeux-les-mines ou par webcam
                </p>
              </div>
            </section>
            <Form />
          </div>
        </section>
      </main>
    </>
  );
}
