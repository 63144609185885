import style from "./bio.module.scss";
import Constants from "../../oldies/stats.js";
import imageBio1 from "../../assests/images/bio/1.webp";
import imageBio2 from "../../assests/images/bio/2.webp";
import imageBio3 from "../../assests/images/bio/3.webp";
import imageBio4 from "../../assests/images/bio/4.webp";
import imageBio5 from "../../assests/images/bio/5.webp";
import US from "../../assests/images/bio/US.svg";
import CA from "../../assests/images/bio/CA.svg";
import SE from "../../assests/images/bio/SE.svg";
import FR from "../../assests/images/bio/FR.svg";
import CD from "../../assests/images/bio/CD.webp";
import { albums } from "../../json/album/albums.js";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

function getFlag(flagString) {
  // get flags from http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg
  switch (flagString) {
    case "US":
      return US;
    case "CA":
      return CA;
    case "SE":
      return SE;
    case "FR":
      return FR;
    default:
      return FR;
  }
}

function getCd(album) {
  if (typeof album === "undefined") {
    return "";
  }
  return <img className={`${style.cds}`} src={CD} alt="album" />;
}

export default function Bio() {
  const [target, setTarget] = useState();

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      const marginTop = 55;

      const scrollToY =
        element.getBoundingClientRect().top + window.scrollY - marginTop;

      setTarget(sectionId);

      window.scrollTo({ top: scrollToY, behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Biographie</title>
      </Helmet>
      <main className={`${style.bio}`}>
        <br />
        <section className={`${style.intro} d-flex f-column`} id="Présentation">
          <h1 className={`${style.title}`}>FRÉDÉRIC SZYMAÑSKI</h1>
          <div className={`${style.content} d-flex aic`}>
            <div className={`${style.img}`}>
              <img
                className={`${style.imageBio1}`}
                src={imageBio1}
                alt="image bio 1"
              />
            </div>
            <p className={style.pl}>
              Musicien multi-instrumentiste, professeur d'intrument depuis 1995,
              Frédéric a enseigné la guitare ou la basse électrique à plusieurs
              centaines de personnes: Débutants, confirmés ou déjà musiciens
              professionnels / professeurs de guitare ou basse.
              <br />
              Depuis 2009, Il enseigne la guitare et la basse électrique à
              l'aide des méthodes{" "}
              <strong>
                <NavLink to={"/cours"} title="CONTINUUM" target="_blank">
                  Continuum
                </NavLink>
              </strong>{" "}
              qu'il a lui même élaboré, un savoir-faire maintenant en rupture
              avec l'enseignement habituel de ces instruments...
            </p>
          </div>
        </section>
        <section
          className={`${style.description} d-flex f-column`}
          id="Biographie"
        >
          <div className={`${style.content} d-flex f-column`}>
            <h2>BIOGRAPHIE</h2>
            <p>
              Après quelques contacts avec la musique durant son enfance
              (quelques années d'étude du solfège, 3 années d'étude de la
              trompette et quelques mois de tambour), Frédéric découvre la basse
              électrique au cours de sa 17e année et se passionne pour cet
              instrument.
              <br />
              Après seulement 6 mois de pratique de l'instrument, il reussit à
              entrer à l'âge de 18 ans en formation professionelle au{" "}
              <strong>
                <Link
                  to={"https://www.cma-valenciennes.fr/"}
                  target="_blank"
                  title="CMA (Centre des Musiques Actuelles de Valenciennes)"
                >
                  Centre des Musiques Actuelles de Valenciennes
                </Link>
              </strong>{" "}
              (en 1994).
              <br />
              Il commence à travailler la guitare en autodidacte en 1995, puis
              reussit l'audition d'entrée au{" "}
              <strong>
                <Link
                  to={"https://conservatoire.lille.fr/"}
                  target="_blanck"
                  title="Conservatoire National de Région de Lille"
                >
                  Conservatoire National de Région de Lille
                </Link>
              </strong>{" "}
              en classe de jazz à la basse électrique (en 1996 alors qu'il
              n'avait que 2 ans 1/2 de pratique) puis dans ce même conservatoire
              en cursus classique au sein de la classe de contrebasse à cordes
              (en 1998).
            </p>
          </div>

          <div className={`${style.content} d-flex aic`}>
            <p className={style.pr}>
              En 1999 Il joue dans pas moins de 11 groupes simultanément (pros
              ou semi-pros), travaille la basse électrique 8 à 12 heures par
              jour en démultipliant les exercices et en étudiant un maximum de
              styles.
              <br />
              En 2001, Frédéric quitte la région Lilloise, continue de
              développer son jeu de guitare et de basse grâce à un projet solo
              (enregistrement d'une composition d'un style different par semaine
              pendant 4 ans), et commence à donner plus de cours de guitare et
              basse.
              <br />
              En 2003, après quelques années de recherches infructueuses du
              batteur idéal pour jouer sa musique, il commence à travailler la
              batterie en autodidacte.
              <br />
              En 2004 il se fait construire une guitare électrique 8 cordes et
              commence à se pencher plus serieusement sur la pédagogie et à
              travailler sur un projet de cours de guitare électrique et basse
              entièrement en solfège.
              <br />
            </p>
            <div className={`${style.img}`}>
              <img
                className={`${style.imageBio2}`}
                src={imageBio2}
                alt="image bio 2"
              />
            </div>
          </div>

          <p>
            En 2005, il intègre le{" "}
            <strong>
              <Link
                to={"https://www.douai.fr/conservatoire-rayonnement-regional"}
                target="_blank"
                title={"Conservatoire National de Région de Douai"}
              >
                Conservatoire National de Région de Douai
              </Link>
            </strong>{" "}
            en classe de jazz à la guitare 8 cordes. À force d'introspection, il
            commence à visualiser les grandes lignes de ce qui pourrait etre son
            style musical personnel, une sorte de jazz-métal teinté de musique
            contemporaine débordant de polyrythmes et de polymodalité...
            <br />
            En septembre 2008, il est membre de la commission pédagogique
            musiques amplifiées de la{" "}
            <strong>
              <Link
                to={"https://www.cmf-musique.org/"}
                target="_blank"
                title={"Confédération Musicale de France"}
              >
                CMF
              </Link>
            </strong>{" "}
            (Confédération Musicale de France, composée entre autres de Francis
            Darizcuren, Patrick Rondat, Thomas Bressel, Pascal Mulot, Jean
            Barthélemy ...).
            <br />
          </p>
          <div className={`${style.content} d-flex aic`}>
            <div className={`${style.img}`}>
              <img
                className={`${style.imageBio3}`}
                src={imageBio3}
                alt="image bio 3"
              />
            </div>
            <p className={style.pl}>
              Fin septembre 2009, il Commence à rédiger ses propres méthodes de
              guitare électrique et de basse électrique, dont les principaux
              concepts sont : l'hyper-progressivité, le respect de la vitesse
              d'assimilation du cerveau, l'acquisition d'un répertoire et le
              développement d'un style de jeu personnel.
              <br />
              En 2010, il choisit de ne plus travailler en école de musique
              (impossibilité de pratiquer une pédagogie efficace) et travaille
              depuis en indépendant.
              <br />
              En juin 2015, il finit de rédiger sa méthode de basse{" "}
              <strong>
                <NavLink
                  to={"/cours/basse"}
                  target="_blank"
                  title="Cours de basse"
                >
                  Continuum basse
                </NavLink>
              </strong>{" "}
              de <b>{Constants.pagesbasse} pages</b>.
              <br />
              En décembre 2015, il finit de rédiger sa méthode de guitare{" "}
              <strong>
                <NavLink
                  to={"/cours/guitare"}
                  target="_blank"
                  title="Cours de guitare"
                >
                  Continuum guitare
                </NavLink>
              </strong>{" "}
              de <b>{Constants.pagesguitare} pages</b>.
              <br />
              En 2018, il commence à travailler plus sérieusement le chant tout
              d'abord en autodidacte puis en prenant des cours de chant rock et
              enfin en intégrant le classe de chant lyrique du conservatoire de
              Béthune (en 2021).
              <br />
              Frédéric enseigne actuellement la guitare électrique, la basse
              électrique, la MAO, le solfège et l'improvisation sur
              Noeux-les-mines et également par webcam (parfois aussi loin que le
              Québec). Il participe à divers projets internationaux de musique
              contemporaine et expérimentale.
            </p>
          </div>
          <div>
            <p>
              Pendant ses années d'apprentissage il a participé à des stages et
              masterclass avec :
              <br />
              Michel Hatzigeorgiou, Francois Laizeau, Albert Marcoeur, Dominique
              Pifarély, Francois Corneloup, François Couturier, Jean claude
              Rapin, Farid Aouameur, Patrick Rondat, Daniel Goyonne, Jean Marie
              Ecay, André Ceccarelli, Pascal Mulot, Louis Winsberg, Tony
              Ballester, Claudia Philips, Fred Savinien...
              <ul className={`${style.stages}`}>
                <i>Autres stages :</i>
                <li>
                  <Link
                    to={"https://www.finalemusic.com/"}
                    target="_blank"
                    title="finalemusic.com"
                  >
                    logiciel Finale
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://frsm-hautsdefrance.fr/vie-federale/stages"}
                    target="_blank"
                    title="Stage de direction d'orchestre"
                  >
                    direction d'orchestre
                  </Link>
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className={`${style.influences}`}>
          <h2>INFLUENCES</h2>
          <div className={`${style.content} d-flex aic`}>
            <div>
              <p className={style.pr}>
                Même si mes influences musicales n'entrent pas en compte dans
                mon enseignement (j'essaie de rester neutre et de ne pas
                influencer stylistiquement mes élèves), que{" "}
                <b>j'aime la musique dans sa globalité</b> et que j'ai comme
                tout le monde été influencé par les artistes les plus connus qui
                ne vont pas être cités ci-dessous, voici mes principales
                influences:
              </p>
              <ul className={style.pr}>
                <li>Jazz: Thelonious Monk, John Coltrane, Aka Moon</li>
                <li>Rap: Public Enemy, KRS-One, Ol'Dirty Bastard, Mystikal</li>
                <li>
                  Reggae/Dancehall: Steel pulse, Sizzla, Queen Omega, Jalifa
                </li>
                <li>
                  Rock/Metal/Exp: Black Sabbath, Faith no more, Fredrik
                  Thordendal (Meshuggah), Frank Zappa, Mats & Morgan Band
                </li>
                <li>
                  Quelques compositeurs baroques, romantiques et modernes dont
                  J.S. Bach, Igor Stravinsky, Frédéric Chopin...
                </li>
                <li>
                  Les musiques de jeux videos des années 80/90 (minimalisme
                  imposé par le 8-bit)
                </li>
                <li>
                  La musique contemporaine (la recherche de nouvelles harmonies
                  et sonorités me plait énormément)
                </li>
                <li>
                  Énormément de "world music" et de musique traditionnelle de
                  différents pays du monde
                </li>
              </ul>
            </div>
            <div className={`${style.img}`}>
              <img
                className={`${style.imageBio5}`}
                src={imageBio5}
                alt="image bio 5"
              />
            </div>
          </div>
        </section>
        <section className={`${style.objectifs}`}>
          <h2>OBJECTIFS</h2>
          <div className={`${style.content} d-flex`}>
            <div className={`${style.img}`}>
              <img
                className={`${style.imageBio4}`}
                src={imageBio4}
                alt="image bio 4"
              />
            </div>
            <p className={style.pl}>
              Mon but en tant que professeur d'instrument est de former des{" "}
              <b>musiciens complets </b>en leur permettant d'évoluer jusqu'à une{" "}
              <b>pratique amateur autonome</b>, ou plus loin si la motivation
              est au rendez-vous, je compte d'ailleurs de plus en plus parmi mes
              élèves de <b>musiciens pros</b> ou d'autres{" "}
              <b>profs d'instrument</b>.
              <br />
              Mes cours permettent d'aborder un bon nombre de genres musicaux,
              de supprimer les blocages et de <b>briser les clivages</b> afin de
              ne pas se sentir "emprisonné" dans un style ou un niveau, en
              d'autres termes : <b>Rendre le musicien plus libre !</b>
            </p>
          </div>
        </section>

        <section className={`${style.discography}`}>
          <h2>GROUPES & DISCOGRAPHIE</h2>
          <div className={`${style.mosaic}`}>
            {albums.map(
              (a) =>
                a.ul &&
                a.ul.map(
                  (b, i) =>
                    b.img && (
                      <div key={i} className={`${style.album}`}>
                        <h3
                          onClick={() => scrollToSection(b.li)}
                          className={style.title}
                        >
                          {b.title}
                        </h3>
                        <img
                          className={style.pochette}
                          src={
                            process.env.PUBLIC_URL + "/albums/" + b.img + ".jpg"
                          }
                          alt={b.img}
                        />
                      </div>
                    )
                )
            )}
          </div>
          <div
            className={`${style.container} d-flex jcc
          `}
          >
            <div className={`${style.list}`}>
              {albums.map((a, i) => (
                <ul key={i}>
                  <li>
                    <p
                      id={a.strong}
                      className={`${target === a.strong && style.targeted}`}
                    ></p>
                    <strong>{a.strong} </strong>
                    <img
                      className={`${style.flags}`}
                      src={getFlag(a.flag)}
                      alt={a.flag}
                    />
                    <strong> : </strong>
                    {a.texte}
                    {a.ul && (
                      <ul>
                        {a.ul.map((b, index) => (
                          <li
                            key={index}
                            id={b.li}
                            className={`${target === b.li && style.liTargeted}`}
                          >
                            {getCd(b.li)}
                            {b.li}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
