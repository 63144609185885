import { useState, useEffect } from "react";
import style from "./form.module.scss";
import sliderData from "./sliderData";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { sendMail } from "../../../apis/contact";

export default function Form() {
  const [sliderIndex, setSliderIndex] = useState(1);
  const [listErrors, setListErrors] = useState(0);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error(error));
  }, [ipAddress]);

  // TOGGLE CONDITIONS
  const [toggleCGU, setToggleCGU] = useState(false);

  function toggle(indexPayload) {
    setSliderIndex((state) => {
      if (indexPayload + state > sliderData.length) {
        return 1;
      } else if (indexPayload + state < 1) {
        return sliderData.length;
      } else {
        return state + indexPayload;
      }
    });
  }

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    firstname: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    age: yup.string().required("Ce champ doit être saisi"),
    adress: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    zipcode: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    town: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    mail: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    mailconfirm: yup
      .string()
      .required("Ce champs doit être saisi")
      .oneOf([yup.ref("mail")], "Les adresses e-mail sont différentes"),
    phone: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    message: yup
      .string()
      .required("Ce champ doit être saisi")
      .min(2, "Au moins 2 caractères"),
    instru: yup.string().required("Veuillez sélectionner votre instrument"),
    level: yup.string().required("Veuillez sélectionner votre niveau actuel"),
    course: yup
      .string()
      .required("Veuillez sélectionner le type de cours souhaité"),
    confirm1: yup
      .boolean()
      .oneOf([true], "Vous devez valider les tarifs et conditions"),
    confirm2: yup
      .boolean()
      .oneOf(
        [true],
        "Vous devez confirmer que vous avez pris connaissance des informations suivantes"
      ),
    confirm3: yup
      .boolean()
      .oneOf(
        [true],
        "Vous devez confirmer que vous avez pris connaissance des conditions générales d'utilisation"
      ),
  });

  const initialValues = {
    name: "",
    surname: "",
    age: "",
    adress: "",
    zipcode: "",
    town: "",
    mail: "",
    mailconfirm: "",
    phone: "",
    message: "",
    instru: "",
    level: "",
    course: "",
    confirm1: false,
    confirm2: false,
    confirm3: false,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
  } = useForm({
    initialValues,
    resolver: yupResolver(validationSchema),
  });

  const submit = handleSubmit(async (values) => {
    values.ip = ipAddress;
    try {
      sendMail(values);
      clearErrors();
    } catch (error) {
      clearErrors();
      console.error(error);
    }
  });

  useEffect(() => {
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [errors]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // confirmation d'envoi
  if (isSubmitSuccessful) {
    scrollToTop();
    return (
      <div className={`${style.submitSuccessful} d-flex aic jcse`}>
        <h3>
          Votre demande de contact pour les cours{" "}
          <font color="#ffa200">a bien été envoyée à Frédéric</font>, il devrait
          normalement vous répondre sous 24 heures !
        </h3>
      </div>
    );
  }

  return (
    <form className={`${style.inputContainer}`} onSubmit={submit}>
      {pageWidth < 769 && (
        <div className={`${style.status} d-flex aic jcse`}>
          <div
            onClick={() => sliderIndex > 1 && toggle(-1)}
            className={`${style.navigationButton} ${style.prevButton}`}
            style={{ filter: sliderIndex === 1 && "opacity(0.5)" }}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <p className={`${style.indexInfo} d-flex aic jcse`}>
            {sliderIndex} / {sliderData.length}
          </p>
          <div
            onClick={() => sliderIndex < 4 && toggle(1)}
            className={`${style.navigationButton} ${style.prevButton}`}
            style={{ filter: sliderIndex === 4 && "opacity(0.5)" }}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      )}
      {pageWidth < 769 &&
        (errors.name ||
          errors.firstname ||
          errors.age ||
          errors.adress ||
          errors.zipcode ||
          errors.town ||
          errors.mail ||
          errors.mailconfirm ||
          errors.phone ||
          errors.message ||
          errors.instru ||
          errors.level ||
          errors.course ||
          errors.confirm1 ||
          errors.confirm2) && (
          <div className={`${style.listErrors}`}>
            Vous devez remplir les champs suivants :
            <ul>
              {errors.name && <li>nom,</li>}
              {errors.firstname && <li>prénom,</li>}
              {errors.age && <li>âge,</li>}
              {errors.adress && <li>adresse,</li>}
              {errors.zipcode && <li>code postal,</li>}
              {errors.town && <li>ville,</li>}
              {errors.mail && <li>e-mail,</li>}
              {errors.mailconfirm && <li>confirmation e-mail,</li>}
              {errors.phone && <li>téléphone,</li>}
              {errors.message && <li>message,</li>}
              {errors.instru && <li>instrument,</li>}
              {errors.level && <li>niveau,</li>}
              {errors.course && <li>cours,</li>}
              {(errors.confirm1 || errors.confirm2 || errors.confirm3) && (
                <li>conditions</li>
              )}
            </ul>
          </div>
        )}
      <div className={`${style.slider}`}>
        {(pageWidth > 768 ||
          sliderData.find((obj) => obj.id === sliderIndex).description ===
            "Step1") && (
          <section className={`${style.step1} d-flex f-column aic`}>
            <label htmlFor="name"></label>
            <div className="error">
              {errors.name && <p className="message">{errors.name.message}</p>}
              <input
                className={errors.name && "inputError"}
                name="name"
                type="text"
                placeholder="Nom"
                {...register("name")}
              />
            </div>
            <label htmlFor="firstname"></label>
            <div className="error">
              {errors.firstname && (
                <p className="message">{errors.firstname.message}</p>
              )}
              <input
                className={errors.firstname && "inputError"}
                name="firstname"
                type="text"
                placeholder="Prénom"
                {...register("firstname")}
              />
            </div>
            <label htmlFor="age"></label>
            <div className="error">
              {errors.age && <p className="message">{errors.age.message}</p>}
              <input
                className={errors.age && "inputError"}
                name="age"
                type="text"
                placeholder="Âge"
                {...register("age")}
              />
            </div>
            <label htmlFor="adress"></label>
            <div className="error">
              {errors.adress && (
                <p className="message">{errors.adress.message}</p>
              )}
              <textarea
                className={`adress ${errors.adress && "inputError"}`}
                name="adress"
                type="text"
                placeholder="Adresse"
                {...register("adress")}
              ></textarea>
            </div>
            <label htmlFor="zipcode"></label>
            <div className="error">
              {errors.zipcode && (
                <p className="message">{errors.zipcode.message}</p>
              )}
              <input
                className={errors.zipcode && "inputError"}
                name="zipcode"
                type="text"
                placeholder="Code postal"
                {...register("zipcode")}
              ></input>
            </div>
            <label htmlFor="town"></label>
            <div className="error">
              {errors.adress && (
                <p className="message">{errors.town.message}</p>
              )}
              <input
                className={errors.town && "inputError"}
                name="town"
                type="text"
                placeholder="Ville"
                {...register("town")}
              ></input>
            </div>
            <label htmlFor="mail"></label>
            <div className="error">
              {errors.mail && <p className="message">{errors.mail.message}</p>}
              <input
                className={errors.mail && "inputError"}
                name="mail"
                type="email"
                placeholder="Adresse e-mail"
                {...register("mail")}
              />
            </div>
            <label htmlFor="mailconfirm"></label>
            <div className="error">
              {errors.mailconfirm && (
                <p className="message">{errors.mailconfirm.message}</p>
              )}
              <input
                className={errors.mailconfirm && "inputError"}
                name="mailconfirm"
                type="email"
                placeholder="Confirmer l'adresse e-mail"
                {...register("mailconfirm")}
              />
            </div>
            <label htmlFor="phone"></label>
            <div className="error">
              {errors.phone && (
                <p className="message">{errors.phone.message}</p>
              )}
              <input
                className={errors.phone && "inputError"}
                name="phone"
                type="tel"
                placeholder="Téléphone"
                src={"nextChevron"}
                {...register("phone")}
              />
            </div>
            {pageWidth < 769 && (
              <div className={`${style.btnBloc}`}>
                <div
                  className={`${style.nextButton}`}
                  onClick={() => listErrors !== 1 && toggle(1)}
                >
                  SUIVANT
                </div>
              </div>
            )}
          </section>
        )}

        {(pageWidth > 768 ||
          sliderData.find((obj) => obj.id === sliderIndex).description ===
            "Step2") && (
          <section className={`${style.step2} d-flex f-column`}>
            <label htmlFor="message"></label>
            <div className="error">
              {errors.message && (
                <p className="message">{errors.message.message}</p>
              )}
              <textarea
                className={errors.message && "inputError"}
                name="message"
                id="message"
                placeholder="Message..."
                {...register("message")}
              ></textarea>
            </div>
            {pageWidth < 769 && (
              <div className={`${style.btnBloc}`}>
                <div
                  className={`${style.prevButton}`}
                  onClick={() => toggle(-1)}
                >
                  PRECEDANT
                </div>
                <div
                  className={`${style.nextButton}`}
                  onClick={() => listErrors !== 2 && toggle(1)}
                >
                  SUIVANT
                </div>
              </div>
            )}
          </section>
        )}

        {/* === STEP 3 === */}
        {(pageWidth > 768 ||
          sliderData.find((obj) => obj.id === sliderIndex).description ===
            "Step3") && (
          <section className={`${style.step3}`}>
            {/* === FIELDSET 1 === */}
            <fieldset className={`${errors.course && "message"}`}>
              <legend htmlFor="instru">INSTRUMENT</legend>
              <div className={`${style.radioContainer} d-flex aic jcs`}>
                <div>
                  <input
                    {...register("instru")}
                    type="radio"
                    name="instru"
                    value="guitare électrique"
                    id="gElec"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="gElec">Guitare électrique</label>
              </div>
              <div className={`${style.radioContainer} d-flex aic jcs`}>
                <input
                  {...register("instru")}
                  type="radio"
                  name="instru"
                  value="guitare classique"
                  id="gClass"
                  className={`${style.radio} radio`}
                />
                <label htmlFor="gClass">Guitare classique</label>
              </div>
              <div className={`${style.radioContainer} d-flex aic jcs`}>
                <input
                  {...register("instru")}
                  type="radio"
                  name="instru"
                  value="guitare folk"
                  id="gFolk"
                  className={`${style.radio} radio`}
                />
                <label htmlFor="gFolk">Guitare folk</label>
              </div>
              <div className={`${style.radioContainer} d-flex aic jcs`}>
                <div>
                  <input
                    {...register("instru")}
                    type="radio"
                    name="instru"
                    value="basse électrique"
                    id="bass"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="bass">Basse électrique</label>
              </div>
            </fieldset>

            {/* === FIELDSET 2 === */}
            <fieldset className={`${errors.course && "message"}`}>
              <legend htmlFor="lvl">NIVEAU</legend>
              <div className={`${style.radioContainer} d-flex ais jcs`}>
                <div>
                  <input
                    {...register("level")}
                    type="radio"
                    id="lvl1"
                    name="level"
                    value="débutant"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="lvl1">
                  Débutant: Débuter avec les bonnes bases
                </label>
              </div>
              <div className={`${style.radioContainer} d-flex ais jcs`}>
                <div>
                  <input
                    {...register("level")}
                    type="radio"
                    id="lvl2"
                    name="level"
                    value="moyen"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="lvl2">
                  Moyen: Revoir les bases correctement et aller plus loin
                </label>
              </div>
              <div className={`${style.radioContainer} d-flex ais jcs`}>
                <div>
                  <input
                    {...register("level")}
                    type="radio"
                    id="lvl3"
                    name="level"
                    value="confirmé"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="lvl3">
                  Confirmé: (Activité scénique régulière) Faire le travail de
                  fond pour continuer à s'améliorer
                </label>
              </div>
              <div className={`${style.radioContainer} d-flex ais jcs`}>
                <div>
                  <input
                    {...register("level")}
                    type="radio"
                    id="lvl4"
                    name="level"
                    value="pro / prof"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="lvl4">
                  Musicien pro / Prof d'instrument: Se perfectionner en tant que
                  musicien et prof pour faire la différence
                </label>
              </div>
            </fieldset>

            {/* === FIELDSET 3 === */}
            <fieldset className={`${errors.course && "message"}`}>
              <legend htmlFor="course">TYPE DE COURS SOUHAITÉ</legend>
              <div className={`${style.radioContainer} d-flex ais jcs`}>
                <div>
                  <input
                    {...register("course")}
                    type="radio"
                    name="course"
                    id="home"
                    value="Cours sur Noeux-les-mines"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="home">
                  Cours sur Noeux-les-mines (salle équipée)
                </label>
              </div>
              <div className={`${style.radioContainer} d-flex ais jcs`}>
                <div>
                  <input
                    {...register("course")}
                    type="radio"
                    name="course"
                    id="discord"
                    value="Cours par webcam via Discord"
                    className={`${style.radio} radio`}
                  />
                </div>
                <label htmlFor="discord">
                  Cours par webcam via Discord (adultes non débutants
                  francophones or English-speaking)
                </label>
              </div>
            </fieldset>
            {pageWidth < 769 && (
              <div className={`${style.btnBloc}`}>
                <div
                  className={`${style.prevButton}`}
                  onClick={() => toggle(-1)}
                >
                  PRECEDANT
                </div>
                <div
                  className={`${style.nextButton}`}
                  onClick={() => listErrors !== 3 && toggle(1)}
                >
                  SUIVANT
                </div>
              </div>
            )}
          </section>
        )}

        {/* === STEP 4 === */}
        {(pageWidth > 768 ||
          sliderData.find((obj) => obj.id === sliderIndex).description ===
            "Step4") && (
          <section className={`${style.step4} d-flex`}>
            <div className={`${style.conditionContainer} d-flex`}>
              <div className={`${style.checkboxContainer} d-flex f-column`}>
                {errors.confirm1 && (
                  <p className="labelError">{errors.confirm1.message}</p>
                )}
                <div className="d-flex">
                  <div>
                    <input
                      type="checkbox"
                      id="confirm1"
                      name="confirm1"
                      className={`${style.checkbox} checkbox`}
                      {...register("confirm1")}
                    />
                  </div>
                  <label htmlFor="confirm1">
                    J'accepte ces tarifs et conditions : <br />
                    Le tarif "à la carte" est de 35€/h de cours sans aucune
                    autre condition. <br />
                    Le tarif <b>abonnement</b> est de <b>86€/mois</b> du 1er au
                    dernier jour du mois par virement mensuel à raison d'un
                    cours d'une heure par semaine et ce toute l'année, non
                    remboursable, tout cours annulé non rattrapé dans les 15
                    jours est dû (possibilité d'anticiper les rattrapages).
                  </label>
                </div>
              </div>
              <div className={`${style.checkboxContainer} d-flex f-column`}>
                {errors.confirm2 && (
                  <p className="labelError">{errors.confirm2.message}</p>
                )}
                <div className="d-flex">
                  <div>
                    <input
                      type="checkbox"
                      id="confirm2"
                      name="confirm2"
                      className={`${style.checkbox} ${
                        errors.confirm2 && "checkboxError"
                      } checkbox`}
                      {...register("confirm2")}
                    />
                  </div>
                  <label htmlFor="confirm2">
                    J'ai lu attentivement les informations relatives aux cours
                    sur le site, j'ai bien compris la pédagogie employée, le
                    caractère progressif de la méthode et la nécessité d'un
                    travail personnel quotidien. Je ne me lance donc pas sur un
                    coup de tête.
                  </label>
                </div>
              </div>

              {/* CGU IP */}
              <div className={`${style.checkboxContainer} d-flex f-column`}>
                {errors.confirm3 && (
                  <p className="labelError">{errors.confirm3.message}</p>
                )}
                <div className="d-flex">
                  <div>
                    <input
                      type="checkbox"
                      id="confirm3"
                      name="confirm3"
                      className={`${style.checkbox} ${
                        errors.confirm3 && "checkboxError"
                      } checkbox`}
                      {...register("confirm3")}
                    />
                  </div>
                  <div>
                    <label htmlFor="confirm3">
                      J'accepte la collecte de mon adresse IP et celle des
                      données personnelles entrées lors de l'envoi de ce
                      formulaire de contact à des fins de gestion des cours et
                      de sécurité.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.CGU} d-flex`}>
              <p>
                <strong>Vos données personnelles</strong>
                <div
                  onClick={() => setToggleCGU(!toggleCGU)}
                  className={`${style.CGUtext} d-flex`}
                >
                  {toggleCGU ? (
                    <p>
                      <small>
                        Réduire <i className="fa-solid fa-chevron-up"></i>
                        <br />
                        La collecte des données personnelles entrées dans ce
                        formulaire me permet une bonne gestion des cours.
                        <br />
                        La collecte de l'adresse IP me permet de détecter et
                        prévenir toute activité frauduleuse ou malveillante sur
                        mon site.
                        <br />
                        Je ne partage ces données avec aucun tiers, sauf si la
                        loi m'y oblige ou si cela est nécessaire pour assurer la
                        sécurité de mes systèmes.
                        <br />
                        Les données des contacts ne devenant pas élèves ne sont
                        pas utilisées et sont supprimées automatiquement après
                        10 ans ou peuvent être détruites immédiatement sur
                        simple demande.
                        <br />
                        En utilisant ce formulaire de contact, vous acceptez
                        expressément la collecte et l'utilisation de vos données
                        personnelles aux fins décrites ci-dessus.
                      </small>
                    </p>
                  ) : (
                    <p>
                      <small>
                        Lire <i className="fa-solid fa-chevron-down"></i>
                      </small>
                    </p>
                  )}
                </div>
              </p>
            </div>
            <div className={`${style.btnBloc}`}>
              {pageWidth < 769 && (
                <div
                  className={`${style.prevButton}`}
                  onClick={() => toggle(-1)}
                >
                  PRECEDANT
                </div>
              )}
              <button disabled={isSubmitSuccessful} className={`${style.send}`}>
                ENVOYER
              </button>
            </div>
          </section>
        )}
      </div>
    </form>
  );
}
