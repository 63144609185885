import style from "./burger.module.scss";
import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Burger() {
  const [burger, setBurger] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      const marginTop = 0;

      const scrollToY =
        element.getBoundingClientRect().top + window.scrollY - marginTop;

      window.scrollTo({ top: scrollToY, behavior: "smooth" });
    }
  };

  return (
    <nav className={`${style.nav} ${burger && style.background} d-flex jce`}>
      {burger ? (
        <>
          <ul className={`${style.ul} d-flex jcsb`}>
            <li>
              <NavLink title="PRÉSENTATION" to={"/biographie"}>
                PRÉSENTATION
              </NavLink>
            </li>
            <li className={`${style.methode}`}>
              <NavLink to={"/cours"} title="COURS ET MÉTHODES">
               MÉTHODES
              </NavLink>
              {width > 768 && (
                <ul className={`${style.methodes}`}>
                  <li>
                    <NavLink title="GUITARE" to={"/cours/guitare"}>
                      GUITARE
                    </NavLink>
                  </li>
                  <li>
                    <NavLink title="BASSE" to={"/cours/basse"}>
                      BASSE
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink title="AVIS" to={"/avis"}>
                AVIS
              </NavLink>
            </li>
            <li className={`${style.methode}`}>
              <NavLink to={"/interactif"} title="INTERACTIF">
                INTERACTIF
              </NavLink>
              {width > 768 && (
                <ul className={`${style.methodes}`}>
                  <li>
                    <NavLink title="IMPRO" to={"/interactif/improvisation"}>
                      IMPRO
                    </NavLink>
                  </li>
                  <li>
                    <NavLink title="EAR TRAINER" to={"/interactif/eartrainer"}>
                      EAR TRAINER
                    </NavLink>
                  </li>
                  <li>
                    <NavLink title="PROBA" to={"/interactif/proba"}>
                      PROBA
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink title="BLOG" to={"/blog"}>
                ÉCRITS
              </NavLink>
            </li>
            <li>
              <NavLink title="CONTACT" to={"/contact"}>
                CONTACT
              </NavLink>
            </li>
          </ul>
          <i
            className="fa-solid fa-xmark"
            onClick={() => {
              setBurger(!burger);
            }}
          ></i>
        </>
      ) : (
        <i
          className={`${style.bar} fa-solid fa-bars`}
          onClick={() => {
            setBurger(!burger);
          }}
        ></i>
      )}
    </nav>
  );
}
