import { NavLink } from "react-router-dom";
import style from "./interactif.module.scss";
import img1 from "../../assests/images/impro.png";
import img2 from "../../assests/images/eartrainer.png";
import img3 from "../../assests/images/proba.png";
import ShortDivider from "../../components/divers/shortDiver";
import { Helmet } from "react-helmet";

export default function Interactif() {
  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Interactif</title>
      </Helmet>
      <main className={`${style.interactif} d-flex f-column aic`}>
        <section className={`${style.intro} d-flex f-column jcc aic`}>
          <h1>INTERACTIF</h1>
          <p>Progressez à travers ces trois interactions</p>
        </section>
        <section className={`${style.section} d-flex aic jcsa`}>
          <div className={`${style.container}`}>
            <h2>IMPRO</h2>
            <ShortDivider />
            <p>IMPROVISATION JOURNALIÈRE</p>
            <article className={`${style.article}`}>
              <button className={`${style.btn}`}>
                <NavLink
                  to={"/interactif/improvisation"}
                  className={`${style.a}`}
                >
                  <img src={img1} alt={img1} />
                </NavLink>
              </button>
            </article>
          </div>
          <div className={`${style.container}`}>
            <h2>EAR TRAINER</h2>
            <ShortDivider />
            <p>TRAVAIL DE L'OREILLE MUSICALE</p>
            <article className={`${style.article}`}>
              <button className={`${style.btn}`}>
                <NavLink to={"/interactif/eartrainer"} className={`${style.a}`}>
                  <img src={img2} alt={img2} />
                </NavLink>
              </button>
            </article>
          </div>
          <div className={`${style.container}`}>
            <h2>PROBA</h2>
            <ShortDivider />
            <p>COMPOSITION & PROBABILITÉS</p>
            <article className={`${style.article}`}>
              <button className={`${style.btn}`}>
                <NavLink to={"/interactif/proba"} className={`${style.a}`}>
                  <img src={img3} alt={img3} />
                </NavLink>
              </button>
            </article>
          </div>
        </section>
      </main>
    </>
  );
}
