import { scrollToSection } from "../../functions/scroll";
import style from "./scroller.module.scss";

export default function Scroller({ section, text }) {
  return (
    <div
      className={style.scroller}
      onClick={() => scrollToSection(section)}
      data-aos="fade-up"
      data-aos-anchor-placement="center-bottom"
      data-aos-delay="300"
    >
      <p>{text}</p>
    </div>
  );
}
