export const rate = [
  {
    img: "rem.jpg",
    name: "Julien",
    instru: "Guitare électrique",
    text: [
      `Bientôt 3 mois que je prends des cours avec Frédéric. C'est désormais chaque semaine un rdv que j'attends avec impatience et que j'apprécie tant par la qualité des supports (on est réellement sur de l'étude avec des partitions et plus encore) que par la faculté de l'enseignant à maintenir une exigence que je qualifierais d'intelligente: lorsqu'on doute il sait rassurer mais il n'hésitera pas non plus à mettre le doigt sur des petits détails insignifiants qui finiront par devenir un axe de travail insoupçonné.`,
      <br />,
      `J'ai bientôt 40 ans et j'ai acheté ma première guitare à 20 ans. J'ai utilisé les tablatures du commerce ou les différents "tutos" internet. En 3 mois avec ses cours j'ai progressé d'une manière que j'appellerais fulgurante en comparaison à mon "expérience" de gratteux.`,
      <br />,
      `Si vous êtes jeune, moins jeune, et que vous souhaitez vous lancer ou que vous avez un enfant qui a de l'appétance pour la guitare, ne l'envoyez pas sur internet, ne gaspillez pas votre argent sur des cours en ligne. Adressez vous à Frédéric.`,
    ],
    rate: 5,
  },
  {
    img: "david.png",
    name: "David",
    instru: "Guitare électrique",
    text: [
      `Les cours sont de qualité et suivent une méthode structurée, diversifiée, toute en progression. L'apprentissage théorique est très abordable et le répertoire est varié.`,
      <br />,
      `Tout ça dans une ambiance sympa.`,
      <br />,
      `Encadré depuis 3 ans par Fred, je recommande sans hésitation.`,
    ],
    rate: 5,
  },
  {
    img: "ig.jpg",
    name: "Cédric",
    instru: "Basse électrique, fretless, acoustique",
    text: [
      `Vous voulez connaître la musique?vous allez apprendre...avec une méthode entièrement écrite avec soins jusqu'au moindre détail par ce cher FRED, ça vous emmène dans un apprentissage hyper progressif où on loupe rien(solfège/pratique/technique/impro et j'en passe)c'est complet et comme il sait faire les choses le monsieur vous emmène dans tous les styles selon votre niveau avec sa méthode.`,
      <br />,
      `Si vous doutez il vous remettra dans le droit chemin c'est un puits de connaissances intarissable sur le sujet...on joue,on apprend,on comprend,on se fait plaisir...c'est parfait...`,
    ],
    rate: 5,
  },
  {
    img: "anita.jpg",
    name: "Anita",
    instru: "Guitare électrique",
    text: [
      `Voilà bientôt 5 ans que je prends des cours de guitare avec Fred . Ancienne musicienne , j'ai profité de ma retraite pour m'y remettre sérieusement . Ce n'est pas toujours facile mais le fait d'y travailler régulièrement porte ses fruits et petit à petit , j'avance dans la méthode . De plus, Fred est très à notre écoute et ne manque jamais de nous re-motiver quand la motivation n'est pas au rendez-vous. Super contente d'avoir un professeur tel que lui .`,
      <br />,
      `Merci Fred !`,
    ],
    rate: 5,
  },
  {
    img: "cynic.jpg",
    name: "Quentin",
    instru: "Basse électrique, basse fretless",
    text: [
      `Super prof, avec une méthode progressive qui aborde tous les sujets, depuis le théorique (solfège, exercises...) jusqu'aux applications pratiques avec des morceaux divers et variés. Absolument recommandé !`,
    ],
    rate: 5,
  },
  {
    img: "frederic.jpg",
    name: "Frédéric",
    instru: "Basse électrique",
    text: [
      `Bientôt 1 an que je prends des cours de basse avec Frédéric et j'en suis extrêmement satisfait !!! Contrairement à internet où on va dans tous les sens, la méthode de Fred est dirigée, on progresse avec la multiplication des exemples (beaucoup de morceaux) et on ne brûle pas les étapes !!! En plus Fred est très sympa, super content d'avoir pu trouver ses cours!!!!!`,
    ],
    rate: 5,
  },
  {
    img: "cyril.jpg",
    name: "Cyril",
    instru: "Guitare",
    text: [
      `A l'origine guitariste "occasionnel", voilà près de 6 mois que je prends des cours avec Fred; et je suis jusqu'alors super content de mes progrès 😀. La méthode est particulièrement sérieuse : on decouvre les notions en profondeur et de façon progressive (vraiment). Du coup l'apprentissage est palpable, efficace et régulier. Les exercices sont variés et intéressants. Pour public averti (mais Fred averti très bien) : la rigueur est de mise, on est pas en face de petits cours pour gratouiller, mais bien d'une réelle méthodologie pour devenir musicien 💪 La méthode est vraiment dingue, j'en parle régulièrement autour de moi, ta pédagogie est pour le moins super efficace. J'ai probablement beaucoup plus "appris de choses utiles à la percussion" avec toi en professeur de guitare; qu'avec n'importe qui d'autre de mon mini-monde percussif.`,
    ],
    rate: 5,
  },
  {
    img: "philippe.jpg",
    name: "Philippe",
    instru: "Basse électrique",
    text: [`Super sympa et méthode de qualité pour une progression efficace!`],
    rate: 5,
  },
  {
    img: "florentin.jpg",
    name: "Florentin",
    instru: "Basse électrique",
    text: [
      `Excellent prof !`,
      <br />,
      `Après 3 ans de pratique sur la basse, je n'arrivais plus à progresser et depuis qu'il m'enseigne, mon niveau ne fait que progresser et s'améliore.`,
      <br />,
      `Un apprentissage ludique, sans prise de tête (ou presque suivant certaines lignes) et une comprehension rapide des partitions, chose que je ne savais pas lire auparavant.`,
      <br />,
      `Maintenant 5 ans que je suis ses cours et mon niveau à fait que progresser.`,
      <br />,
      `Les cours sont funs et diversifiés. N'hésitez pas et foncez !`,
    ],
    rate: 5,
  },
  {
    img: "jazzbass70.jpg",
    name: "Kévin",
    instru: "Basse électrique",
    text: [
      `A tous les lecteurs...salut !`,
      <br />,
      `Voici mon profil: J'ai commencé la musique à l'age de 20 ans (groupes, repéts, concert, copains), puis, près de vingt ans plus tard j'ai repris les activités musicales, c'était il y a quelques mois.`,
      <br />,
      `Voici le résultat au bout de quelques mois d'apprentissage avec Monsieur Szymanski: Je sais à peu près déchiffrer une partition en clef de fa,(et à près de 40ans), mon son de basse est bien plus joli, plus dynamique, plus régulier, plus autoritaire quand il faut, plus rond quand il faut. Ma rythmique est devenue à peu près métronomique et même si je suis seul à jouer dans l'orchestre, la maison tient debout, et cela pour moi c'est essentiel en tant que bassiste. Ma capacité à improviser est montée en flèche. Je sonne juste, sans faute de goût, ni métronomique. Quand j'improvise sur l'accompagnement d'un morceau, ça fait le job ! (mais je joue simple) Et cerise sur le gâteau, je suis même capable de relever mes lignes de basse sur une portée en clef de Fa.`,
      <br />,
      `No pain no gain: Monsieur Szymanski a développé une méthode pédagogique efficace, et si je connais peu de choses en musique, j'ai des notions en pédagogie. Seulement tout cela nécessite de travailler, de travailler et de travailler encore. Comptez à peu près 1 heure par jour, plus au début de la méthode (apprentissage de la lecture).`,
      <br />,
      `En conclusion après quelques mois : Ca vaut le coup !!!`,
    ],
    rate: 5,
  },
  {
    img: "helene.jpg",
    name: "Hélène",
    instru: "Guitare électrique",
    text: [
      `Toi qui passes par ici et qui souhaites apprendre la guitare et/ou basse, quelques bonnes raisons de contacter Fred : `,
      <br />,
      `- la méthode est vraiment top (les avis sont unanimes), tu reprends les bases, tu travailles ton instrument et le solfège en même temps, tu (re)découvres des morceaux divers et variés...`,
      <br />,
      `- pédagogiquement parlant, Fred assure (parole de prof), il est dispo (t'as une question, besoin de reporter le cours, il a toujours une solution) et en plus, il est super sympa! Apprendre dans la joie et la bonne humeur, que demander de plus ?!`,
      <br />,
      `Convaincue par un ami (merci Seb!) je regrette juste de ne pas m'être décidée plus tôt !`,
    ],
    rate: 5,
  },
  {
    img: "germain.jpg",
    name: "Germain",
    instru: "Basse électrique",
    text: [
      `Elève de Fred depuis un peu moins de 2 ans, je peux affirmer que je prends de plus en plus de plaisir à découvrir la basse et la musique.`,
      <br />,
      `C'est un excellent prof et avant tout un super musicien, ce qui est la garantie d'être bien accompagné et de pouvoir progresser dans la maîtrise de son instrument.`,
      <br />,
      `Grâce à une excellente méthode progressive (qu'il lui-même écrite !), mélangeant théorie, improvisation et morceaux divers, on progresse à son rythme ! On fait du solfège de manière plus naturelle et moins contraignante mais surtout, c'est une méthode bien plus appliquée et utile.`,
      <br />,
      `Les morceaux permettent de mettre en pratique les dernières thématiques abordées. Ils sont d'ailleurs très variés et nombreux (On se surprend à passer d'un morceau de Nirvana à un autre des Supremes, The Cure aux Rolling Stones, Tracy Chapman à Marilyn Manson...)`,
      <br />,
      `C'est  donc très ouvert et c'est parfait pour passer en revue les éléments travaillés.`,
      <br />,
      `Si vous souhaitez apprendre la guitare ou la basse et que vous êtes prèts à prendre le temps de travailler votre instrument, je ne peux que vous recommander Fred et de suivre sa méthode ! Vous ne serez pas déçus !`,
    ],
    rate: 5,
  },
  {
    img: "lespaul.jpg",
    name: "Denis",
    instru: "Guitare électrique",
    text: [
      `Très pédagogue, patient et à l'écoute, Fred est un excellent prof (j'en suis moi-même un dans un tout autre domaine), il arrive à ne pas vous laisser vous démotiver à travers les exercices à la fois ludiques, différents en terme de styles et très évolutifs.`,
      <br />,
      `Un an tout rond passé à ses côtés, et je mesure de façon très significative les progrés réalisés en finalement peu de temps...`,
      <br />,
      `Merci, merci et pourvu que ça dure !`,
    ],
    rate: 5,
  },
  {
    img: "muse.jpg",
    name: "Céline",
    instru: "Guitare électrique",
    text: [
      `La guitare j'en ai toujours rêvé, je n'avais jamais osé (le solfège c'est rébarbatif, je n'ai pas l'oreille musicale, débuter à l'âge adulte c'est trop difficile...) et je suis tombée sur le site de Fred et je me suis lancée.`,
      <br />,
      `La méthode de Fred est très progressive et donc abordable même par les débutants n'ayant aucune notion musicale comme moi. Les exercices, les morceaux sont très variés donc on ne se lasse jamais.`,
      <br />,
      `Fred est d'une patience infinie , il sait encourager et dédramatiser quand je bloque.`,
      <br />,
      `C'est sûr qu'il faut bosser (c'est comme pour tout!) mais chaque semaine j'ai tellement envie de connaître la suite que ce n'est pas du tout une contrainte. Après 3 ans de cours, le plaisir et la motivation sont toujours là!!!`,
    ],
    rate: 5,
  },
  {
    img: "alex.jpg",
    name: "Alex",
    instru: "Guitare électrique",
    text: [
      `Je prends des cours de guitare avec Fred depuis 1 an maintenant. Je suis ravi des progrès réalisés.`,
      <br />,
      `Cette méthode vous permet d'apprendre le solfège et la guitare de façon ludique (pour une personne n'ayant aucune connaissance musicale: je trouve cela génial). Vous apprenez doucement la rigueur de la pratique d'un instrument de musique.`,
      <br />,
      `Après à vous de voir si vous préférez l' "à peu prés" des cours traditionnels ou la perfection.`,
      <br />,
      `MOI J'AI CHOISI`,
    ],
    rate: 5,
  },
  {
    img: "steph.jpg",
    name: "Stéphane",
    instru: "Basse électrique, basse fretless",
    text: [
      `M. Szymanski aborde le travail de l'instrument par les bases théoriques et classiques tout en mélangeant cela avec des exercices plus fun, tel que le travail de morceaux "connus". L'étude de l'instrument se fait sur plusieurs axes, l'apprentissage du solfège, le travail des morceaux et les grilles d'improvisation progressives qui permettent de croiser les données théoriques et la pratique de l'instrument. Il ne reste plus qu'a travailler constamment et consciencieusement les exercices pour voir la pratique de l'instrument évoluer au fil des cours.`,
    ],
    rate: 5,
  },
  {
    img: "angel.jpg",
    name: "Angélique",
    instru: "Guitare électrique",
    text: [
      `Merci, je suis partie de zéro et tu m'as appris beaucoup, simplement et dans la bonne humeur!`,
      <br />,
      `Bon courage pour la fin de l'écriture de ta méthode, je confirme l'inscription sur la boite aux lettres....cours de QUALITE!!!!`,
      <br />,
      `Biz, bonne continuation et une p'tite caresse au chien!!!`,
    ],
    rate: 5,
  },
  {
    img: "freddy.png",
    name: "Freddy",
    instru: "Guitare électrique",
    text: [
      `En cours avec Fred depuis un peu plus de deux ans après être passé par des écoles de musique. L'approche est totalement différente. Il a créé et continu de faire évoluer SA méthode, en la personnalisant à l'élève en fonction de l'évolution et des difficultés rencontrées. Je ne travaillais que la rythmique de façon basique sur des morceaux connus en école de musique. 
Fred nous fait travailler simultanément, l'arpège, la rythmique, la lecture des notes (pas de tablature) avec toutes les subtilités des écritures passant de morceaux dans tous les styles à des pages de lecture qui vous fait travailler et progresser rapidement...`,
      <br />,
      `Riche en conseils et astuces il a de plus une patience à toute épreuve, même aux plus stressés comme moi 😉.`,
      <br />,
      `Sa culture musicale est très étendue, il aborde tous les styles et vous accompagne à la batterie pour développer les différents rythmes quand ça coince un peu. Pour moi, c'est incontestablement : The Maestro.`,
      <br />,
      `Merci Fred.`,
    ],
    rate: 5,
  },
  {
    img: "cedric.jpg",
    name: "Cédric",
    instru: "Guitare classique",
    text: [
      `Après plus d'un an et demi de cours avec Frédéric, je dois dire que je ne regrette rien. Bonne humeur, écoute, pédagogie sont quelques mots qui reflètent bien le déroulement de chacun des cours.`,
      <br />,
      `Même en dehors des cours, lorsque je bloque, un petit mail à Frédéric et la réponse ne se fait jamais attendre. En ce qui concerne la méthode, j'ai été ravi lorsque j'ai appris qu'elle était écrite de A à Z par ses soins, ce qui évite de suivre une méthode que l'on pourrait acheter n'importe où et prouve qu'il y a un vrai travail de réflexion derrière tout ça et une réelle envie de produire des choses de qualité.`,
      <br />,
      `Ecrire une méthode aussi conséquente que la sienne sans réel engagement dans son travail n'est à mon sens pas possible. La méthode est très progressive et alterne des pages permettant de gagner en dextérité (tout en restant agréable à l'oreille) et d'apprendre le solfège tout doucement (pas de théorie sans pratique) et des morceaux de musique de tout genre permettant de s'ouvrir à d'autres styles musicaux.`,
      <br />,
      `Elle permet à n'importe qui, même sans la moindre notion de solfège d'apprendre et de comprendre.`,
    ],
    rate: 5,
  },
  {
    img: "aurel.jpg",
    name: "Aurélien",
    instru: "Guitare électrique",
    text: [
      `Deux années de cours et que de chemin parcouru!`,
      <br />,
      `La méthode est l'une des mieux organisée que j'ai eu l'occasion de pratiquer, les paliers sont très progressifs. On se trouve vite en conscience de ses progrès, ce qui est très motivant! Un grand merci au personnage Fred pour sa patience et l'obstination qu'il a mis à trouver un créneau horaire idéal pour nous deux malgré les six heures de décalage horaire qui nous séparaient!`,
      <br />,
      `Si vous cherchez une formation musicale sérieuse et motivante, vous êtes au bon endroit.`,
    ],
    rate: 5,
  },
  {
    img: "patrice.jpg",
    name: "Patrice",
    instru: "Guitare électrique",
    text: [
      `Après un an de cours avec un prof' qui n'avait pas vraiment de méthode, et un peu plus d'un an en autodidacte, donc en gros, après deux ans de galère, je suis tombé par hasard sur le site de Fred, et j'ai été intéressé par sa méthode. Je me suis dit, ENFIN un prof qui sait où il va, qui semble faire les choses dans l'ordre et qui en plus incorpore la théorie et la lecture de partition. Vraiment dommage qu'il ne soit pas dans mon coin...`,
      <br />,
      `Mais il donne des cours par Skype!`,
      <br />,
      `C'est un peu déroutant au début, mais personnellement je m'y suis vite habitué, et maintenant je trouve ça même plus pratique qu'en "vrai". Ca demande un tout petit peu d'efforts pour s'organiser et régler le matériel (comparé à mon prof ou j'arrivais je branchais ma guitare et en avant) mais bon rien d'insurmontable.`,
      <br />,
      `Concernant la méthode...`,
      <br />,
      `Jusqu'à maintenant, je trouve la méthode que Fred a écrite juste parfaite! Chaque semaine on progresse un peu (mais la progression est quasi constante sur la durée) dans chaque aspect du jeu de guitare et quand on regarde un ou deux mois en arrière on se rend compte qu'on avance vraiment, et plutôt vite, ce qui est agréable. Le répertoire est extrêmement varié (beaucoup plus que ne le laisse penser l'aperçu que l'on peut trouver sur le site) et les morceaux sont souvent accompagnés de commentaires du chef (sur la façon dont ça a été enregistré, les substances qu'on pris les musiciens lors de l'enregistrement et diverses autre anecdotes toujours intéressantes). En avançant, je me suis aussi rendu compte de l'immense travail en amont de l'écriture de la méthode. Chaque petite difficulté est isolée, décryptée, travaillée seule, puis intégrée dans des morceaux de plus en plus difficiles. Autre détail, concernant le solfège: la méthode est écrite pour des gens n'en ayant absolument aucune notion, ce qui était quasiment mon cas. Eh bien, l'apprentissage se fait tout doucement et directement sur l'instrument (pas de longues séances a taper sur la table comme un couillon).`,
      <br />,
      `Concernant Fred...`,
      <br />,
      `Fred est vraiment un super prof'! Etant donné que je prend mes cours par Skype je ne l'ai jamais rencontré, ni aucun de ses autres élèves, mais je n'ai pas le sentiment d'être moins important qu'un autre à cause du fait que tout reste virtuel. Il est disponible en permanence pour tout questions qu'on pourrait vouloir lui poser par mail, et a un temps de réponse qui oscille entre 2 sec et 1 minute les jours où il est un peu fatigué. Toujours ponctuel, toujours de bonne humeur, toujours à l'écoute, si vous avez envie d'apprendre à jouer de la musique, il vous y aidera à coup sûr!`,
    ],
    rate: 5,
  },
  {
    img: "greg.jpg",
    name: "Greg",
    instru: "Guitare électrique",
    text: [
      `Choisir Fred, c'est choisir une ouverture d'esprit, une véritable méthode et une pédagogie conçue en rupture avec l'enseignement classico-chaotique.`,
      <br />,
      `Depuis 1 an de cours, je ressens une véritable progression avec un plaisir et une envie croissante d'apprendre et de comprendre la musique après chaque cours. Prenez le temps de consulter les avis des autres élèves et de rencontrer Fred si vous souhaitez vous lancer.`,
      <br />,
      `Comme moi vous avez peut être la chance d'avoir Fred dans votre secteur, ou une bonne connexion Internet et une webcam : NE LA GACHEZ PAS 😉`,
    ],
    rate: 5,
  },
  {
    img: "louis.jpg",
    name: "Louis",
    instru: "Guitare électrique",
    text: [
      `J'aime les cours de guitare avec Frédéric parce qu'il vient à domicile, parce que c'est adapté à tout âge et car il n'est pas trop sévère et a de la patience.`,
      <br />,
      `Je découvre beaucoup de groupes et de musiques que je ne connaissais pas et il faut juste s'entraîner pour réussir.`,
    ],
    rate: 5,
  },
  {
    img: "sylviabbeyroad.jpg",
    name: "Sylvia",
    instru: "Guitare électrique, guitare classique",
    text: [
      `Cela fait un peu plus d'un an maintenant que je prends des cours avec Fred et le seul regret que j'ai, c'est de ne pas avoir commencé plus tôt !`,
      <br />,
      `J'apprécie particulièrement le fait de savoir lire les notes et de pouvoir déchiffrer et comprendre les partitions, plutôt que de suivre les tablatures. Cela me permet de bien pouvoir me repérer sur le manche et de bien assimiler ce que j'apprends !`,
      <br />,
      `Un autre point très positif : on découvre les exercices et morceaux à travailler pour la semaine suivante lors de chaque cours et cela crée un certain suspense et motive vraiment à s'entraîner pour progresser ! Les morceaux que l'on travaille sont très variés (époques, styles, rythmes, son clair / son saturé) et chacun y trouve son compte et peut se constituer un répertoire personnel. (Pour ma part, la grande fan des Beatles que je suis se régale avec des morceaux des Beatles, Nirvana, Bob Marley, ACDC ...)`,
      <br />,
      `Avec le temps, on se rend compte aussi que l'on écoute différemment les morceaux et que l'oreille se développe...`,
      <br />,
      `Quant à Fred, il a une patience à toute épreuve et prodigue de nombreux conseils. La méthode qu'il a créée permet de prendre confiance en soi et de progresser très très rapidement.`,
      <br />,
      `Alors, si vous êtes débutant(e), ne tardez pas davantage et foncez pour votre premier cours !`,
    ],
    rate: 5,
  },
  {
    img: "coralie.jpg",
    name: "Coralie",
    instru: "Guitare classique",
    text: [
      `Les cours avec Frédéric sont super parce qu'on apprend sans s'en rendre compte, on joue des morceaux faciles à interpréter et les cours ne durent ni trop ni pas assez longtemps.`,
      <br />,
      `Avis du papa :`,
      <br />,
      `Je trouve que le contact avec Coralie a été très bon et que la méthode utilisée permet aussi bien d'acquérir les connaissances de solfège que l'apprentissage de l'instrument en lui-même. Je suis heureux de voir qu'en 6 mois, Coralie qui n'avait jamais touché une guitare, réussit désormais à jouer quelques morceaux, aussi bien sur partition qu'à l'oreille.`,
    ],
    rate: 5,
  },
  {
    img: "luc.jpg",
    name: "Luc",
    instru: "Guitare folk",
    text: [
      `N'ayant reçu aucune formation musicale à part la formation obligatoire de fin de primaire et du collège, je me suis tourné vers l'apprentissage de la musique avant tout pour que mes enfants découvrent cet art.`,
      <br />,
      `Je me suis intéressé à la guitare et j'ai préféré prendre des cours d'emblée pour progresser rapidement. Travailler seul avec une méthode m'aurait découragé vu l'ampleur de la tâche et les différentes façons d'aborder l'instrument. Mon fils prend lui aussi des cours particuliers avec Frédéric et nous évoluons sur le même instrument à la même vitesse.`,
      <br />,
      `La méthode d'apprentissage proposée par Frédéric nous satisfait car nous abordons en même temps : lecture de la musique, les différents rythmes (pompe, arpèges….), les accords avec les redoutables barrés, les power chords….On touche à plusieurs difficultés d'apprentissage en variant régulièrement sans buter sur l'une d'entre elles. Le progrès avec plaisir !`,
      <br />,
      `En clair pour résumer sa méthode c'est apprendre en s'amusant ; avec quelques efforts personnels tout de même indispensables.`,
    ],
    rate: 5,
  },
  {
    img: "buried.jpg",
    name: "François",
    instru: "Basse électrique, basse fretless",
    text: [
      `La méthode de Fred est très intuitive. Avant de décider de prendre des cours, je lisais pas mal d'infos sur la toile, et ce qui me paraissait complexe m'est apparu d'une logique et d'une simplicité évidente ici. L'apprentissage de la musique peut paraitre rébarbatif, c'est un peu la crainte que j'avais (j'ai suivi un cours de solfège étant gosse, j'ai très vite arrêté tellement c'était chiant), mais ici les choses sont présentées de manière évidente. Chaque nouvelle info arrive dans la continuité des précédentes, et j'avoue trouver ça passionnant. Le fait de mettre en pratique rapidement tout ce qu'on apprend est également un facteur déterminant.`,
      <br />,
      `Les morceaux sont tous interessants à jouer, dans des styles différents et permettent d'appliquer et/ou de comprendre le cours précédent.`,
      <br />,
      `A côté de ça, Fred est une personne pédagogue, passionnée et qui sait transmettre sa passion (Même si des fois il dit qu'il parle trop 😉 c'est faux). Je ressors de chaque cours gonflé à bloc. On peut discuter de tous les styles musicaux, Fred est une mine de connaissances, très ouvert, et qui applique cette ouverture d'esprit à ses cours.`,
      <br />,
      `Update après 2 ans (déjà) de cours:`,
      <br />,
      `J'abonde toujours dans le même sens. Le rythme est pris, mais je vais toujours en cours avec le même enthousiasme. Fred a une démarche telle que l'on sait toujours ou on en est dans la méthode, et surtout ou on va. La motivation reste intacte, le chemin est encore long ^^ (même si quand on regarde en arrière, on a franchis quelques caps).`,
      <br />,
      `Pour revenir sur le côté pédagogique, Fred sait a quel moment introduire tel ou tel concept, même si on ne peut pas encore le maîtriser, pour qu'inconsciemment on y fasse plus attention. Tout est beaucoup plus facile à appréhender ainsi, et surtout on voit les choses dans le bon ordre (c'est un élément qui me parait capital, à la fois pour la compréhension mais aussi pour la motivation).`,
      <br />,
      `Ce que j'aime particulièrement, c'est que le cours reste ouvert, même s'il y a des fiches d'exercices pensés pour travailler tous les aspects en parallèle. Si j'ai une interrogation sur un point musical particulier, Fred va me trouver des exercices, et le cours s'orientera dans cette direction. La méthode n'est pas figée, mais vraiment vivante.`,
      <br />,
      `En 2 ans, je pense avoir bien progressé. Je peux jouer des morceaux qui me paraissaient vraiment complexes il n'y a pas si longtemps que ça. Les cours sont toujours aussi agréables, Fred toujours aussi patient et sympa. Même si c'est un super musicien, il ne se mets jamais en avant. Ce n'est pas un prof comme on peut en trouver beaucoup qui donne des cours sur son temps libre parce qu'il joue bien. Il travaille pour ses élèves en mettant en place une méthodologie adaptée et intelligente. Bref je re-signe pour 2 ans 😉`,
      <br />,
      `Un petit complément sur le contenu des cours:`,
      <br />,
      ` Fred aborde tous les aspects , on bosse à la fois la théorie (gammes, harmonisation, modes, modulations..) et la technique (rythme, slap, tapping main droite et main gauche) par des exercices réguliers et surtout progressifs, en somme le seul vraiment moyen de s'améliorer (combien d'entre nous se sont dit qu'ils allaient bosser du double thumb en voyant une vidéo de Wooten ou Miller sur le net?).`,
      <br />,
      `Pas besoin de passer des heures à s'entrainer, les exercices sont calculés pour travail rapide, mais régulier. En sus, tous les styles sont abordés, avec leurs clichés et leurs gimmicks. Il peut y avoir plusieurs approche pour chaque exercice (l'apprentissage d'un morceau de Bach, par exemple, peut recéler quantité d'exercices).`,
      <br />,
      `L'arrivée du jazz dans la méthode ouvre de nouveaux horizons, et je dois dire que le travail des walkings est une nouvelle source de motivation.`,
      <br />,
      `Aujourd'hui, je suis non seulement capable de jouer des morceaux complexes (Pastorius, Infectious Groove, Miller...), dans des styles bien différents, mais aussi et surtout les comprendre! En somme je suis un meilleur musicien.`,
    ],
    rate: 5,
  },
  {
    img: "florent.jpg",
    name: "Florent",
    instru: "Guitare electrique, guitare folk",
    text: [
      `En un mot : "PEDAGOGIE"`,
      <br />,
      `Je suis des cours de 2 heures toutes les 2 semaines depuis bientôt un an avec la méthode de guitare électrique mise au point par Fred.`,
      <br />,
      `Non seulement les cours se passent de manière détendue et agréable, mais lorsque vous éprouvez des difficultés à assimiler une notion, Fred vous l'explique de différentes manières pour que vous la saisissiez correctement. De même, lors d'un apprentissage difficile, Fred se montre patient et vous décompose le passage de manière à ce que vous l'assimiliez en douceur. Après approximativement un an, mon oreille s'est assez développée, et ma culture musicale s'est beaucoup enrichie.`,
      <br />,
      `Pour conclure, la seule "contrainte" imposée est celle du travail personnel, qui au fil de la progression devient un plaisir.`,
    ],
    rate: 5,
  },
  {
    img: "aurelien.jpg",
    name: "Aurélien",
    instru: "Guitare électrique",
    text: [
      `Salut j'ai contacté Fred début septembre 2011 car je stagnais en auto-didacte, il a su cibler mes besoins et objectifs après un court entretien avant de me faire parcourir la méthode.`,
      <br />,
      `Pédagogue et surtout faisant preuve d'une patience à toute épreuve, j'ai acqueri réelle motivation et me suis mis des objectifs bien déterminés.`,
      <br />,
      `Suite à un changement de vie professionnelle j'ai démenagé en ile de france et depuis Fred me donne des cours via web-cam . Certes un petit peu déstabilisant comparé aux cours classiques, mais qui reste d'une qualité admirable!`,
      <br />,
      `Si vous êtes encore sceptique à cette pratique je vous conseilles vivement d'essayer je m'y suis habitué en 2 cours. Et si Fred se transforme en Hulk vert (problème de webcam en 2011 ndlr) pas d'inquiétude sa arrive de temps en temps.`,
      <br />,
      `Sur ce à vos manches et bon jeu !`,
    ],
    rate: 5,
  },
  {
    img: "georges.jpg",
    name: "Christophe",
    instru: "Guitare électrique",
    text: [
      `Voilà, j'ai commencé les cours avec Fred il y a 1 an environ et je ne suis pas prêt de m'arrêter.`,
      <br />,
      `Avant cela j'avais pratiquer la guitare en autodidacte il y a une 20 aine d'années (eh oui …) puis j'ai essayé plusieurs profs et méthodes avant de m'arrêter de jouer pendant quelques temps. Le résultat guitaristique était plutôt médiocre.`,
      <br />,
      `J'ai trouvé le site de Fred sur Google et j'avoue qu'au début, l'apprentissage de la lecture et de la théorie musicale m'a un peu rebuté (comme tout le monde je pense…). Mais c'était sans compter sur Fred qui arrive, d'une façon très progressive, à nous faire acquérir des notions de base très importantes pour améliorer son jeu (parfois même sans nous en rendre compte…) et on aborde alors beaucoup mieux les morceaux en comprenant ce qu'on joue.`,
      <br />,
      `Ajouter à cela une patience à toute épreuve (mais faut faut un peu bosser quand même !) avec un prof super sympa et vous avez le cocktail du prof idéal.`,
      <br />,
      `Tout est là pour progresser.`,
      <br />,
      `Je le conseille à tous, ….si vous avez envie de bien jouer bien sûr !!!`,
    ],
    rate: 5,
  },
  {
    img: "antoine.jpg",
    name: "Antoine",
    instru: "Guitare électrique",
    text: [
      `Fred m'as montré que j'avais des "vides" dans mon apprentissage de la musique, que la technique n'est pas uniquement basé sur la vitesse, qu'il y a tout un travail sur le son, la propreté. J'ai donc décider de revenir à zéro et de commencer la méthode que Fred avait créé. Après 2 mois passé sur sa méthode je commence à savoir lire et à comprendre ce que je joue. Des choses que je pensais acquises sont remises en question, en effet il me fait voir les choses d'un autre angle. La méthode est très progressive: elle commence de la base et fais travailler plusieurs choses en même temps, un aspect que j'aime est que l'on apprends à lire des partitions dès la première page, chose qui est essentielle si l'on veut atteindre un excellent niveau.`,
    ],
    rate: 5,
  },
  {
    img: "francois.jpg",
    name: "JC",
    instru: "Basse électrique",
    text: [
      `Cela fait maintenant un an et quatre mois que je travaille avec Fréderic en utilisant sa méthode et rien d'autre. Il faut bien avouer que les débuts font peur (avec le solfège on est tout de suite dans le grand bain) mais cela passe étonnamment très bien.`,
      <br />,
      `À raison d'une heure de cours par semaine et bien entendu un peu de pratique quotidienne (pas énorme à vrai dire, cela dépend du boulot à côté), je suis assez surpris de ce que j'arrive à jouer maintenant, c'est vrai que de temps à autre on a parfois l'impression de stagner mais c'est une sensation à mon avis plutôt naturelle qui se dissipe assez vite.`,
      <br />,
      `La méthode en elle-même me convient bien, pas toujours facile (surtout le ternaire) mais on oublie rapidement lorsque l'on s'aperçoit que l'on peut jouer certains de nos morceaux préférés sans les massacrer grâce aux exercices de lecture et… je n'en dis pas plus, beaucoup de choses sont abordées avec passion et pédagogie. On se rend compte des progrès lorsque l'on joue un morceau que deux mois avant on n'arrivait pas à passer, et là, c'est le pied.`,
      <br />,
      `Le plaisir de jouer est en fait présent dès le début, on sait où l'on doit aller, on essaie de prendre le chemin le plus rapide pour y arriver mais en évitant les erreurs qui peuvent plomber un apprentissage, Fred veille au grain :`,
      <br />,
      `Exemple : « punaise, c'est quoi ce doigté ? Tu va jamais y arriver comme ça ! De toutes façons, si t'y arrives pas c'est que c'est sûrement pas la bonne façon de faire, regarde… », ok, fastoche Fred, pédagogie oblige.`,
      <br />,
      `oilà, c'est à peu près tout ce que je n'ai pas oublié de dire, ah ouais, Fred est sympa et marche au jus de fruit. Dans les moments difficiles (je suis un anxieux) il sait remotiver les troupes, et je ne peux pas le cacher ; je viens de faire l'acquisition d'une chouette basse (je ne sais pas si je peux citer la marque qui commence par un F, vient des Etats-Unis, a 5 cordes et un préamp débrayable…), c'est dire l'envie de continuer. Je vous dirai ce qu'il en est dans un an, progrès et sensations inclus.`,
      <br />,
      `Si certains auraient encore un doute (j'm'y mets ou j'm'y mets pas), foncez !`,
      <br />,
      `JC`,
    ],
    rate: 5,
  },
  {
    img: "olivier.jpg",
    name: "Olivier",
    instru: "Guitare électrique",
    text: [
      `Etant débutant et n'ayant jamais pratiqué de solfège, je trouve que la méthode est vraiment super car elle associe pratique de l'instrument via des morceaux connus que l'on peut vite jouer, et découverte du solfège en douceur, de manière appliquée, ce qui fait qu'on ne lâche jamais l'instrument pour se plonger dans un bouquin. On se fait donc plaisir très rapidement tout en continuant de progresser, Fred distillant de nouvelles notions à chaque cours, notions que l'on acquiert en douceur sans s'en rendre compte.
`,
    ],
    rate: 5,
  },
  {
    img: "bertrand.jpg",
    name: "Bertrand",
    instru: "Basse électrique",
    text: [
      `Cursus: Autodidacte de la basse à trois doigts (main gauche) et au médiator entre 1997 et 2008 puis cours à domicile depuis février 2008 avec fred.`,
      <br />,
      `Fred m'a permis de remettre en question les techniques que j'utilisais jusqu'à lors à la basse et qui me parraissais satisfaisantes (j'ai enregistré un premier LP avec le groupe Blow up au studio feeling à Tourcoing et un 45T Presto!).`,
      <br />,
      `Fred m'a donc permis de tout remettre à plat,d'acquérir de bonnes bases en solfège et enfin une technique propre à la basse et au bassiste. Depuis je "m'amuse" vraiment beaucoup plus avec mon instrument et bien que les débuts furent difficiles(10 ans de basse et se rendre compte qu'on ne sait pas grand chose c'est pas facile...), je me suis vraiment accroché soutenu par Fred. Je le remercie pour ses précieux conseils, sa pédagogies sa patience (cf les noirs pointées et autres doubles croches ) aujourd'hui, je ne compte pas m'arrêter, je veux encore en savoir plus. Bref! En 2009 je compte sur toi!`,
    ],
    rate: 5,
  },
  {
    img: "julien.jpeg",
    name: "Julien",
    instru: "Basse électrique",
    text: [
      `Je travaille avec Fred en cours de solfège, cours de basse, et atelier improvisation jazz. Fred est très pédagogue, ouvert d'esprit et à l'écoute.`,
      <br />,
      `Toutes les questions que je pose en cours ont le droit à des réponses précises et pertinentes qui me permettent d'avancer dans mon apprentissage de la basse. Je peux également proposer des techniques et des morceaux que j'aimerais apprendre même si ils n'ont pas vraiment rapport avec le cours.`,
      <br />,
      `Les cours de basse sont très complets et adaptés au niveau de chacun. Ils sont apuyés par des morceaux choisis par le professeur et les élèves et qui sont joués en groupe avec d'autres musiciens du même niveau. L'atelier improvisation jazz me donne l'occasion de jouer sur des grands classiques du jazz. Le cours est bien construit, avec une partie théorique reprenant la construction des différentes games et modes et leur	utilisation sur des lignes de basse jazz et une partie pratique où chacun à la possibilité de s'exprimer par la musique, quel que soit son niveau pour l'improvisation.`,
      <br />,
      `Je me suis senti beaucoup progresser depuis que je suis en cours après avoir galéré pendant de nombreuses années en tant qu'autodidacte.`,
      <br />,
      `Merci Fred!`,
    ],
    rate: 5,
  },
  {
    img: "romain.jpeg",
    name: "Romain",
    instru: "Guitare électrique, guitare manouche",
    text: [
      `- La Polyvalence, autant dans le style de musique qu'au niveau des instruments. Fred est capable sans aucun problème d'improviser en Jazz sur une guitare 8 cordes, d'assurer une rythmique funky à la batterie, d'accompagner un morceau Blues/rock à la basse ou au piano...Je trouve cela très important de la part d'un professeur de maitriser une large palette d'instruments et de styles musicaux : cela nous permet de découvrir et de développer de nouvelles influences rythmiques et mélodiques. Cela lui permet aussi d'avoir suffisamment de recul pour comprendre et ainsi nous expliquer les difficultés que peut rencontrer chaque musicien (par exemple en Atelier Jazz, le bassiste, le guitariste, le pianiste et le batteur appréhendent d'une manière différente un même morceau...)`,
      <br />,
      `- Les connaissances théoriques : En plus de la pratique de la guitare Jazz, je comptais beaucoup sur Fred pour éclaircir de nombreuses zones d'ombres concernant mon approche théorique de la musique : Gammes, arpèges, cadences, substitutions et altérations, bref des notions très rébarbatives si l'on essaye de les apprendre dans des bouquins ou sur le web...Et qui pourtant prennent parfois tout leur sens avec une simple explication orale et une mise en pratique !`,
      <br />,
      `- Enfin, d'un point de vue personnel, j'apprécie beaucoup Fred pour sa patience, son ouverture d'esprit, sa motivation, sa simplicité et son humour qui, en plusieurs années de fidélité, m'ont fait penser chaque samedi : "vivement la semaine prochaine" !`,
    ],
    rate: 5,
  },
  {
    img: "hugo.jpeg",
    name: "Hugo",
    instru: "Guitare électrique",
    text: [
      `Fred est un puits de sciences (enfin plutôt de musique 😉). Il a réponse à chacune de mes questions. Il adopte une optique telle qui ne cherche pas à influencer son élève mais à lui forger une personnalité "musicale". Il m'apprend les rudiments du jazz avec une pédagogie très adapté. Ce qui m'a permis entre de comprendre plus rapidement (notamment les notions de modes et autres).`,
      <br />,
      `Mais il ne délaisse pas mes goûts personnels car il m'a fait travailler quelques classiques du metal et des styles que je voulais découvrir un peu plus (Funk, Reggae).`,
      <br />,
      `Fred introduit également la composition dans ses cours, nous avons, par exemple, harmonisé une grille d'accord avec des lignes de basses, batteries, violons sur lesquelles j'improvisais. J'ai pu également aborder plusieurs techniques de guitares et d'autres sont prévues ! Par ailleurs, j'applique tout ce que j'apprend en cours à l'atelier improvisation de jazz, me permettant ainsi de me perfectionner et de m'exprimer.`,
      <br />,
      `Tout cela avec un prof à l'écoute, plein d'humour et passionnant !`,
      <br />,
      `(environ 10 ans plus tard après un long arrêt à cause des études) :`,
      <br />,
      `J'ai repris la guitare avec Fred (avec qui j'avais commencé durant mon adolescence) ce qui m'a permis de reprendre tout à zéro avec sa méthode, me permettant ainsi de revoir les bases et de combler mes lacunes.`,
      <br />,
      `Aujourd'hui je peux dire que sa méthode et son accompagnement m'ont permis de progresser de manière linéaire, le tout en abordant une multitude de styles.`,
      <br />,
      `Le solfège incorporé dans sa méthode n'est pas du tout un frein au plaisir de jouer, bien au contraire, cela nous donne envie d'aller toujours plus loin pour comprendre et apprécier ce que l'on joue.`,
      <br />,
      `Toujours à l'écoute et ouvert d'esprit, son enseignement permet d'apprécier aussi les styles sur lesquels on ne se serait pas penché ainsi que les petits détails qui rendent un morceau, en apparence simple, intéressant.`,
      <br />,
      `Que l'on soit débutant ou non, je pense que personne ne regrette les cours avec Fred.`,
    ],
    rate: 5,
  },
  {
    img: "christelle.jpg",
    name: "Christelle",
    instru: "Guitare électrique, accordéon",
    text: [
      `La méthode est très bien pensée, on avance vers les difficultés mais de manière très progressive de sorte qu'on est pas écoeuré et on s'étonne des progrès qu'on fait. Dès le départ, les cours travaillent les différentes compétences (lecture, techniques...).`,
      <br />,
      `Les cours sont agréables car on a un prof qui a réellement envie de transmettre et de voir progresser son élève. De plus, très rapidement sont introduits des morceaux, ce qui permet de se faire plaisir et Frédéric s'adapte au goût de l'élève et lui trouve des morceaux qu'il aime. Très contente d'avoir trouvé Frédéric comme prof!`,
    ],
    rate: 5,
  },
  {
    img: "bobo.jpeg",
    name: "Boris",
    instru: "Guitare électrique",
    text: [
      `Les cours de Fred sont très ouvert, on y découvre tout style de musique, et cela est très vite prenant, surtout quand on a tendance a s'engouffrer dans un seul style musical. L'apprentissage des accords et des gammes se fait de telle sorte qu'il peut eveiller la créativité de chacun. Grâce à Fred, j'ai pus découvrir d'autres style musicaux, et découvrir les rouages de base du Jazz et de l'improvisation. Et tout cela, dans la joie et la bonne humeur, et avec beaucoup d'humour.`,
    ],
    rate: 5,
  },
  {
    img: "sylvain.jpg",
    name: "Sylvain",
    instru: "Guitare folk",
    text: [
      `J'ai trouvé par hasard le site de Fred (très bien fourni d'ailleurs). Je cherchais un Prof de guitare, quelqu'un puisse m'aider à composer des morceaux. J'ai arrêté 20 ans la guitare, un ami m'a demandé de reprendre pour créer des morceaux chantés. J'ai trouver chez Fred un enseignement adapté à ma demande. Il est capable de s'adapter à tous ce qui tourne autour de la musique et cela me permet d'avancer beaucoup plus vite. C'est un vrai professionnel de la musique. Ses connaissances théoriques, pratiques et ses qualités pédagogiques sont indéniables.`,
    ],
    rate: 5,
  },
  {
    img: "sg.jpg",
    name: "Jean-Loup",
    instru: "Guitare électrique",
    text: [`Difficile de trouver mieux comme cours de guitare !`],
    rate: 5,
  },
  {
    img: "shakira.jpg",
    name: "La maman d'Éloïse",
    instru: "Guitare électrique",
    text: [
      `Altruiste, bourré d'humour, délicat, patient, avec un immense talent ! les élèves ont de la chance d'avoir un prof comme toi... à bientôt!`,
    ],
    rate: 5,
  },
  {
    img: "guillaume.jpeg",
    name: "Pierre",
    instru: "Guitare électrique",
    text: [
      `Un prof au top, une méthode bien pensée pour apprendre à jouer de notre instrument préféré. Que du bonheur !`,
    ],
    rate: 5,
  },
  {
    img: "sebastien.png",
    name: "Sébastien",
    instru: "Guitare électrique",
    text: [
      `Je te remercie pour tout ce que tu m'as appris, tu es un musicien exceptionnel et ce que tu as créé et le travail que tu as produit est vraiment incroyable.`,
    ],
    rate: 5,
  },
  {
    img: "guillaume.jpg",
    name: "Guillaume",
    instru: "Guitare électrique",
    text: [
      `J'ai vraiment apprécié ta patience, ta pédagogie et ta façon d'être ces 4 années que j'ai passé avec toi, tu es un bon prof, et tu le sais 😄.`,
    ],
    rate: 5,
  },
];
