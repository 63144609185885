const pagesbasse = 2008;
const pagesbassefrancois = 64;
const pagesguitare = 2161; 
const pagesguitarehugo = 10; 
const pagesguitareflorent = 8;
const morceauxbasse = 450;
const morceauxguitare = 400;
const nombredeleves = 26;
const today = new Date();

const Constants = {
  pagesbasse: pagesbasse,
  pagesbassefrancois: pagesbassefrancois,
  pagesbassefred: pagesbasse - pagesbassefrancois,
  pagesguitare: pagesguitare,
  pagesguitarehugo: pagesguitarehugo,
  pagesguitareflorent: pagesguitareflorent,
  pagesguitarefred: pagesguitare - (pagesguitarehugo + pagesguitareflorent),
  percbassefred: ((pagesbasse-pagesbassefrancois)/pagesbasse*100).toFixed(2),
  percbassefrancois: ((pagesbassefrancois)/pagesbasse*100).toFixed(2),
  percguitarefred: ((pagesguitare-(pagesguitarehugo+pagesguitareflorent))/pagesguitare*100).toFixed(2),
  percguitarehugo: ((pagesguitarehugo)/pagesguitare*100).toFixed(2),
  percguitareflorent: ((pagesguitareflorent)/pagesguitare*100).toFixed(2),
  morceauxbasse: morceauxbasse,
  morceauxguitare: morceauxguitare,
  anneesdexperience: today.getFullYear() - 1995,
  nombredeleves: nombredeleves,  
};

export default Constants;


