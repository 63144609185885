import style from "./news.module.scss";
import mainStyle from "../../homepage.module.scss";
import { useContext } from "react";
import { ArticlesContext } from "../../../../services/context/articlesContext";
import { NavLink } from "react-router-dom";
import Card from "../../../blog/components/card/card";
import ShortDivider from "../../../../components/divers/shortDiver";
import Scroller from "../../../../components/scroller/scroller";

export default function News() {
  const { blogHome } = useContext(ArticlesContext);
  const resolution = window.innerWidth;

  return (
    <section
      id={"news"}
      className={`${style.section} ${mainStyle.section} d-flex f-column aic jcc`}
    >
      <h2
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-delay="100"
      >
        ARTICLES & NEWS
      </h2>
      <ShortDivider />
      <p
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-delay="100"
      >
        <NavLink
          to={"/blog"}
          title="BLOG"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="300"
        >
          Voir tous les articles
        </NavLink>
      </p>
      <div
        className={`${style.content} d-flex jcse f-wrap`}
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay="100"
      >
        <div className={`${style.column} d-flex f-column aic`}>
          {resolution > 768 ? (
            <h2>Les plus récents</h2>
          ) : (
            <h2>Le plus récent</h2>
          )}
          <div className="d-flex f-column aic jcc">
            {resolution > 768 ? (
              blogHome[0].map((a, i) => <Card key={i} data={a} />)
            ) : (
              <Card data={blogHome[0][0]} />
            )}
          </div>
        </div>
        <div
          className={`${style.column} ${style.mainColumn} d-flex f-column aic`}
        >
          {resolution > 768 ? <h2>Les plus lus</h2> : <h2>Le plus lu</h2>}

          <div className="d-flex f-column aic jcc">
            {resolution > 768 ? (
              blogHome[1].map((a, i) => <Card key={i} data={a} />)
            ) : (
              <Card data={blogHome[1][0]} />
            )}
          </div>
        </div>
        <div className={`${style.column} d-flex f-column aic`}>
          <h2>À lire également</h2>
          <div className="d-flex f-column aic jcc">
            {resolution > 768 ? (
              blogHome[2].map((a, i) => <Card key={i} data={a} />)
            ) : (
              <Card data={blogHome[2][0]} />
            )}
          </div>
        </div>
      </div>
      <div className={`${style.scroller}`}>
        <Scroller section={"header"} text={"Retour en haut de la page"} />
      </div>
    </section>
  );
}
