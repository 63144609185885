import { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import { ProductService } from "./PrductService";
import Card from "./cards/card";
import style from "../rate.module.scss";

export default function CarouselRate({ setReadMore, setContainer }) {
  // https://primereact.org/carousel/
  const [products, setProducts] = useState([]);
  const responsiveOptions = [
    {
      breakpoint: "1200px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  useEffect(() => {
    ProductService.getProducts().then((data) => setProducts(data.slice(0, 9)));
  }, []);

  const productTemplate = (product) => {
    return (
      <>
        <div
          className={`${style.crsl} border-1 surface-border border-round m-2 text-center py-5 px-3`}
        >
          <Card
            data={product}
            css={true}
            setReadMore={setReadMore}
            setContainer={setContainer}
          />
        </div>
      </>
    );
  };

  return (
    <div className={`${style.card} card`}>
      <Carousel
        value={products}
        numScroll={1}
        numVisible={3}
        responsiveOptions={responsiveOptions}
        itemTemplate={productTemplate}
      />
    </div>
  );
}
