import style from "./card.module.scss";

export default function BigCard({ data, readMore, createRate, css }) {
  return (
    <article
      className={`${style.backdrop} ${css && style.crsled} d-flex aic jcc`}
    >
      <div
        className={`${style.card} ${
          readMore && style.bigCard
        } d-flex f-column jcc`}
      >
        <div className={`${style.head} d-flex aic`}>
          <div className={style.img}>
            <img src={process.env.PUBLIC_URL + "/img/" + data.img} alt="" />
          </div>
          <h2>
            {data.name} - {data.instru}
          </h2>
        </div>
        <div className={`${style.stars} d-flex`}>{createRate(data.rate)}</div>
        <div className={`${style.rate}`}>
          <p className={!readMore && style.shortText}>
            {data.text}
            {!readMore && <div className={style.gradient}></div>}
          </p>
        </div>
        <div className={`${style.readMore} d-flex jce`}>
          <p
            onClick={() => {
              readMore(false);
            }}
          >
            Réduire
          </p>
        </div>
      </div>
    </article>
  );
}
