import { useState } from "react";
import parse from "html-react-parser";
import style from "../cours.module.scss";
import { counterView } from "../../../apis/articles";

export default function Question({ data }) {
  const [toggle, setToggle] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleClick = () => {
    setToggle(!toggle);
    if (counter === 0) {
      setCounter(1);
      data.view++;
      counterView(data.url, data.view);
    }
  };

  return (
    <div className={`${style.question}`}>
      <p onClick={() => handleClick()} className={`${style.q} d-flex aic jcsb`}>
        <strong>{data.title}</strong>
        <span className={`${toggle ? style.rotate : style.down}`}>
          <i className="fa-solid fa-chevron-down"></i>
        </span>
      </p>
      <div className={`${style.r} ${toggle ? style.toggle : style.disable}`}>
        {toggle && parse(data.intro)}
      </div>
    </div>
  );
}
