import { createHashRouter } from "react-router-dom";
import App from "../App";
import NotFound from "../pages/notFound/notFound";
import Contact from "../pages/contact/contact";
import Guitar from "../pages/cours/guitar/guitar";
import Bass from "../pages/cours/bass/bass";
import Rate from "../pages/rate/rate";
import Blog from "../pages/blog/blog";
import Article from "../pages/blog/components/article/article";
import Homepage from "../pages/homepage/homepage";
import Bio from "../pages/bio/bio";
import Cours from "../pages/cours/cours";
import EarTrainer from "../pages/interactif/eartrainer/eartrainer";
import Proba from "../pages/interactif/probabilité/probabilité";
import Impro from "../pages/interactif/impro/improvisation";
import Interactif from "../pages/interactif/interactif";
import ArticleLoader from "../services/loader/articlesLoader";

export const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    loader: ArticleLoader,
    children: [
      {
        index: true,
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/cours",
        element: <Cours />,
      },
      {
        path: "/cours/guitare",
        element: <Guitar />,
      },
      {
        path: "/cours/basse",
        element: <Bass />,
      },
      {
        path: "/avis",
        element: <Rate />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:article",
        element: <Article />,
      },
      {
        path: "/biographie",
        element: <Bio />,
      },
      {
        path: "/interactif",
        element: <Interactif />,
      },
      {
        path: "/interactif/improvisation",
        element: <Impro />,
      },
      {
        path: "/interactif/eartrainer",
        element: <EarTrainer />,
      },
      {
        path: "/interactif/proba",
        element: <Proba />,
      },
    ],
  },
]);
