/* Morceaux transposés en C majeur # only */
const majorSong = {
	"Lucky" : ["C", "Am", "Dm", "G", "Em", "Am", "Dm", "G", "Am", "Dm", "G", "C", "Am", "Dm", "G", "C", "Am", "Em", "G", "Dm", "Am", "G", "Dm", "Am", "G", "C"],
	"Knockin on heavens door" : ["C", "G", "Dm", "C", "G", "F", "C"],
	"Please do not go" : ["C", "F", "G", "C", "F", "C", "F", "G", "C"],
	"Hey joe" : ["F", "C", "G", "D", "A", "F"],
	"These boots" : ["C", "F", "C", "D#", "C"],
	"Mollys lips" : ["C", "F", "C"],
	"island" : ["Am", "Dm", "G", "C", "G", "C", "F", "Dm", "G", "C"],
	"wild thing" : ["C", "F", "A#", "G", "C", "F", "G", "F", "C", "A#", "C"],
	"stand by me" : ["C", "Am", "F", "G", "C"],
	"like a rolling stone" : ["C", "G", "Em", "F", "G", "F", "G", "F", "Em", "Dm", "C", "F", "Em", "Dm", "C", "Dm", "F", "G", "C", "F", "G", "C"],
	"satisfaction" : ["C", "A#", "F", "C", "F", "C", "G", "C"],
	"love is all intro" : ["A", "D", "G", "C"],
	"love is all" : ["C", "G", "C", "F", "G", "C"],
	"love is all refrain" : ["Dm", "G", "C", "F", "Dm", "G", "C", "F"],
	"love is all pont" : ["Dm", "G", "C", "F", "Dm", "G", "C", "A#"],
	"creep" : ["C", "E", "F", "Fm", "C"],
	"where did you sleep last night" : ["C", "F", "D#", "G", "C"],
	"nothing compares 2U" : ["C", "G", "Am", "C", "G", "C", "G", "Am", "E", "A#", "F", "A#", "F", "G", "C", "G", "Am", "F", "A#", "F", "Am", "G"],
	"redemption song" : ["C", "Am", "F", "C", "Dm", "C", "Am", "F", "G", "C", "F", "G", "C", "F", "G", "Am", "F", "G", "C", "F", "C/E", "Dm", "Dm6"],
	"talkin bout a revolution" : ["C", "Fadd9", "Am", "G", "Gsus4", "G", "Gsus2", "C"],
	"thinking out loud" : ["C", "C/E", "F", "G", "C", "C/E", "F", "G", "Dm", "G", "C", "Dm", "G", "Dm", "G", "Am", "Dm", "G", "Am7", "C/G", "Csus4/F", "C/E", "C"],
	"let it be" : ["C", "G", "Am", "F", "C", "G", "F", "C", "Am", "C", "F", "C", "G", "F", "C", "F", "C", "G/B", "Dm/A", "C/G"],
	"you really got me" : ["F", "G", "F", "G", "A", "G", "A", "C", "D", "C", "D", "C", "F"],
	"bonny" : ["C", "F", "C", "F", "C", "F", "G", "C", "F", "G", "C"],
	"le lion est mort ce soir" : ["C", "F", "C", "G", "C"],
	"oricono flow" : ["G", "F", "C", "G", "F", "C", "G", "Am", "G", "F", "D", "G"],
	"lemon tree" : ["C", "G", "Am", "Em", "F", "G", "C", "G", "C", "G", "Am", "Em", "F", "D", "G", "C", "G", "Am", "Em", "F", "G", "F", "G", "F", "G", "C"],
	"cristalised" : ["G7", "Dm", "C", "G7"],
	"anarchy in the UK" : ["G", "F", "Em", "Dm", "C", "Em", "F", "C", "G", "F", "Em", "Dm", "C", "Em", "F", "C", "G", "C", "D7", "Em9", "D7", "Em9", "D7", "G", "C", "D", "Dsus2", "D", "Dsus4", "D", "Dsus2", "D", "C"],
	"love me tender" : ["C", "D", "G", "C", "G/B", "Am", "C/G", "F", "Dm7b5", "C", "Am", "D", "G", "C"],
	"pretty woman" : ["G7", "C", "Am", "C", "Am", "F", "G", "G7", "C", "Am", "F", "Fm", "G", "C", "Am", "F", "Fm", "G7", "Dm", "G", "C", "Am", "Dm", "G", "C"],
	"jai demandé à la lune" : ["C", "G", "Am", "C", "Dm", "Am", "Em", "G", "C"],
	"les copains dabord" : ["C", "D", "F", "E", "E7", "Am", "D7", "G", "C"],
	"no milk today" : ["C", "A#", "F", "G", "C", "G", "F", "G", "C", "G", "F", "G", "C"],
	"yellow submarine" : ["G7", "F", "C", "Am", "Dm", "F", "G7", "C", "G7", "F", "C", "Am", "Dm", "F", "G7", "C", "G7", "C", "G7", "C"],
	"cest extra" : ["C", "Am", "F", "Dm", "G", "G7", "C"],
	"should i stay or should i go" : ["C", "F", "C", "F", "C", "F", "D#", "F", "C", "F", "C", "G", "G7", "C"],
	"all you need is love" : ["C", "CΔ9/B", "Am", "C", "CΔ9/B", "Am", "C", "F", "C", "F", "G7", "C", "F", "G7", "C", "E7", "Am", "F", "G7", "C"],
	"laventurier": ["Dm", "Am", "G", "C", "Dm"],
	"highway to hell" : ["C", "F/A", "A#", "F/A", "A#", "F/A", "A#", "F/A", "C", "F/A", "A#", "F/A", "A#", "F/A", "A#", "F/A", "G", "C", "F", "A#", "F/A", "C", "F", "A#", "F", "A#", "F", "C"],
	"un autre monde" : ["Csus2", "Em7add11", "Csus2", "Asus4", "Em7add11", "Csus2", "Asus4", "Em7add11", "C", "Am", "Em", "C", "Am", "G", "F", "G", "F", "G", "F", "G", "C"],
	"everybody hurts" : ["C", "F", "C", "F", "C", "F", "FΔ/E", "Dm", "G", "Dm", "G", "C", "F", "C", "F", "C", "F", "FΔ/E", "Dm", "G", "Dm", "G", "E", "Am", "E", "Am", "A#", "F6", "A#", "Gm", "C"],
	"boys dont cry" : ["C", "Dm", "Em", "F", "Em", "Dm", "C", "Em", "Dm", "Em", "Dm", "C", "Dm", "Em", "F", "Em", "Dm", "G", "Am", "Am7", "G", "Am", "Am7", "F", "G", "C"],
	"ça cest vraiment toi" : ["G", "F", "C", "G", "G7", "G", "F", "C", "G", "Am", "C", "D", "G", "Am", "C", "D", "G", "C", "G", "C"],
	"buffalo soldier" : ["C7", "C", "F", "C", "F", "C", "F", "C", "C/E", "G/D", "Am", "F", "C", "F", "C", "C/E", "G/D", "Am7", "C", "F", "C", "Am", "F", "Em7", "Am", "F", "Em7", "G", "C"],
	"somewhere over the rainbow" : ["C", "Em", "Am", "F", "C", "Em", "Am", "Fadd9", "F", "C", "Em", "F", "C", "F", "E7", "Am", "F", "C", "Em", "F", "C", "F", "C", "Em", "Am", "Dm", "C", "Em", "Am", "Dm", "C", "Em", "F", "C", "F", "C", "Em", "Am", "Dm", "C", "Em", "F", "C", "F", "C", "E7", "Am", "F", "G", "Am", "F", "C", "Em", "C", "Em", "C", "F", "C", "F", "C", "F", "C", "Gsus4", "G", "C", "Em", "F", "C", "F", "C", "E7", "Am", "F", "G", "Am", "C", "Em", "Am", "Dm", "C", "Em", "F", "C", "F", "C", "Em", "Am", "Dm", "C"],
	"walk on the wild side": ["C", "F6", "C", "D", "F", "D", "C", "F6", "C"],
	"imagine": ["C", "CΔ", "F", "Fadd#11", "C", "CΔ", "F", "Am", "Dm", "F", "C", "CΔ", "F", "Fadd#11", "C", "CΔ", "F", "Am", "Dm", "F", "G", "G7", "F", "G", "C", "CΔ", "E", "E7", "F", "G", "C"],
	"moonlight shadow" : ["C", "G", "Am", "F", "G", "Am", "F", "G", "C", "G", "Am", "F", "G", "C", "G", "C", "G", "Am", "F", "G", "C", "G", "Am", "F", "G", "C", "G", "C", "G", "C", "F", "G", "C", "G", "C", "G", "C", "G", "C", "F", "G", "C", "G", "Am"],
	"blackbird" : ["C", "Dm7", "C/E", "C", "Dm7", "C/E", "C", "F", "D7/F#", "G", "E7/G#", "Am", "G#", "G", "D7/F#", "F", "Fm", "C/E", "D7", "G7sus4", "C", "F", "C/E", "D7", "G7sus4", "C", "A#", "Am", "Gm", "F", "D#", "F", "A#", "Am", "Gm", "F", "D#", "D7", "G7sus4", "C", "Dm7", "C/E", "C", "Dm7", "C/E", "F", "C/E", "D7", "G7sus4", "C"],
	"NY avec toi" : ["C", "Am", "C", "Am", "C", "Am", "C", "Dm", "C", "Am", "C", "Dm", "F", "C", "Dm", "F", "C", "Am", "C", "G", "C"],
	"communication breakdown" : ["C", "A#", "F", "A#", "C", "A#", "F", "A#", "F", "G", "C"],
	"yesterday" : ["C", "Bm", "E7", "Am", "F", "G", "C", "CΔ/B", "Am7", "D", "F", "C", "Bm7", "E7", "Am", "G", "F", "Dm", "G7", "C" , "Am7", "D", "F", "C", "Am7", "D7", "F", "C"],
	"blowin in the wind" : ["C", "F", "C/E", "G/D", "C", "F", "C/E", "C", "C/E", "F", "C/E", "F", "C/E", "G/D", "C"],	
	"chupee" : ["C", "Am", "Em", "G7", "C", "Am", "Em", "G7", "F", "Dm", "C", "Em", "F", "Dm", "C", "G6", "C"],
	"lithium" : ["C", "Em", "Am", "F", "G#", "A#", "Gm", "A#", "C"],
	"all together now" : ["C", "G7", "C", "G7", "C", "G7", "C", "F", "C", "F", "G7", "C"],
	"my sharona" : ["C", "G", "F", "G", "F", "C"],
	"la bamba" : ["C", "F", "G", "F", "C", "F", "G", "C"],
	"oh darling" : ["G+", "C", "G", "Am", "F", "Dm", "G", "C", "F", "C", "F", "G#", "Dsus2", "G", "G#", "G", "G+", "C"],
	"sitting on the dock of the bay" : ["C", "E", "F", "D", "C", "E", "F", "D", "C", "A", "C", "A", "C", "D", "C", "A", "C", "G", "F", "C", "G", "F", "C", "G", "F", "C", "A#", "G", "C", "E", "F", "D", "C", "E", "F", "D", "C", "A", "C", "A", "C", "D", "C", "A", "C", "A", "C", "A", "C"],
	"god saves the queen" : ["C", "F", "C", "F", "C", "G", "D", "G", "C", "G", "C", "Am", "D", "Dsus4", "D", "Dsus4", "D", "Am", "G", "C", "G", "F", "Em", "Dm", "F", "Em", "Dm", "C"],
	"back in black" : ["C", "A#", "F", "C", "A#", "F", "C", "G", "C", "G", "F", "C", "G", "C", "G", "A#sus4", "A#", "F", "A#sus4", "A#", "F", "F", "C", "G", "C", "G", "F", "C", "G", "C", "G", "A#sus4", "A#", "C"],
	"revolution" : ["C", "F", "C", "F", "G", "Dm", "G", "Dm", "A#", "C", "A", "G", "C", "FΔ", "C", "FΔ", "C", "FΔ", "G", "C"],
	"it aint over" : ["C", "CΔ", "C7", "F", "Fm", "C"],
	"only in dreams" : ["C/E", "Fadd9", "Dsus4", "G7", "G6", "G7", "G6", "C/E", "Fadd9", "Dsus4", "G7", "G6", "G7", "G6", "Em", "Dm", "C", "F", "Em", "Dm", "Em", "Dm", "C", "F", "Em", "Dm", "A#", "D#", "C", "F", "Em", "Dm", "C", "F", "G", "C"],
	"all right now" : ["C", "F", "C", "F", "C", "A#", "F", "C", "A#", "F", "C", "G", "C"],
	"I love rock & roll" : ["C", "F", "G", "C", "G", "F", "G", "C", "F", "G", "C"],
	"comfortably numb" : ["C", "G", "C", "G", "F/A", "A#", "F", "A#", "F", "C", "G", "C", "G", "F/A", "A#", "F", "A#", "F", "Gsus4", "Gsus2", "G", "F/A", "A#", "F", "C", "Asus2", "Gsus2", "F", "FΔ/E", "Dm7", "Asus2", "Gsus2", "F", "FΔ/E", "Dm7", "Asus2", "C"],
	"behind the sun" : ["F", "C", "G", "G9", "F", "C", "G", "G9", "CΔ", "G9", "CΔ", "G9", "F"],
	"sweet home alabama" : ["G", "F", "C", "G", "F", "C", "G", "F", "C", "A#", "F", "G", "F", "C", "F", "G", "F", "C", "F", "C"],
	"message in a bottle" : ["C", "F", "G", "C", "F", "G", "Am", "F", "Am", "F", "Am", "F", "Em7", "C", "Em7", "C", "Em7", "C", "Am"],
	"jeux interdits": ["C", "Dm7", "Dm6", "A#/D", "Dm6", "Gadd9", "C6", "C", "CΔ", "C7", "F", "C", "G6", "G7", "C"],
	"under the bridge": ["C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "CΔ", "C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "CΔ", "Dm", "C", "G", "Dm", "Dm7", "Dm", "C", "G", "Dm", "Dm7", "C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "CΔ", "C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "C", "G", "Am", "Em", "F", "C", "G", "Am", "F", "CΔ", "Dm", "C", "G", "Dm", "Dm7", "Dm", "C", "G", "Dm", "Dm7"],
	"I want you back": ["C", "F", "Am", "F", "Dm", "G", "C", "F", "G", "Am", "F", "Dm", "G", "C"],
	"tears in heaven" : ["C", "G/B", "Am", "Am7/G", "F/A", "G7sus4", "G7", "C", "G/B", "Am", "Am7/G", "F/A", "C/G", "G", "C/G", "G7", "Am", "E/G#", "Gm6", "A7", "Dm7", "F/G", "C"],
	"no woman no cry" : ["C", "CΔ/B", "Am", "F", "C", "F", "C", "G", "C"],
	"free bird" : ["C", "G/B", "Am", "A#", "F", "G", "C", "G/B", "Am", "A#", "F", "G", "C", "D#", "F", "C"],
	"dont speak pont": ["C", "CΔ", "A#", "A#Δ", "D7sus4", "G"],
	"the joker": ["C", "F", "G", "F", "C", "F", "C"],
	"jai 10 ans" : ["C", "Csus4", "C", "D#add#11", "A#", "C", "F7", "C", "D#", "A#", "C#", "G#", "C", "A", "Dm", "G", "C", "A", "Dm", "G", "C", "D", "G", "C"],
	"wild world" : ["Am", "D7", "G", "CΔ", "F", "Dm", "E", "E7sus4", "E", "Am", "D7", "G", "CΔ", "F", "Dm", "E", "E7sus4", "E", "Am", "D7", "G", "CΔ", "F", "Dm", "E", "C", "G", "F", "G", "F", "C", "G", "F", "G", "F", "C"],
	"aqualung" : ["C", "D", "E", "D", "E", "D", "E", "F", "C", "D", "C", "F", "D#", "A#", "C#", "C"],
	"Ben" : ["C", "F/C", "C", "F/C", "C", "G7", "C", "G7", "CΔ", "Esus4/B", "E/G#", "E7/A#", "A7", "G#7", "G7", "C", "F/C", "C", "F/C", "Cm", "G7", "Cm", "G7", "CΔ", "Esus4/B", "E/G#", "E7/A#", "A7", "G#7", "G7", "C", "F/C", "C", "F/C", "Dsus4", "G/B", "CΔ", "Dm7", "G/B", "C", "Dsus4", "G/B", "CΔ", "Dm7", "G/B", "C", "F/C", "C", "G7", "C", "G7", "CΔ", "G/B", "E7/A#", "A7", "G#7#5", "G7", "C", "F/C", "C"],
	"djobi djoba" : ["CΔ", "Em", "Dm", "G", "CΔ", "Em", "Dm", "G", "CΔ", "FΔ", "Em", "Dm", "G", "Dm", "Em", "Dm", "G", "CΔ", "Dm", "Em", "Dm", "G", "CΔ"],
	"wedding march" : ["C", "Am6", "B", "B7", "Esus24", "Em", "Dm", "C", "G", "C", "Am6", "B", "B7", "Esus24", "Em", "Dm", "C", "G", "C", "G", "C", "G7", "C", "G7", "C", "Am7", "Dm", "Am", "Dm7", "F+", "D7", "G", "C"],
	"BWV 831" : ["C", "Dm7/C", "G/B", "C", "Am/C", "D7/C", "G/B", "CΔ/B", "Am7", "D7", "G#", "G°7", "Dm/F", "F°7", "C/E", "FΔ/E", "Dm7", "G7", "C", "C7", "FΔ", "F#°7", "B°/E", "G7", "C/G", "G7sus4", "G7", "C°7/G", "C/G", "G7sus4", "G7", "C7", "F/C", "G7/C", "C"],
	"sir duke" : ["C", "Am", "G#9", "G9", "C", "Am", "G#9", "G9", "F#9", "F9", "E9", "D#9", "D9", "D#9", "E9", "F9", "F9", "E9", "D#9", "D9", "D#9", "E9", "F9", "F#9", "G9", "C", "F#m7", "FΔ", "Em7", "Dm7", "G", "C", "F#m7", "FΔ", "Em7", "Dm7", "G", "C"],
	"BWV 1007" : ["C", "F/C", "G7/C", "C", "Am/C", "D/F#", "G/B", "Am", "D/A", "Dsus4", "D7", "G", "G°7", "Dm", "G#°", "F", "G", "C", "G7/B", "C7", "F/C", "G7/C", "C", "D7/F#", "G7/F", "D7", "G7", "C", "C/G", "D7", "G", "G7", "F", "C", "G" , "Dm7", "G", "G7", "C/D", "Dm", "C/D", "G/D", "Dm7", "G7", "C", "Gsus4", "G7", "C"],
	"The wind cries mary" : ["G", "F", "C", "G", "F", "C", "D", "F", "C"],
	"BWV 1009" : ["C", "G", "Dm/F", "F", "G7", "C", "D7/C", "Am", "G/B", "E7", "Am", "D7", "D7/F#", "G", "G7/B", "Em", "Am", "D7/F#", "D7/A", "Em/B", "Dm", "C", "G", "D/A", "Em/G", "D7", "Gm/D", "D#°", "D7", "G", "D7", "G", "G7", "C", "Am" , "Dm", "G/B", "E", "Am", "F", "Dm/F", "E7/D", "E7/C", "Am", "C7", "C7/E", "Am", "F", "C7", "G°7", "F", "Dm", "E7/G#", "F/A", "Em/B", "Am/C", "G/D", "C/E", "G7/F", "G", "G7", "Cm/G", "E7/G#", "G7", "C", "F", "G", "C"],
	"Christophe ascendant" : ["C", "C/E", "F", "F#°", "G", "C", "C/E", "F#°7", "G7", "C"],
	"Christophe descendant" : ["C", "C7/A#", "F/A", "Fm/G#", "G", "C"],
	"dim chroma" : ["C", "C#°", "Dm", "D#°", "Em", "F", "F#°", "G", "G#°", "Am", "A#", "B°", "CΔ", "C#°7", "Dm7", "D#°7", "Em7", "FΔ", "F#°7", "G7", "G#°7", "Am7", "A#Δ", "Bm7b5"],
	"aug prog" : ["C", "C+", "Am", "F", "F+", "Dm", "A#", "A#+", "G", "G+", "Em", "CΔ", "CΔ#5", "Am7", "FΔ", "FΔ#5", "Dm7", "A#Δ", "A#Δ#5", "G7", "G7#5", "Em7"],
	"BWV 1005 allegro assai" : ["C", "G", "C", "G", "F", "C/E", "C7/E", "F", "C/E", "C7/E", "F", "C/E", "G7/D", "C", "G7", "C", "C7", "A7b9", "Dm", "G7", "A7add13", "Dm7", "G/D", "A7", "G/D", "A7/D", "D", "D7#5", "D7", "Gm", "Cm", "D", "D7", "Gm", "D" , "G"],
	"stir it up" : ["C", "F", "G", "C"],
	"one" : ["Dm", "C", "Em", "F", "Dm", "C", "Em", "F"],
	"november rain": [ "C", "G", "C", "Dm", "G", "C", "F", "C", "G7", "C", "G6", "F", "Dm", "C", "G7", "C", "G6", "F", "Dm", "C", "G7", "C", "G6", "Dm", "G", "C"],
	"more than words" : ["C", "C/E", "Fadd9", "Dm7", "F", "G", "C", "C/E", "Fadd9", "Dm7", "F", "G", "Am", "Dm7", "G", "G7", "C", "G/B", "Am", "Dm7", "G", "Gsus2", "C", "C/E", "F", "Fm", "C", "Fadd9", "C", "Dm7", "G7", "C", "C/E", "C", "C/E", "G/B", "Am", "Em", "F", "FΔ9/E", "Dm7" , "G", "C", "C/E", "C", "C/E", "Gsus2/B", "Am", "Em", "F", "FΔ9/E", "Dm7" , "G7", "Fadd9", "C/E", "Fadd9", "Dm7", "F", "G", "Gsus4", "C", "Gsus2/B", "Gsus2/A#", "Asus4", "Dm7"],
	"Anthropology" : ["C", "Dm7", "G7", "Em7", "A7", "Dm7", "G7", "C7", "F7", "Em7", "A7", "Dm7", "G7", "C", "Dm7", "G7", "Em7", "A7", "Dm7", "G7", "C7", "F7", "C", "A7", "Dm7", "G7", "C", "E7", "A7", "D7", "G7", "C", "Dm7", "G7", "Em7", "A7", "Dm7", "G7", "C7", "F7", "C", "A7", "Dm7", "G7", "C"],
	"The way you look tonight" : ["C6", "Am7", "Dm9", "G7", "CΔ", "Am7", "Dm9", "G7",  "CΔ", "Am7", "Dm7", "G7", "CΔ", "A7", "Dm7", "G7", "Gm7add11", "C7", "FΔ", "Dm7", "G7", "C6", "Am7", "Dm9", "G7", "CΔ", "Am7", "Dm9", "G7", "C"],
	"The way you look tonight pont" : ["Dm7", "G7", "CΔ", "C#°7", "Dm7", "G7", "CΔ", "Em7",  "D#°7", "Dm7", "G9", "CΔ", "C#°7", "Dm7", "G13",  "CΔ", "FΔ#11"],
	"st thomas" : ["C", "Em7", "A7", "Dm7", "G7", "C", "Em7", "A7", "Dm7", "G7", "C", "Em7b5", "Bb7",  "A7", "Dm7", "G#7#5", "G7", "C7", "C9/E", "F6", "F#°7", "C/G", "G7", "C"],
	"all the things you are" : ["Am7", "Dm7", "G7", "CΔ", "FΔ", "F#m7", "B7", "EΔ", "Em7", "Am7", "D7", "GΔ", "CΔ", "C#m7b5", "F#7", "BΔ", "C#m7", "F#7", "BΔ", "A#m7b5", "D#7", "G#Δ", "E7#5", "Am7", "Dm7", "G7", "CΔ", "FΔ", "A#13", "Em7", "D#°7", "Dm7", "G7", "C6", "Bm7b5", "E7", "Am7"],
	"scrapple" : ["Dm7", "G7", "Dm7", "G7", "C", "F7", "F#°7", "C", "Dm7", "G7", "Dm7", "G7", "C", "F7", "F#°7", "C", "Bm7", "E7", "A7", "D7", "Dm7",  "G7", "Dm7", "G7", "Dm7", "G7", "C", "F7", "F#°7", "C"],
	"all of me" : ["C6", "E7", "A7", "Dm7", "E7", "Am7", "D13", "Dm7", "G7", "C6", "E7", "A7", "Dm7",  "F6", "Fm6", "Em7", "A9", "Dm7", "G13", "C6"],
	"Bright size life" : ["CΔ", "D#Δ", "G", "G7/F", "CΔ", "D#Δ", "G", "G7/F", "C/E", "G", "C/D", "A#/C", "D", "G", "CΔ", "D#Δ", "G", "G7/F", "C/E", "GΔ", "CΔ"],
	"Donna lee" : ["CΔ", "A7", "D7", "Dm7", "G7", "CΔ", "Gm7", "C7", "FΔ", "A#7", "CΔ", "A7", "D7", "D7#11", "Dm7", "G7", "CΔ", "A7", "D7", "Bm7b5", "E7", "Am", "E7", "Am", "E7", "Am", "D#°7", "Em7", "A7", "Dm7", "G7", "CΔ", "Dm7", "G7", "CΔ"],
	"body & soul" : ["CΔ", "Dm7", "Em7", "Fm6", "Em7", "Am7", "Dm7", "G7", "CΔ", "Dm7", "G7", "Em7", "D#°7", "Dm7", "G7"],
	"Stella" : ["F#m7b5", "B7", "Dm7", "G7", "Gm7", "C7", "FΔ", "A#7", "CΔ", "F#m7b5", "B7", "Em7", "Cm7", "F7", "GΔ", "F#m7b5", "B7", "Bm7b5", "E7", "A7#5", "Dm7", "A#7#11", "CΔ", "F#m7b5", "B7", "Em7b5", "A7", "Dm7b5", "G7", "CΔ"],
	"there is no greater love" : ["CΔ", "F9", "A#9#11", "A7", "D9", "Dm7", "G7", "CΔ", "F9", "A#9#11", "A7", "D9", "Dm7", "G7", "C69", "Bm7b5", "E7", "Am6", "Bm7b5", "E7", "Am6", "Bm7b5", "E7", "Am7", "D7", "G7", "CΔ", "F9", "A#9#11", "A7", "D9", "Dm7", "G7", "C69", "Dm7", "G7", "CΔ"],
	"someday my prince will come" : ["CΔ", "E7#5", "FΔ#11", "A7#5", "Dm7", "A7#5", "D7", "G7", "Em7", "D#°7", "Dm7add11", "G7", "Em7", "D#°7", "Dm7add11", "G7", "CΔ", "E7#5", "FΔ#11", "A7#5", "Dm7", "A7#5", "D7", "G7", "Gm7", "C9", "FΔ", "F#°7", "CΔ/G", "Dm9/G", "G7", "C69"],
	"Dim escape" : ["C°", "C"],
	"Dim escape2" : ["C°", "Em"],
	"Dim escape3" : ["C°", "G"],
	"Dim escape4" : ["C°", "A#"],
	"Dim escape5" : ["C°", "Dm"],
	"Dim7 escape" : ["C°7", "C"],
	"Dim7 escape2" : ["C°7", "Em"],
	"Dim7 escape3" : ["C°7", "G"],
	"Dim7 escape4" : ["C°7", "A#"],
	"Dim7 escape5" : ["C°7", "Dm"],
	"Top prog 1" : ["C", "G", "Am", "F", "C"],
	"Top prog 2" : ["C", "G", "Am", "Em", "F", "C"],
	"Top prog 3" : ["C", "Am", "F", "G", "C"],
	"Top prog 4" : ["C", "F", "Am", "G", "C"],
	"Top prog 5" : ["C", "G", "F", "G", "C"],
	"Top prog 6" : ["F", "Dm", "C/G", "G", "C"],
	"Top prog 7" : ["C", "G/B", "Am", "G", "C"],
	"Top prog 8" : ["C", "Dm7", "C/E", "F", "C"],
	"Top prog 9" : ["C", "Em/B", "Am", "F", "C"],
	"Top prog 10" : ["F", "C/E", "G", "C"],
	"Top prog 11" : ["F", "C/E", "Dm", "C"],
	"Top prog 12" : ["C", "C7", "F", "C"],
	"Top prog 13" : ["F", "Fm", "C", "F"],
	"Top prog 14" : ["C", "A#", "F", "C"],
	"Top prog 15" : ["C", "G#", "G", "C"],
	"Top prog 16" : ["C", "G", "F", "A#", "C"],
	"A Journey in the Dark" : ["F", "Am", "F", "G", "C"],
	"star treck" : ["C6", "G#9", "C6", "F9", "D", "C#7b5", "D#6", "G7b5", "C6", "G#9", "C6", "F7", "E9", "F", "A#7", "Em7add11", "A7#5b9", "Dm7", "G7sus4", "D°", "C"],
	"spy changes" : ["Dm7", "G7", "CΔ", "CmΔ9", "Dm7"],
	"alt moon" : ["C69","Gadd9/B", "A#13", "A7", "Dm7"],
	"walk on by" : ["Dm7", "Em7", "Dm7", "Em7", "Am7", "Em7", "Dm7", "G", "CΔ", "Dm7", "CΔ", "Dm7", "CΔ", "Em7"],
	"Still crazy after all these years" : ["AΔ", "EΔ", "EmΔ", "Am7", "CΔ", "G", "G7/B", "C", "F", "G", "F#°", "Bsus4", "B7", "Em", "D#m7", "Dm7", "G7", "C", "C#°", "G", "D7", "Em", "C#°", "G/D", "D7", "Cm", "D7", "G", "G7/B", "C", "F", "G", "F#°", "Bsus4", "B7", "Em", "D#m7", "Dm7", "G7", "C", "C#°", "G", "D7", "Em", "C#°", "G/D"],
	"Still crazy after all these years 2" : ["F7", "A#9", "CΔ", "G", "Gm"], 
	"Still crazy after all these years 3" : ["Dm7", "Gsus4", "G", "CΔ"],
	"Still crazy after all these years 4" : ["G7", "C", "B", "C", "B", "C", "B", "Am7", "G", "G7/B", "C", "F7", "G", "F#°", "Bsus4", "B7", "E"],
	"Still crazy after all these years 5" : ["G", "F/G", "C", "C7/E", "F", "F#°", "C", "G", "G#°", "Am", "F#°", "C", "F", "C/G", "G7", "C", "F", "C"],
	"Goodbye yellow brick road" : ["C", "G", "Am", "G", "F", "G", "C", "Dm", "G", "F", "Dm", "G", "C", "Dm", "G", "C", "F", "A#", "G", "C", "G#", "A#", "D#", "G#", "Fm", "G7", "C", "E7", "F", "C", "G7", "Dm", "G", "C", "Am", "E", "F", "G#", "A#", "C", "G/B", "Am", "Am7/G", "F", "G", "G#", "A#", "D#", "G#", "Fm", "G", "C", "Dm", "G", "C", "F", "Dm", "G", "C", "Dm", "G", "C", "F", "A#", "G", "C", "G#", "A#", "D#", "G#", "Fm", "G7", "C", "E7", "F", "C", "A7", "Dm", "G", "C", "Am", "E", "F", "G#", "A#", "C", "G/B", "Am", "Am7/G", "F", "G", "G#", "A#", "D#", "G#", "Fm", "G", "C"],
	"Real love" : ["Cm", "G", "Cm", "G", "C", "Em", "Am", "G+", "F", "Dm", "G", "C", "F", "C", "F", "C", "FmΔ", "C", "Fm6", "C", "Am", "F", "G", "C", "Am", "F", "G", "Dm", "A", "Dm", "Gsus4", "G", "C"],
	"its only love" : ["C", "Am", "C", "Am", "C", "Em", "A#", "F", "G", "G+", "C", "Em", "A#", "F", "G", "G+", "F", "G", "C", "Am", "A#", "G", "C", "Am", "A#", "G", "F", "G", "C"],
	"michelle" : ["C", "Fm7", "A#6", "A°7", "G", "F#°7", "G", "C", "Fm7", "A#6", "A°7", "G", "F#°7", "G", "C", "Fm7", "G", "Cm", "G", "C"],
	"Till There Was You" : ["C", "C#°7", "Dm7", "G7#9", "C", "C#°7", "Dm7", "Fm", "C", "Em", "D#m", "Dm7", "G7#9", "C", "Dm7", "G7#9", "C", "C#°7", "Dm7", "Fm", "C", "Em", "D#m", "Dm7", "G7#9", "C", "C#°7", "F", "Fm", "C", "A", "Dm7", "DmΔ", "D7", "G7", "G7#5", "C", "C#°7", "Dm7", "Fm", "C", "Em", "D#m", "Dm7", "G7#9", "C", "G7", "F#7", "G7", "C", "G#", "C", "CΔ"],
	"Im so tired" : ["C", "B", "F", "G7", "C", "Am", "F", "G7", "C", "G+", "Am", "Fm", "C", "G7", "F", "C", "B", "F", "G7", "C", "Am", "F", "G7", "C", "G+", "Am", "Fm", "C", "G7", "F", "C"],
	"dear prudence" : ["G", "G7", "CΔ/G", "G+", "G", "G7", "CΔ/G", "G+", "G", "G+", "C", "Cm", "G+", "G", "F", "C", "G", "G7", "CΔ/G", "G+", "G", "G+", "C", "Cm", "G+", "G", "F", "C", "G", "C/G", "D/G", "C/G", "G", "C/G", "D/G", "C/G", "G", "A#", "C", "G", "C"],
	"fixing a hole refrain" : ["C", "G", "C", "G", "D", "G", "D", "G", "C"],
	"from me to you" : ["C", "Am", "C", "Am", "C", "G7", "F7", "Am", "C", "G7", "C", "Gm", "C7", "F", "D7", "G", "G+", "C", "Am", "C", "G7", "F7", "Am", "C", "G7", "C", "Gm", "C7", "F", "D7", "G", "G+", "C"],
	"starting over" : ["C", "C+", "C6", "C+", "C", "G7", "C", "G7", "C", "C+", "C6", "C7", "F/C", "Fm/C", "C", "C+", "Dm", "G", "Dm", "G", "Em", "A", "Dm", "G", "C", "C+", "C", "C+", "C"],
	"starting over 2" : ["C", "Dm", "G7", "C", "Am", "Dm", "G7", "C", "A", "G/B", "A/C#", "D"],
	"isolation" : ["C", "G#+", "Am7", "C7/A#", "F", "C", "G#+", "Am7", "C7/A#", "F", "C", "G#+", "Am7", "C7/A#", "F7", "G", "F9", "C", "G", "F", "G", "C", "G", "F", "G", "F", "G", "F7", "G", "C", "G#+", "Am7", "C7/A#", "F", "C", "C7", "F7", "C"],
	"isnt it a pity" : ["C", "F#m7b5/C", "FΔ/C", "C", "F#m7b5/C", "FΔ/C", "C", "C°7", "Dm7/C", "C", "D7", "F", "C", "C°7", "Dm7/C", "C", "D7", "F", "C", "C°7", "Dm7/C", "C"],
	"when Im 64" : ["C", "F", "G", "C", "G", "C", "C7", "F", "Fm", "C/G", "A", "D7", "G7", "C", "Am", "G", "Am", "E", "Am", "Dm", "F", "G", "C", "G", "Am", "A#°7", "G7", "C"],
	"heartache tonight" : ["D#", "A#", "F", "C", "Am", "C", "Am", "C", "F", "C", "G", "C", "Am", "C", "Am", "C", "F", "C", "G", "G7", "F7", "C", "F7", "D7", "G7", "C", "C7", "F7", "F#°7", "C", "G7", "C"],
	"we are the champions" : ["C", "Em", "Am", "F", "G", "C", "Em", "F", "C#°", "Dm", "G/D", "Fm", "B°7", "C", "A#/D", "D#", "F", "G7sus4", "C"],
	"Bohemian Rhapsody" : ["Am7", "D7", "Am7", "D7", "Am7", "D7", "G7", "Dm7", "G7", "C", "Dm7", "C", "G7", "C", "Am7", "C7", "F", "Dm7", "G7", "C#", "C", "B", "C", "C#", "C", "B", "C", "F", "C/E", "D#°", "G7/D", "C"],
	"Bohemian Rhapsody 2" : ["C", "Am", "Dm", "Dm7", "G7", "C", "Am", "Dm", "C#+", "F/C", "Bm7b5", "A#Δ", "A+", "F"],
	"Bohemian Rhapsody 3" : ["C", "G/B", "Am", "Dm", "C+", "F/C", "Bm7b5", "G7", "C", "G/B", "Am", "Fm", "C", "F", "C", "C°", "Dm7", "G"],
	"Bohemian Rhapsody 4" : ["Bm7b5", "A#", "A#Δ/A", "A#7/G#", "G+", "F#"],
	"Bohemian Rhapsody 5" : ["C", "F", "C", "C°", "C", "F", "C", "C°", "C", "F", "C", "F", "C", "C°", "C", "F", "C", "E/B", "B7", "D#/A#", "G7", "C"],
	"Bohemian Rhapsody 6" : ["C#", "C", "B", "C", "C#", "C", "B", "C", "A#/F", "F", "F°", "F", "A#/F", "F", "F°", "F", "A#", "F/A", "G7", "C"],
	"Bohemian Rhapsody 7" : ["F", "C/E", "D#°", "Dm7", "G#", "G", "F#", "G", "G#", "G", "F#", "G", "C", "G", "C", "G", "C/G", "G7", "C/G", "G", "C", "D#7", "G#m", "F#7", "B", "A#7", "D#7", "G7", "C", "F/C", "C", "B°", "Am7", "G7", "C", "F", "B7", "Em", "G7", "C"],
	"Bohemian Rhapsody 8" : ["F", "G", "C", "F", "G", "D#", "C", "F7", "A#"],
	"Bohemian Rhapsody 9" : ["Dm", "G", "Dm", "G", "Dm", "G", "Dm", "G", "C"],
	"Bohemian Rhapsody 10" : ["C", "D", "D#", "F", "F#", "G#7", "F7", "G7", "C", "G/B", "Am", "G#°7", "Am", "G#°7", "Am", "G7", "C", "B", "Em", "F", "G", "Am", "Em", "Am", "Em", "Am", "Fm", "F/G", "C"],
	"Bohemian Rhapsody 11" : ["C", "F", "C", "C°", "C"],
	"Bohemian Rhapsody 12" : ["F/A", "G#6", "G7", "G7b9", "G7", "C", "F", "C", "D#°", "Dm7", "C"],
	"The show must go on" : ["F", "G", "Em", "Am", "G", "F", "G", "Em", "Am", "C#m7b5", "D"],
	"I'm going slightly mad" : ["G9", "G7", "G9", "G7", "C", "C°", "Dm7b5", "C", "C°", "Dm7b5", "D#", "Dm", "D#", "G°7", "G#°7", "Am6"],
	"I'm going slightly mad 2" : ["Gm7", "Dm", "D", "Dm7", "Em7", "D", "Dm", "Em7", "F", "Em", "F", "F°7", "Gm7"],	
	"I'm going slightly mad 3" : ["F9", "F7", "G9", "G7", "C", "C°", "Dm7b5", "C", "C°", "Dm7b5", "D#", "Dm", "D#", "D#°7", "Fm"],
	"Manchild" : ["G", "Bb", "D", "G", "Gsus4", "G", "B", "C"],
	"Manchild 2" : ["F", "G"],
	"Manchild 3" : ["Em", "A", "G", "F", "Em", "A", "F", "G"],
	"Manchild 4" : ["Am", "Bm", "Am", "Em", "Am", "Bm", "Am", "Em"],
	"Come on people" : ["C", "C+", "C", "C+", "F", "Em7", "Am7", "F", "G", "A", "Asus4", "Asus2", "A"],
	"Come on people 2" : ["C", "Csus4", "Csus2", "C", "C7", "C6", "CΔ", "C", "C7", "C6", "CΔ", "C"],
};


/* Morceaux transposés en A mineur # only */
const minorSong = {
	"oye commo va": ["Am", "D", "Am"],
	"quand la musique est bonne": ["G", "Am", "G", "F", "G", "Am", "G", "Am", "G", "F", "G", "Am", "C", "Dm", "Em", "Am", "Em", "Dm", "Em", "F", "Em", "Am"],
	"un jour en france": ["Em", "F", "Em", "Am", "Em", "F", "Em", "C", "Em", "F", "Em", "Am", "Em", "F", "C", "Am"],
	"something in the way": ["Am", "F", "Am"],
	"petite marie": ["Dm", "G", "Am", "Dm", "E", "Am", "Dm", "G", "Am", "Dm", "E", "Am", "F", "G", "Em", "F", "G", "Am", "E", "C", "Dm", "F", "C", "G"],
	"losing my religion": ["F", "G", "Am", "F", "G", "Am", "G", "Am", "Em", "Am", "Em", "Dm", "G", "Am", "G", "F", "G", "C", "Dm7", "C", "Am"],
	"polly": ["Am", "C", "G", "F", "Am", "C", "G", "F", "G", "F", "C", "D#", "G", "F", "C", "D#", "Am"],
	"tout le bonheur du monde": ["Am", "F", "G", "Am", "F", "G", "Am", "G", "Am", "A#", "Dm", "G", "Am", "A#", "Dm", "A#", "Dm", "Am", "A#", "Am"],
	"mr jones": ["Am", "F", "Dm", "G", "Am", "F", "G", "C", "F", "G", "C", "F", "G", "Am", "F", "Am", "G", "Am", "F", "Am", "G", "C", "F", "G"],
	"oricono flow": ["Am", "G", "D", "Am", "G", "D", "E"],
	"about a girl": ["Am", "C", "Am", "C", "Am", "Dm", "F", "Am"],
	"about a girl 2": ["Asus2", "Em", "Dm7"],
	"lemon tree": ["Am", "Em", "Am", "Em", "D", "Em", "Am", "Em", "Am", "E", "Am", "G", "E", "E7", "Am"],
	"house of the rising sun": ["Am", "C", "D", "F", "Am", "E", "Am", "C", "D", "F", "Am", "C", "E", "Am", "C", "D", "F", "Am", "E", "Am", "Dm", "Am", "Dm", "Am"],
	"sweet dreams": ["Am", "F", "Em", "Am", "F", "Em", "Am", "F", "Em", "F", "Em", "Am", "F", "Am", "F", "Em", "Am", "F", "Em", "F", "Em", "F", "Em", "Am", "F", "Em", "Am"],
	"no milk today": ["Am", "C", "E", "Am", "E", "Am"],
	"greensleeves": ["Am", "G", "F", "E", "Am", "G", "F", "E", "Am", "C", "G", "F", "E", "C", "G", "F", "E", "Am"],
	"on my way": ["Am", "Am7", "Dsus2/F#", "F", "C", "CΔ/B", "Am", "Am7", "Dsus2/F#", "F", "C", "CΔ/B", "E7", "F", "C", "E7", "Am", "C", "E7", "F", "Dsus2/F#", "F", "Am"],
	"im only sleeping": ["Am", "Dm", "C", "F", "C", "E7", "Am", "Dm", "C", "F", "C", "F", "C", "Dm", "Em", "Dm", "FΔ", "Am", "Dm", "E7", "Am", "C", "F", "E7", "Am", "G", "C", "G", "Am"],
	"sunny afternoon": ["Am", "Am7/G", "F#m7b5", "FΔ", "E7", "E7/D", "E7#5/C", "E7/B", "Am", "G", "C", "G", "E7", "Am", "G", "C", "G", "E7", "Am", "A7", "D", "G", "C", "E7", "Am", "D", "A7", "D", "G", "C", "E7", "Am"],
	"glorybox": ["Am", "G", "F#°", "F", "Am"],
	"fight for your right": ["Am", "C", "Dm", "Am", "E", "Am"],
	"parisienne walkways": ["Am", "Dm7", "G9sus4", "CΔ", "F", "Bm7b5", "E7", "Am", "Dm7", "G9sus4", "CΔ", "F", "Bm7b5", "E", "A", "D", "A", "Dm7", "G9sus4", "CΔ", "FΔ", "Bm7b5", "E", "E7", "Am", "FΔ/A", "F#m7b5", "A7", "Dm7", "G9sus4", "CΔ", "FΔ", "Bm7b5", "B", "E", "F", "E", "Dm7", "G9sus4", "CΔ", "FΔ", "Bm7b5", "E", "Am", "Dm", "Am", "F", "E", "Am", "Dm", "Am", "F", "G", "Am"],
	"laventurier": ["Em", "C", "G", "Am", "Em"],
	"foule sentimentale": ["Am", "Dm", "G", "E7", "Am", "F", "Dm", "E7", "Am"],
	"smoke on the water": ["Am", "G", "Am", "Dm", "A#", "Am", "Dm", "G", "Am"],
	"flowers for zoe": ["G6", "F#7#5", "FΔadd13", "E7", "G6"],
	"the nobodies": ["Am", "Am/C", "E+", "Am", "Am7/G", "Am", "F", "Am", "F", "E", "Am"],
	"harvest": ["Am", "Dm", "Em", "Am", "F", "Em", "E", "Am", "Dm", "Am", "Em", "C", "B", "Dm", "Am", "Em", "Gm", "Dm", "C#", "Am", "E", "Am", "Em", "D#", "F#m", "Dm", "Am"],
	"curse of the black pearl": ["Am", "G", "Am", "G", "Am", "Dm", "Am", "G", "Am", "G", "Am", "Dm", "Am", "G", "Am", "F", "Am", "G", "Dm", "Am", "G", "Am", "C", "G", "C", "G", "C", "G", "Am"],
	"Dexter": ["Am", "Dm", "A#", "C", "Am", "Dm", "B", "E7", "Am"],
	"dans leau de la claire fontaine": ["C", "E7", "Am", "C7", "F", "E", "Am", "E", "Am", "C", "E7", "Am", "C7", "F", "E7", "Am", "E7", "Am"],
	"game of thrones": ["Am", "A", "Am", "Em", "G", "Dm", "Am", "Em", "G", "Am", "F", "C", "Dm", "Am", "F", "Dm", "Em", "Am"],
	"Pennyroyal tea": ["Am", "G", "Am", "G", "C", "D", "A#", "C", "D", "A#", "Am"],
	"the man who sold the world": ["C", "Am", "E", "Am", "Asus4", "Am", "Asus2", "E", "C", "G", "E", "Am", "G", "C", "G#", "C", "G", "C", "G#", "E", "Am", "C", "Am", "E", "Am", "C", "Am", "E", "Am", "C", "Am", "E", "Am", "C"],
	"california dreaming": ["Am", "G", "F", "G", "E7sus4", "E7", "F", "C", "E7", "Am", "F", "E7sus4", "E7", "Am", "G", "F", "G", "E7sus4", "E7", "Am", "F", "C", "E7sus4", "Am", "F", "E7sus4", "E7", "Am", "G", "F", "G", "Am", "G", "F", "G", "Am", "G", "F", "G", "FΔ", "Am"],
	"sultans of swing": ["Am", "G", "F", "E", "E7", "Am", "G", "F", "E", "C", "G", "F", "Am", "F", "G", "Am", "F", "G", "F", "G", "Am", "G", "F", "G", "Am", "G", "F", "E", "E7", "E7#5", "E7", "Am"],
	"jump in the fire": ["Am", "Am7/G", "F#°", "Dm", "C", "Am", "G", "C", "G", "Am", "Dm", "D#", "Am", "Am7/G", "F#°", "Am", "Dm", "C", "A#", "C", "Am", "G", "Am"],
	"come out and play": ["Am", "C", "Em", "G", "Am", "Em", "G", "Am", "Em", "C", "Am", "Em", "G", "Am", "C", "Em", "G", "Dm", "F", "E", "Am", "C", "Am", "Em", "G", "Am", "C", "Em", "G", "Em", "Am"],
	"du hast": ["Dm", "C", "Am", "Dm", "C", "Am", "C", "Dm", "C", "Dm", "F", "G", "Em", "Dm", "C", "Am", "Dm", "C", "Am"],
	"the sound of silence": ["Am", "G", "Am", "F", "C", "F", "C", "F", "C", "Am", "C", "G", "Am", "G", "C", "G", "Am", "C", "F", "C", "F", "C", "F", "C", "Am", "C", "G", "Am", "G", "Am", "C", "F", "C", "F", "C", "F", "C", "Am", "C", "G", "Am", "G", "Am", "C", "F", "C", "F", "C", "F", "C", "G", "Am", "C", "G", "Am", "G", "Am", "C", "F", "C", "F", "C", "F", "C", "Am", "C", "G", "Am"],
	"break on through": ["Am", "G", "Am"],
	"moonlight shadow": ["Am", "F", "G", "C", "G", "Am"],
	"zombie": ["Am", "FΔ", "C6", "D7sus4/B", "Am"],
	"Because": ["F", "Am", "F7", "F7add13", "A#", "A#°", "Am", "Bm7b5", "E", "F", "Am", "F7", "F7add13", "A#", "A#°", "Am", "Bm7b5", "E", "F", "Am", "F7", "F7add13", "A#", "A#°", "D", "E7", "Am", "Bm7b5", "E", "F", "Am", "F7", "F7add13", "A#", "A#°", "Am"],
	"sunny": ["Am", "C7", "F7", "E7", "Am", "C7", "F", "Dm", "E", "E7", "Am", "F", "E", "Am"],
	"lauvergnat": ["Am", "E7", "Am", "E7", "F", "G7", "C", "E7", "Am", "E7", "Am", "E7", "Am", "G7", "C", "C7", "F", "G7", "C", "Am", "Dm", "E7", "Am", "E7", "Am", "G7", "B7", "E7", "Am", "E7", "Am", "D", "G", "F", "E7", "Am"],
	"5/4": ["Am", "Dm", "Am", "C", "E", "Am"],
	"paint it black": ["Am", "E", "Am", "E", "Am", "G", "C", "G", "Am", "G", "C", "G", "D", "E7", "Am"],
	"inspecteur gadget": ["Am", "F7", "E7", "Am", "E7", "Am", "F7", "E7", "Am", "Dm", "E", "Am", "Dm", "G", "C", "Dm", "G", "C", "Dm", "B7", "E", "Am"],
	"canonball": ["Am", "G", "Dm", "G", "Am", "Dm", "G", "Am"],
	"come together": ["Am7", "E7", "D", "Am7", "E7", "D", "F#m", "E", "D", "E", "Am7"],
	"pink panther": ["Am", "F7", "Am", "A#7", "Am", "F7", "Am", "F7", "Am", "A#7", "Am", "F7", "Am", "F7", "E7", "Am"],
	"smell like teen spirit": ["Am", "C", "Em", "G", "Am", "G", "Am", "A#", "Am", "G", "Am", "Dm", "C", "Am"],
	"master blaster": ["Am", "G", "F", "E", "D", "Am", "E", "D", "Am", "G", "Am"],
	"lithium": ["Dm", "F", "Dm", "F", "Dm", "F", "G", "Em", "Am"],
	"sweet child of mine": ["G", "Fadd9", "C", "G", "F", "C", "G", "Gsus4", "G", "Gsus2", "G", "Dm", "F", "G", "Gsus4", "G", "Gsus2", "G", "Dm", "F", "G", "Am", "F", "Em", "Dm", "Am", "C", "Dm", "F", "G", "C", "Am", "C", "Dm", "F", "G", "Am"],
	"beat it": ["Am", "G", "Am", "G", "F", "G", "Am", "G", "Am"],
	"my sharona": ["Am", "D", "C", "D", "C", "Am", "C", "Dsus4", "D", "F", "G", "Am", "Dsus4", "D", "F", "G", "F", "G", "Am", "Dsus4", "D", "F", "G", "F", "G", "E", "Am"],
	"seven nation army": ["C", "D", "A", "C", "A", "G", "F", "E", "A", "C", "A", "G", "F", "G", "F", "E", "D", "A"],
	"save tonight": ["Am", "F", "C", "G", "Am"],
	"wonderwall": ["Am7", "C", "Gsus4", "D7sus4", "Am7", "C", "Gsus4", "D7sus4", "Fadd9", "Gsus4", "D7sus4", "Am7", "C", "Gsus4", "D7sus4", "F", "G", "Am", "F", "G", "Am", "F", "G", "C", "Am", "C", "F", "C", "F", "C", "CΔ/B", "Am", "D7sus4", "Fadd9", "Am7", "C", "Am7", "Fadd9", "Am7", "C", "Am7", "D7sus4", "Am7"],
	"play for today": ["Bm7", "A#Δ", "Am", "Asus2", "Gsus2", "G7", "Gsus2", "Am", "Asus2", "Gsus2", "G7", "Gsus2", "F", "Am", "Gsus2", "Am", "F", "Bm7", "C6sus2", "Bm7", "C6sus2", "Bm7", "A#Δ", "Am", "Asus2", "Gsus2", "G7", "Gsus2", "F", "E7add11", "Dm7", "E7add11", "Dm7", "E7add11", "Dm7"],
	"come as you are": ["Am", "G", "Am", "Gsus4", "Asus4", "C", "Asus4", "C", "Am", "G", "Am", "Gsus4", "Asus4", "C", "Asus4", "C", "Dsus4", "Fsus2", "Dsus4", "Fsus2", "Am", "G", "Am", "G", "Asus4"],
	"daddy cool": ["Am", "G", "Am", "G", "Em", "Am", "G", "F", "Am"],
	"got to be real": ["FΔ", "E7", "Am", "FΔ", "E7", "Am", "Dm", "D°", "C", "Dm7", "E7", "Am"],
	"cant stop": ["Am", "Am7/G", "Am/E", "FΔ", "Am", "Am7/G", "Am/E", "FΔ", "C", "G", "Am/E", "FΔ", "C", "G", "Am/E", "FΔ", "C", "G", "Em7", "FΔ", "Am", "G", "Am7add11", "Em", "Em7", "F", "G", "Am", "G", "Am7add11", "Em", "Em7", "F", "G", "Am", "Em", "Em7", "F", "G", "Am", "FΔ/A", "G", "Am7add11", "Em", "F", "Am"],
	"glide": ["FΔ", "Em7add11", "A#69", "Am7", "FΔ", "Em7add11", "A#69", "Am7", "A", "G", "F", "G", "F", "Em", "Am7", "Em", "Am7"],
	"backwoods": ["Am7", "G69", "C", "D", "Am7", "Dm", "Cm", "Am", "Cm", "Dm", "Am7add11", "Am7", "D/A", "Am7", "D/A", "Am7"],
	"last night a DJ": ["Am7", "Am7add11", "Am7", "A9sus4", "Am7"],
	"Eye of the tiger": ["Am", "G", "Am", "Em", "F", "Am", "Am7", "Am9", "Am7", "Em", "F", "Dm", "C", "G", "Dm", "C", "G", "F", "G", "Am"],
	"roxanne": ["Am", "Em/G", "FΔ", "Em", "Dm7", "Gsus4", "Asus4", "Am", "Em/G", "FΔ", "Em", "Dm7", "Gsus4", "Asus4", "Dm7", "Gsus4", "Asus4", "Dm7", "Gsus4", "Asus4", "Dm", "C", "F", "G", "Am", "Dm", "C", "F", "G", "Am", "Am7/G", "Am"],
	"always on the run" : ["Am", "C", "D", "Am", "D", "Am", "Em", "Am", "G", "Am"],
	"billie jean": ["Am", "Dm", "Am", "Dm", "Am", "F", "Am", "F", "Am", "F", "Am", "F", "E", "Am"],
	"stairway to heaven": ["Am", "Amadd9/G#", "Am7/G", "D/F#", "FΔ", "G/B", "Am", "Amadd9/G#", "Am7/G", "D/F#", "FΔ", "G/B", "Am", "C", "D", "FΔ", "Am", "C", "G", "Dsus4", "D", "C", "D", "FΔ", "Am", "C", "D", "FΔ", "Am", "G/B", "Am7", "Em", "Dsus2", "D", "Em", "Am7", "Em7/D", "D", "C/D", "D", "Am7", "Em", "Dsus2", "D", "Em", "Am7", "Em7/D", "D", "C/D", "D", "C", "G/B", "Am", "C", "G/B", "FΔ", "Am", "C", "G/B", "Am", "C", "G/B", "FΔ", "Am", "C", "G/B", "D", "Dsus2", "D", "Dsus4", "D", "C", "CΔ9#11", "CΔ9",  "D", "Dsus2", "D", "Dsus4", "D", "C", "CΔ9#11", "CΔ9", "G/B", "Am", "Am7/G", "FΔ", "Am", "Am7/G", "FΔ", "Am", "Am7/G", "F", "G/B", "Am", "Am7/G", "Em/G", "FΔ"],
	"american ghost dance": ["G7sus4", "G7", "G7sus4", "G7", "Am7"],
	"sunshine of your love": ["A7", "G7", "A7", "D", "C", "D", "A7", "G7", "A7", "E7", "G", "D", "E7", "G", "D", "E7", "A7"],
	"breaking the girl": ["A", "B7add11/A", "Am7", "Dadd9/A", "Em7/A", "A", "C", "G", "Am", "C", "G", "FΔ", "Am", "G/B", "C", "G", "E", "Am", "G/B", "C", "G", "FΔ", "Am", "G/B", "C", "G", "E", "Am", "G/B", "C", "G", "FΔ", "F7", "E7", "A", "B7add11/A", "Am7", "Dadd9/A", "Em7/A", "A", "Am7", "C9", "Am7", "C9", "Am7"],
	"war": ["Am", "G", "Am"],
	"plug in baby": ["E", "F", "C", "E", "F", "C", "E°", "F", "Am", "Em", "F", "Am", "E", "F", "Am", "Am/E", "F", "C", "E"],
	"another one bites the dust": ["Am", "Dm", "Am", "Dm", "F", "C", "F", "C", "F", "C", "F", "Dm", "E", "Am"],
	"all along the watchtower": ["G", "Am", "F", "G", "Am", "G", "F", "Am", "G", "F", "G", "F", "Am", "G", "F", "G", "F", "Am7", "F", "Am7", "F", "Am7", "F", "Am7", "E", "F", "G", "Am", "E", "F", "G", "Am", "E", "F", "E", "Am", "F", "E", "Am", "G6", "F"],
	"walking on the moon": ["A7sus4", "Am7", "F", "G", "Am7", "F", "G", "A7sus4", "Am7", "F", "C", "G", "Dm", "F", "C", "G", "A7sus4"],
	"if you have to ask": ["Am7", "Am7add13", "Am7", "E9", "D#9", "D9", "A7", "CΔ9", "G7", "B7#9", "E9", "D#9", "D9", "A7", "CΔ9", "G7", "B7#9"],
	"money": ["Am7", "Em", "Dm", "Am7", "Dm", "Am7", "Em", "Dm", "Am7", "Dm7", "Am7", "Am13", "Am7", "Am13"],
	"Boogie oogie oogie": ["Asus4", "Am", "Asus4", "Am", "Am7", "G7sus4", "Am7", "G7sus4", "Am", "Dm", "G69", "C", "Am", "Dm", "G69", "C"],
	"message in a bottle": ["Amadd9", "Fadd9", "G", "Dm", "Amadd9", "Fadd9", "G", "Dm", "Amadd9"],
	"long train running": ["A9sus4", "Am7", "A9sus4", "Am7", "Dm7", "A9sus4", "Am7", "F", "E", "A9sus4", "Am7", "A9sus4", "Am7", "Dm7", "A9sus4", "Am7", "F", "E", "Dm7", "A9sus4"],
	"aeroplane": ["D7sus4", "D7sus2", "D13sus4", "D7", "D7sus4", "Dsus2", "D9sus4", "D", "Am7", "D7", "Am7", "D7", "Am7add11", "D7", "Am7add11", "D7", "Fsus2", "Gsus4", "Am7", "Fsus2", "Gsus4", "Am7", "Fsus2", "Gsus4", "Am7", "D9", "Am7", "D9" , "Am7"],
	"hotel california": ["Am", "E7", "Gsus2", "D9", "F", "C", "Dm7", "E7", "Am", "E7", "Gsus2", "D9", "F", "C", "Dm7", "E7", "F", "C", "E7", "Am", "F", "C", "Dm", "E7", "Am", "E7", "Gsus2", "D9", "F", "C", "Dm7", "E7", "F", "C", "E7", "Am", "G/B", "Am", "G", "F", "C", "Dm", "E7", "Am"],
	"for the love of god": ["Amadd9", "A#Δadd#11", "Amadd9", "Dmadd9", "Amadd9", "FΔ", "A#Δadd#11", "Amadd9", "Am7", "Amadd9", "A#Δadd#11", "Amadd9", "Dmadd9", "Amadd9", "FΔ", "A#Δadd#11", "Amadd9", "Am7", "C", "A#Δsus2", "Am", "Gsus4add9", "G", "C", "A#Δsus2", "Am", "Gsus2", "Amadd9"],
	"jungleman": ["Am", "C", "D", "Am", "D#", "D", "F", "Am", "G", "Am", "F9", "G9", "Am", "Bm", "Am", "A", "E7", "A"],
	"rape me": ["A", "C", "E", "G", "A", "C", "E", "G", "C#", "A", "C#", "A", "B", "E", "A"],
	"hallelujah": ["A°", "Am", "A°", "Am", "A°", "Am", "A°", "AmΔ", "F", "C", "Am7", "C", "Am7", "C", "Am7", "C", "Am7", "F", "G", "Am", "Am7/G", "G", "C/E", "F", "G", "Am", "F", "G", "E7/G#", "Am", "C", "F", "Am", "F", "C/E", "G", "Gsus4", "Am7", "C", "Am7", "C", "F", "Am9", "F", "C/E", "G", "C", "Am7", "C", "Am7", "F", "C", "F", "G", "C"],
	"money for nothing": ["Am", "C", "D", "C", "Am", "C", "Am", "F", "C", "F", "G", "Am", "D", "E", "F#", "Am"],
	"jeux interdits": ["Am", "Dm", "E", "Am", "E7", "Am"],
	"fade to black intro": ["FΔ/A", "FΔ/G", "FΔ/A", "G/B", "FΔ/A"],
	"fade to black": ["F6", "E/G#", "E/B", "E7", "Amadd9", "C", "G", "Amadd9", "C", "G", "E", "Am", "G", "C", "G", "Am", "Dm", "Em", "C", "G/B", "Am", "G", "C", "G", "Am", "G", "Em", "Amadd9", "C", "G", "Amadd9", "C", "G", "Am"],
	"aces high": ["Am", "F", "G", "Am"], 
	"under the bridge": [ "A", "C", "G6", "FΔ", "A", "C", "G6", "FΔ", "E7", "G", "A", "C", "G6", "FΔ", "A", "C", "G6", "FΔ", "A"],
	"sunday bloody sunday": [ "Am", "C", "F6", "Am", "C", "F6", "C", "Dm", "C", "Dm", "Am", "C", "F", "Am", "C", "F", "Am", "C", "F6", "Am", "C", "F6", "D#", "Dm", "C", "D#", "Dm", "C", "Am", "C", "F6", "Am", "C", "F6", "D#", "Dm", "C", "D#", "C", "D#", "C", "D#", "C", "Am", "C", "F", "Am", "C", "F", "Am"],
	"bad": [ "Am7", "B7", "DΔ", "C#m7", "B7", "DΔ", "C#m7", "B7", "DΔ", "C#m7", "E7", "Am7"],
	"foxy lady": [ "Am7", "D7", "Am7", "D7", "A7#9", "G", "A7#9", "G", "Am7", "G", "Am7"],
	"Angie": ["Am", "Am7", "E7", "E7/G#", "Gsus4", "Fsus4", "F", "C", "G", "Gsus4", "Am", "Am7", "E7", "Gsus4", "Fsus4", "F", "Fsus2", "C", "G/B", "C", "Am", "Am7", "E7", "Gsus4", "Fsus4", "F", "C", "Gadd9", "G", "Dm", "Am", "C", "Gadd9", "G", "Gadd9", "G", "Am"],
	"good times": [ "Am", "D", "Am", "Bm", "Am"],
	"another brick in the wall": [ "Am", "D", "Am", "Em", "Am", "G", "D", "Am", "C", "G", "Am", "C", "G", "Am"],
	"tears in heaven" : ["Am", "E/G#", "Gm6", "A7", "Dm7", "F/G", "C"],
	"I shot the sherif" : ["Am", "Dm", "Am", "F", "Em", "Am"],
	"nothing else matters": [ "Am", "Dm", "Dm/E", "F", "G", "Am", "G", "F", "Am", "G", "F", "Am", "G", "F", "C", "E7", "Am", "G", "Gsus4", "Fadd9", "Am", "G", "Fadd9", "Am", "G", "Gsus4", "Fadd9", "C", "E7", "Am", "F", "D", "G", "F", "D", "G", "F", "D", "G", "Am"],
	"my lady darbanville": [ "Am", "G", "Am", "G", "Em", "Am"],
	"dont speak": ["Am", "Em", "Dm", "G", "Em", "Dm", "G", "Am", "Em", "Dm", "G", "Em", "Am", "Dm", "G", "Am", "Em", "A", "Dm", "G", "C", "G", "A"],	
	"dont speak refrain": ["Am", "Dm", "G", "E", "Dm", "E", "Am", "Dm", "Em7", "Am", "Dm", "G", "E", "Dm", "E", "Am", "F", "G", "Em"],
	"dont speak solo": ["Am", "Em", "Dm", "G", "Am", "Em", "Dm", "G", "Em", "Am", "Dm"],
	"europa": ["G7sus4", "G7", "CΔ", "FΔ", "E7sus4", "E7", "Am", "G7sus4", "G7", "CΔ", "FΔ", "E7sus4", "E7", "A7", "Dm", "G7", "CΔ", "FΔ", "E7sus4", "E7", "Am", "Dm7", "Am7", "Dm7", "Am7", "G13sus4", "Am7", "G13sus4"],
	"layla": ["Am", "G#7", "A#7", "C7", "Dm", "C", "F", "Dm", "G", "C", "Am"],	
	"floods": [ "Amadd#11", "G#addb13", "Amadd#11", "G#addb13", "CΔ/B", "D#", "C", "Am9", "Fadd9", "F7", "Csus2", "Am7add11", "A7sus2", "FΔ9", "A7sus2", "FΔ9", "Asus2", "Fsus2", "Cadd9", "Gsus2", "Amadd9", "Fadd9", "Cadd9", "Dmadd9", "Asus2", "Fsus2", "Csus2", "Gsus2", "Asus2", "Fsus2", "Csus2", "Gsus2", "Asus2"],
	"aqualung": ["Am", "G", "Gsus2", "G", "Gsus2", "G", "C", "Gm", "C6", "D", "Dm", "Dsus2", "Dm", "Am"],
	"women of irland": ["Dm7", "G", "F", "G", "Dm7", "G", "F", "G", "C", "Am", "F", "G", "Am", "Dm", "G", "C"],
	"drug squad": [ "Am", "G", "F", "E7", "Am", "E7", "Am", "F", "E7", "Am", "F", "E", "Am"],
	"I could have lied": [ "Am", "G", "FΔ", "Am", "G", "FΔ", "A", "C", "Gadd9", "F6", "Am", "G", "FΔ", "Am", "G", "F", "Am", "G", "FΔ", "Am"],
	"I could have lied refrain": [ "Am", "C", "G", "F", "Am", "C", "G", "F", "Am"],
	"jammin": [ "Am7", "D", "F", "Em7", "Am7", "D", "F", "Em7", "Am7", "D", "Am7", "D", "F", "Em7", "Am7", "D", "Am7", "D", "F", "Em7", "Am7"],
	"in the stone": [ "A", "A7/G", "Dm6", "E9sus4", "E9", "A", "A7/G", "Dm6", "E9sus4", "E9", "A", "Dm", "Gadd9", "Gadd9/B", "A", "Dm", "Gadd9", "F#7sus4", "E7sus4", "A", "A/C#", "A/E", "A7/G", "A", "D", "A"],
	"miserlou": [ "E", "F", "E", "F", "E", "Am", "G", "F", "E", "F", "E", "F", "E", "Am", "E", "F", "E"],
	"the thrill is gone": [ "Am", "Dm", "Am", "FΔ", "E7", "Am"],
	"purple haze": [ "A°", "Am", "A7#9", "C", "D", "A7#9"],
	"the godfather": [ "Am", "Dm", "Am", "Dm", "Am", "E", "Am", "G", "C", "A#", "D°7", "E", "Am", "Dm", "Am", "Dm", "Am", "E", "Am"],
	"sun is shining": [ "Am", "F/A", "Am7", "Dm", "Am", "Am7", "Am6", "F/A", "Am", "Am7", "Am6", "F/A", "Dm", "Dm7", "Dm6", "A#/D", "Dm", "Dm7", "Dm6", "A#/D", "Am", "Am7", "Am6", "F/A", "Am"],
	"BWV 1008" : ["Am", "E7b9", "Am", "FΔ", "Bm7b5", "Em7", "Am7", "Dm7", "Em7", "Dm", "G7", "C", "A7/C#", "Dm", "B7", "Em", "Am7", "B7", "Em", "Am", "Em", "B7", "Em", "E7", "Am", "B7/D#", "Em", "A7b9/C#", "Dm", "A#", "E7", "Am7", "Dm/F", "E7b9/G#", "Am" , "C", "E7", "Am", "B7", "Am", "E7", "Am", "E7", "Am", "A#", "Am", "E7", "Am", "B7/D#", "E7", "Am", "E7", "B7sus4", "B7", "E7", "Dm", "A#", "E7b9" , "Am", "Dm", "Dm7", "E7b9", "Am", "E7", "Am", "Dm", "A#", "A#", "E7", "Am", "Esus4", "E", "Am"],
	"paranoid android couplet": [ "Am", "Am7/G", "D9", "D7/F#", "Em", "F#7sus4b9", "F#7sus4/E", "Em7b5", "F#7sus4b9", "F#7sus4/E", "Em6", "DΔ13", "C#7", "Em6", "DΔ13", "C#7", "Am"],
	"paranoid android pont": [ "Am", "E/G#", "Em/G", "F#7", "Bm", "F#7", "Bm", "Bm7/A", "G", "D/F#", "Em6", "D", "C#7", "F#", "Am"],
	"BWV 1008 courante" : ["Am", "E", "Am", "E", "Am", "C", "C7", "F", "G/B", "C", "E7", "Am", "Bsus4", "B7", "Em", "C", "E°", "E", "Am", "E", "F", "E", "Am", "C", "C7", "F", "G/B", "C", "E7", "Am", "B7", "Em/G", "C", "E°", "E" , "E7", "Am", "D7", "G", "C7", "F", "G7", "C", "E7", "Am", "D", "E7", "Am", "F", "B7", "E", "Am", "E", "E7", "Am", "D", "G", "C" , "C7", "F", "G7", "C", "E7", "Am", "Dm", "E7", "Am", "E", "Esus4", "B7", "E", "B7sus4", "B7", "E", "Am"],
	"I want you intro": [ "Am", "Am/C", "B7b9", "F7", "E+", "Am", "Am/C", "B7b9", "F7", "E+", "Am"],
	"I want you couplet": [ "Am", "C", "G6", "Dm", "Em7", "Dm7", "F", "C6", "A#", "G", "Am", "E7b9", "Am7", "Dm7", "F", "C6", "A#", "G", "Am", "E7b9", "Am7", "Dm", "F", "Am7", "Dm7", "F", "C6", "A#", "G", "Am", "E7b9", "Am"],
	"BWV 1002 courante" : ["Am", "E7", "Am", "G7", "C", "Dm", "E7", "A7", "D7", "E7", "Asus4", "E7", "Am", "D#°7", "Em7", "A9", "Daddb9", "B7#9", "Em", "Am", "F", "D7b9/F#", "Gm", "E7", "Am", "Asus4", "A7", "Dm", "Dsus4", "D7", "Gm", "A7b9", "Dm", "A7", "Dm" , "D7b9", "Gm", "A7", "Dm", "D7", "G", "Am", "D7", "G", "C", "G", "C", "G", "C", "G9", "C7", "F", "G7", "C", "E7", "Am", "B7", "E7", "F#m", "Em", "Dm", "E7", "A7", "D9", "E7", "Am", "A7", "D7", "B7", "E7", "A7b9", "Dm", "E7", "Am"],
	"BWV 1004 courante" : ["Am", "E7/G#", "Am", "E7", "Dm", "E7", "Am", "C7/E", "F", "C7", "F", "G7/B", "Em", "C", "A7", "F", "E7/G#", "Am", "B7/D#", "Em", "B7", "G", "Am", "B7/D#", "Em", "E", "B7", "E", "Am", "E7", "Am", "C7/E", "F", "A7/C#", "A#/D" , "Am/C", "A#Δ", "A7", "Dm", "G7/B", "Am/C", "E7", "D7", "G", "C7/E", "F", "B7/D#", "E", "E7/G#", "Am", "A7b9", "Dm", "E7", "F", "B7", "E9/G#", "Am"],
	"could you be loved": [ "Am", "C", "Am", "F", "C", "Am", "F", "C", "Am", "Dm", "Am", "Dm", "Am", "F", "Em", "Dm", "Am", "F", "Em", "G", "C", "Am", "F", "C", "Am", "F", "C", "Am", "Dm", "Am", "F", "Em", "Dm", "Am", "F", "Em", "G", "C", "Am", "F", "C", "Am", "F", "C", "Am"],
	"one": [ "Am", "FΔ", "Am", "FΔ", "Am", "C/G", "FΔ", "Dm", "Am", "F", "G", "C", "G7", "D#", "Dm", "C", "G7", "D#", "Dm"],
	"ring my bell": [ "Am", "Dm7", "Am", "E7", "Am"],
	"BWV 926 prelude" : ["Am", "Dm/A", "E7/G#", "Am", "B7", "Em", "Gm/D", "A7", "Dm", "C", "Dm", "Am", "E", "E7/D", "F/C", "A7/C#", "Dm", "A#", "E7b9/G#", "Am", "E7b9", "Am", "B7", "E", "E7/D", "A7", "Dm", "A7", "A#", "A", "E7", "A"],
	"Last train to london" : ["Am7", "Em7", "Dm7", "Em7", "Am7", "Em7", "Dm7", "Em7", "FΔ", "G", "CΔ", "FΔ", "CΔ", "FΔ", "Em7", "Dm7", "CΔ", "FΔ", "CΔ", "FΔ", "Em7", "Dm7", "Am7"],
	"little wing": [ "Am", "C", "Dm", "Am7", "Em", "D#", "Dm7", "C", "F", "G", "Am"],
	"BWV 999 prelude" : ["Am", "Dm/A", "E7/A", "Am", "Am7/G", "FΔ", "Am/E", "B7/D#", "Emadd9", "Em", "CΔ", "Am6", "B7", "Em/B", "B7", "Em/B", "B7/G", "E°/B", "B7", "C#°/B", "B7sus4", "B7", "E°7/B", "B7b9", "Em/B", "B7sus4", "B7", "D#°/E", "E", "D#°/E", "E", "E7", "Am/E", "B7b9/E", "B7b9/A", "E"],
	"november rain": [ "Am", "E", "Dm", "C", "F", "G", "Am", "E", "Dm", "C", "F", "G", "Am"],
	"the 4 horsemen" : ["F", "C", "G", "F", "Am", "F", "C", "G", "F", "Am", "G", "Am", "F", "G", "Am", "C", "Am", "F", "C", "G", "F", "Am", "G", "F", "E", "Am", "G", "F", "E", "F", "E", "C", "Am", "A#", "Am", "A#", "Am", "Gsus2", "FΔ", "E7sus4", "Am", "Gsus2", "FΔ", "E7sus4", "Am", "Gadd9/B", "FΔ/C", "E7sus4", "Am", "Gadd9/B", "FΔ/C", "E7sus4", "Am"],
	"autumn leaves" : ["Dm7", "G7", "CΔ", "FΔ", "Bm7b5", "E7", "Am", "Dm7", "G7", "CΔ", "FΔ", "Bm7b5", "E7", "Am", "Bm7b5", "E7", "Am", "Dm7", "G7", "CΔ", "FΔ", "Bm7b5", "E7", "Am7", "Dm9", "Gm7", "C7", "FΔ", "Bm7b5", "E7", "Am"],
	"Blue bossa" : ["Am7", "Dm7", "Bm7b5", "E7", "Am", "Cm7", "F7", "BbΔ", "Bm7b5", "E7", "Am"],
	"Fly me to the moon" : ["Am7", "Dm7", "G7", "CΔ", "C7", "FΔ", "Bm7b5", "E7", "Am7", "A7", "Dm7", "G7", "CΔ", "A7", "Em7", "A7", "Dm7", "G7", "CΔ", "Bm7b5", "E7", "Am7", "Dm7", "G7", "CΔ", "C7", "FΔ", "Bm7b5", "E7", "Am7", "A7", "Dm7", "G7", "Em7", "A7", "Dm7", "G7", "CΔ", "Bm7b5", "E7", "Am7"],
	"minor swing" : ["Am6", "Dm6", "E7b9", "Am6", "Dm6", "Am6", "E7b9", "Am6",  "E7b9", "Am6", "E7b9", "Am6"],
	"song for my father" : ["Am9", "G9", "F9", "E7", "Am9", "G9", "F9", "E7", "Am9", "G9", "Am9", "G9",  "F9", "E7", "Am9"],
	"solar" : ["AmΔ", "Am7", "Em7", "A7", "DΔ", "Dm7", "G7", "CΔ", "Cm7", "F7", "A#Δ", "Bm7b5",  "E7", "AmΔ"],
	"body & soul" : ["Am7", "E7", "Am7", "D9", "GΔ", "C9", "Bm7", "A#°7", "Am7", "F#m7b5", "B7", "Em7", "Am7", "D7", "G6", "Bm7b5", "E7", "Am7"],
	"Dim escape" : ["A°", "E"],
	"Dim escape2" : ["A°", "G"],
	"Dim escape3" : ["A°", "Bm7b5"],
	"Dim7 escape" : ["A°7", "Am"],
	"Dim7 escape2" : ["A°7", "E"],
	"Dim7 escape3" : ["A°7", "G"],
	"Dim7 escape4" : ["A°7", "Bm7b5"],
	"Top prog 1" : ["Am", "G", "F", "G", "Am"],
	"Top prog 2" : ["F", "G", "E/G#", "Am", "F"],
	"Top prog 3" : ["C", "E", "Am", "C"],
	"Top prog 4" : ["Am", "Am7/G", "F", "Am"],
	"Top prog 5" : ["G", "G#°", "Am", "G"],
	"Honor Him" : ["Am", "F6", "G", "Am7/G", "F", "Dm", "G", "Em", "Am"],
	"Fallout 3" : ["Am", "A", "Am", "F", "G", "Am"],
	"suicide mission" : ["Am", "F", "G", "Dm", "F", "C", "Dm", "Am"],
	"earth" : ["Am", "F", "G", "Am", "C", "Dm", "Em", "Am"],
	"metal gear solid 3" : ["Am", "F", "C", "G", "Am", "F", "Em", "Dsus4", "D", "Bm", "G", "D", "A", "Bm", "G", "F#m", "Esus4", "E", "Am"],
	"The Bridge of Khazad-dûm" : ["Am", "F", "C", "G", "Am"],
	"The Ocean on His Shoulders" : ["Am", "G6", "FΔ", "G/D", "Am"],
	"time" : ["Dm", "Am", "C", "G", "Dm", "FΔ", "C", "G", "Am"],
	"walk on by" : ["Am7", "D", "Am7", "D", "Am7", "G7", "Am7"],
	"Still crazy after all these years" : ["Am", "E", "F", "E", "F", "C"],
	"moving out" : ["Am", "Dm7", "G", "G+", "CΔ", "Am", "Dm7", "G", "G+", "CΔ", "Am", "D7", "F", "G", "Am", "Dm7", "G", "G+", "CΔ", "F", "G", "E7", "Am", "Dm7", "Bm", "E7", "Am", "D7", "F", "G", "Am", "D7", "G", "G+", "C", "F", "G", "E7", "Am", "Dm7", "Bm", "E7", "Am"],
	"Almost blue" : ["Am", "AmΔ/G#", "Am7/G", "F#m7b5", "Dm9", "E+", "Am", "AmΔ/G#", "Am7/G", "Bm7b5", "E7", "Am", "AmΔ/G#", "Am7/G", "Bm7b5", "E7", "C", "F", "E", "D", "Am", "A°", "C", "A#", "A", "Dm", "Bm7b5", "E+", "Am", "B7", "Am", "F", "Bm7b5", "C", "C#°", "Dm", "Bm7b5", "E", "Am", "Dm", "Bm7b5", "E+", "Am", "Bm7b5", "Am"],
	"michelle refrain" : ["Am", "C7", "F", "E7", "Am", "Am/E", "E+", "Am7", "Am6", "Dm/A", "E", "Am"],
	"old brown shoe" : ["C7", "Dm7", "F", "F7", "Ab", "Ab7", "F", "E+", "Am", "C7", "D°7", "F", "F7", "Ab", "Ab7", "F", "E+", "Am", "G", "F", "G", "F", "F#°", "G", "G7", "C7", "D°7", "F", "F7", "Ab", "Ab7", "F", "E+", "Am", "F7", "Ab", "F7", "E7", "Am", "G7"],
	"fixing a hole" : ["A", "E+", "Am7", "Am6", "Am7", "Am6", "Am7", "Am6", "A", "E+", "Am7", "Am6", "Am7", "Am6", "Am7", "D", "A"],
	"this love" : ["E7/G#", "Am", "Dm", "B°7", "E7/G#", "Am", "Dm", "B°7", "Am", "Dm", "G7", "C", "Am", "Dm", "G7", "C", "Am", "Dm", "F", "E", "E7/G#", "Am", "Dm", "B°7", "E7/G#", "Am", "Dm", "B°7", "Dm", "C", "E7/G#", "Am", "Dm", "C", "E7", "Am"],
	"we are the champions" : ["Am", "G/A", "Am", "G/A", "Am", "G/A", "Am", "G/A", "C", "Csus4/F", "C", "Csus4/F", "C", "G/B", "Am", "D", "G","Am"],
	"The show must go on" : ["Am", "F", "Dm", "E", "Dm", "Am", "F", "Dm", "E", "Dm", "Am", "F", "Dm", "E", "Dm", "Am", "F", "Dm", "E", "Dm", "Cm"],
	"The show must go on 2" : ["Dm", "E", "Dm", "E", "Dm", "Am"],
	"I'm going slightly mad" : ["Am", "Bm7b5", "B°7", "Am", "Bm7b5", "B°7", "Am"],
	"I'm going slightly mad 2" : ["E7", "F", "E7", "F"],
	"Delicate" : ["Am", "F", "E", "Am", "F", "E", "Dm", "G", "E/G#","Am", "F", "E", "Am", "F", "E", "Dm", "G", "E/G#","Am", "F", "E", "Dm7", "Em7", "Am", "Dm7", "Em", "Am", "Am7", "Dm7", "Em7", "Dm7", "Em7", "F#Δ", "G#", "EΔ", "F#", "Am"],
	"Calling you" : ["Am9/E", "FΔ9", "Am9", "D9sus4", "Am9/E", "FΔ9", "Am9", "D9sus4", "Am9/E"],
	"Calling you 2" : ["Am7b5/D#", "D7/C", "Bm7b5", "E7", "C#m7b5", "F#+", "Bm7b5", "E7", "Am7b5/D#", "D7/C", "Bm7b5", "E7", "C#m7b5", "F#+", "Bm7b5", "E7", "Am7"],
	"Calling you 3" : ["Dm7add11", "G7", "Am7"],
	"Come on people" : ["Dm7", "Em7", "Am7", "Dm7", "Em7", "Am7"],
};

export default { majorSong, minorSong};

