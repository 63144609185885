import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/short Header/shortHeader";
import Footer from "../../components/footer/footer";
import style from "./notFound.module.scss";
import logo from "../../assests/images/logo n°4 (Noir sur transparent) Fred.png";
import { Helmet } from "react-helmet";

export default function NotFound() {
  const navigate = useNavigate(); // Usenavigate to redirect to the page

  useEffect(() => {
    // UseEffect is used to set the condition for redirection
    setTimeout(() => {
      // Set the timeout
      navigate("/"); // Redirect path to '/'
    }, 10000); // set time 10000ms
  }, []);

  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Erreur 404</title>
      </Helmet>
      <Header />
      <main className={`${style.notFound} d-flex f-column aic jcc`}>
        <h1>! Erreur 404 !</h1>
        <div className={`${style.img}`}>
          <img className={`${style.img}`} src={logo} alt="" />
        </div>
        <h2>Oups ! Il semblerait que cette page soit introuvable...</h2>
        <p>
          Vous allez être redirigé vers la page d'accueil, sinon{" "}
          <NavLink to={"/"} title="ACCUEIL">
            cliquez ici
          </NavLink>
        </p>
      </main>
      <Footer />
    </>
  );
}
