import style from "./methode.module.scss";
import methodeguitare from "../../../../assests/images/methodeguitare.jpg";
import methodebasse from "../../../../assests/images/methodebasse.jpg";
import mainStyle from "../../homepage.module.scss";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Scroller from "../../../../components/scroller/scroller";
import ShortDivider from "../../../../components/divers/shortDiver";

function RandomSentence() {
  const [text, setText] = useState("");

  useEffect(() => {
    let r_text = new Array();
    r_text[0] = `CHOOSE YOUR WEAPON!`;
    r_text[1] = `IT'S DANGEROUS TO GO ALONE! TAKE THIS.`;

    var i = Math.floor(r_text.length * Math.random());
    setText(r_text[i]);
  }, []);

  return (
    <p>
      <b>{text}</b>
    </p>
  );
}

export default function Methode() {
  return (
    <section
      id={"methode"}
      className={`${style.cours} ${mainStyle.section} d-flex f-column aic jcc`}
    >
      <div id="Présentation" className={`${style.intro} d-flex f-column aic`}>
        <h2
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="300"
        >
          LES COURS ET MÉTHODES
        </h2>
        <ShortDivider />
        <p
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="300"
        >
          Accédez à la méthode <strong>Continuum</strong> et aux cours qui vont
          avec et <strong>progressez toujours plus !</strong> 😉
        </p>
      </div>
      <div
        id="Phrase"
        className={`${style.intro}`}
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-delay="300"
      >
        {RandomSentence()}
      </div>
      <div
        id="Méthodes"
        className={`${style.methodes} d-flex aic jcse`}
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="300"
      >
        <article className={`${style.article} d-flex aic`}>
          <NavLink to={"/cours/guitare"} className={`${style.img}`}>
            <img
              src={methodeguitare}
              alt="Continuum guitare - par Frédéric Szymañski"
            />
          </NavLink>
        </article>
        <article className={`${style.article} d-flex aic`}>
          <NavLink to={"/cours/basse"} className={`${style.img}`}>
            <img
              src={methodebasse}
              alt="Continuum basse - par Frédéric Szymañski"
            />
          </NavLink>
        </article>
      </div>
      <Scroller section={"interactions"} text={"Progressez aussi en ligne !"} />
    </section>
  );
}
