const URL_API = process.env.REACT_APP_URL_API_CONTACT;

export async function sendMail(values) {
  try {
    const response = await fetch(`${URL_API}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
    if (response.ok) {
      const responseFromBack = await response.json();
      return responseFromBack;
    }
  } catch (error) {
    console.error(error);
  }
}
