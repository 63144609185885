import { rate } from "../../../../../json/rate/rate";

let randomRate = [];

export const ProductService = {
  getProductsData() {
    function shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    const notes = rate.map((a) => a.rate);
    const moyenne = calculerMoyenne(notes);

    function calculerMoyenne(nombres) {
      let somme = 0;
      let nombreElements = nombres.length;

      for (let nombre of nombres) {
        somme += nombre;
      }

      if (nombreElements === 0) {
        return 0;
      }

      return somme / nombreElements;
    }

    const randomArray = () => {
      const test = {
        name: "see more",
        text: "Cliquez ici pour voir tous les avis !",
      };
      const shuffleObjects = [...rate];
      shuffle(shuffleObjects);

      const randomThree = shuffleObjects.slice(0, 5);
      randomRate = randomThree;
      randomRate.push(test);
    };
    randomArray();
    return randomRate;
  },

  getProducts() {
    return Promise.resolve(this.getProductsData());
  },
};
