import { Helmet } from "react-helmet";
import { rate } from "../../json/rate/rate";
import Card from "./components/card/card";
import style from "./rate.module.scss";

export default function Rate() {
  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Avis</title>
      </Helmet>
      <main className={`${style.grid}`}>
        <section className="d-flex jcc">
          <h1>LES ÉLÈVES EN PARLENT</h1>
        </section>
        <section className={`${style.rateList} d-flex aic jcse f-wrap`}>
          {rate.map((a, i) => (
            <Card data={a} key={i} />
          ))}
        </section>
      </main>
    </>
  );
}
