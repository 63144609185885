import style from "./blog.module.scss";
import Card from "./components/card/card";
import { useContext } from "react";
import { ArticlesContext } from "../../services/context/articlesContext";
import { Helmet } from "react-helmet";

export default function Blog() {
  const { blog } = useContext(ArticlesContext);

  return (
    <>
    <Helmet>
      <title>Frédéric Szymañski - Ecrits</title>
    </Helmet>
    <main className={`${style.blog} d-flex f-column aic`}>
      <section>
        <h1>ARTICLES & NEWS</h1>
      </section>
      <section className={"d-flex jcse f-wrap"}>
        {blog.map(
          (a, i) =>
            a.category !== "FAQ" && a.enable && <Card data={a} key={i} />
        )}
      </section>
    </main>
        </>
  );
}
