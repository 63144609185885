import style from "./impro.module.scss";
import imageAxis1 from "./img/1.gif";
import imageAxis2 from "./img/2.gif";
import imageAxis3 from "./img/3.gif";
import imageAxis4 from "./img/4.gif";
import imageAxis5 from "./img/5.gif";
import imageAxis6 from "./img/6.gif";
import imageAxis7 from "./img/7.gif";
import imageAxis8 from "./img/8.gif";
import imageAxis9 from "./img/9.gif";
import imageAxis10 from "./img/10.gif";
import imageAxis11 from "./img/11.gif";
import imageAxis12 from "./img/12.gif";
import imageAxis13 from "./img/13.gif";
import imageAxis14 from "./img/14.gif";
import imageAxis15 from "./img/15.gif";
import imageAxis16 from "./img/16.gif";
import imageAxis17 from "./img/17.gif";
import imageAxis18 from "./img/18.gif";
import imageAxis19 from "./img/19.gif";
import imageAxis20 from "./img/20.gif";
import imageAxis21 from "./img/21.gif";
import imageAxis22 from "./img/22.gif";
import { useState } from "react";
import { Helmet } from "react-helmet";

// helper functions -----------------------------------------
function RandomTonality() {
  var ton = new Array(16);
  ton[0] = `C`;
  ton[1] = `D`;
  ton[2] = `E`;
  ton[3] = `F`;
  ton[4] = `G`;
  ton[5] = `A`;
  ton[6] = `B`;
  ton[7] = `C#`;
  ton[8] = `D#`;
  ton[9] = `F#`;
  ton[10] = `G#`;
  ton[11] = `A#`;
  ton[12] = `Db`;
  ton[13] = `Eb`;
  ton[14] = `Gb`;
  ton[15] = `Ab`;
  ton[16] = `Bb`;

  const indexTon = Math.floor(Math.random() * ton.length);

  return ton[indexTon];
}

function RandomCleanTonality() {
  var ton = new Array(11);
  ton[0] = `C`;
  ton[1] = `D`;
  ton[2] = `E`;
  ton[3] = `F`;
  ton[4] = `G`;
  ton[5] = `A`;
  ton[6] = `B`;
  ton[7] = `Db`;
  ton[8] = `Eb`;
  ton[9] = `Gb`;
  ton[10] = `Ab`;
  ton[11] = `Bb`;

  const indexTon = Math.floor(Math.random() * ton.length);

  return ton[indexTon];
}

function RandomScale(level) {
  var texte;
  if (level == null || level === 1) {
    texte = new Array(3);
    texte[0] = `majeur`;
    texte[1] = `mineur`;
    texte[2] = `pentatonique majeur`;
    texte[3] = `pentatonique mineur`;
  } else if (level === 2) {
    texte = new Array(6);
    texte[0] = "Ionien";
    texte[1] = "Dorien";
    texte[2] = "Phrygien";
    texte[3] = "Lydien";
    texte[4] = "Mixolydien";
    texte[5] = "Eolien";
    texte[6] = "Locrien";
  } else if (level === 3) {
    texte = new Array(13);
    texte[0] = "Ionien";
    texte[1] = "Dorien";
    texte[2] = "Phrygien";
    texte[3] = "Lydien";
    texte[4] = "Mixolydien";
    texte[5] = "Eolien";
    texte[6] = "Locrien";

    texte[7] = "Mineur harmonique";
    texte[8] = "Locrien becarre 6";
    texte[9] = "Majeur augmenté";
    texte[10] = "Dorien #4";
    texte[11] = "Phrygien majeur";
    texte[12] = "Lydien #2";
    texte[13] = "Superlocrien diminué";
  } else if (level === 4) {
    texte = new Array(20);
    texte[0] = "Ionien";
    texte[1] = "Dorien";
    texte[2] = "Phrygien";
    texte[3] = "Lydien";
    texte[4] = "Mixolydien";
    texte[5] = "Eolien";
    texte[6] = "Locrien";

    texte[7] = "Mineur harmonique";
    texte[8] = "Locrien becarre 6";
    texte[9] = "Majeur augmenté";
    texte[10] = "Dorien #4";
    texte[11] = "Phrygien majeur";
    texte[12] = "Lydien #2";
    texte[13] = "Superlocrien diminué";

    texte[14] = "Mineur melodique";
    texte[15] = "Dorien b2";
    texte[16] = "Lydien augmenté";
    texte[17] = "Bartók";
    texte[18] = "Mixolydien b6";
    texte[19] = "locrien becarre 2";
    texte[20] = "Altéré";
  } else if (level === 5) {
    texte = new Array(23);
    texte[0] = "Ionien";
    texte[1] = "Dorien";
    texte[2] = "Phrygien";
    texte[3] = "Lydien";
    texte[4] = "Mixolydien";
    texte[5] = "Eolien";
    texte[6] = "Locrien";

    texte[7] = "Mineur harmonique";
    texte[8] = "Locrien becarre 6";
    texte[9] = "Majeur augmenté";
    texte[10] = "Dorien #4";
    texte[11] = "Phrygien majeur";
    texte[12] = "Lydien #2";
    texte[13] = "Superlocrien diminué";

    texte[14] = "Mineur melodique";
    texte[15] = "Dorien b2";
    texte[16] = "Lydien augmenté";
    texte[17] = "Bartók";
    texte[18] = "Mixolydien b6";
    texte[19] = "locrien becarre 2";
    texte[20] = "Altéré";

    texte[21] = "Diminué 1-2";
    texte[22] = "Diminué 2-1";
    texte[23] = "Par ton";
  } else if (level === 6) {
    texte = new Array(30);
    texte[0] = "Ionien";
    texte[1] = "Dorien";
    texte[2] = "Phrygien";
    texte[3] = "Lydien";
    texte[4] = "Mixolydien";
    texte[5] = "Eolien";
    texte[6] = "Locrien";

    texte[7] = "Mineur harmonique";
    texte[8] = "Locrien becarre 6";
    texte[9] = "Majeur augmenté";
    texte[10] = "Dorien #4";
    texte[11] = "Phrygien majeur";
    texte[12] = "Lydien #2";
    texte[13] = "Superlocrien diminué";

    texte[14] = "Mineur melodique";
    texte[15] = "Dorien b2";
    texte[16] = "Lydien augmenté";
    texte[17] = "Bartók";
    texte[18] = "Mixolydien b6";
    texte[19] = "locrien becarre 2";
    texte[20] = "Altéré";

    texte[21] = "Diminué 1-2";
    texte[22] = "Diminué 2-1";
    texte[23] = "Par ton";

    texte[24] = "Majeur harmonique";
    texte[25] = "Dorien b5";
    texte[26] = "Phrygien b4";
    texte[27] = "Lydien mineur";
    texte[28] = "Mixolydien b9";
    texte[29] = "Lydien #5 #9";
    texte[30] = "Locrien bb7";
  }

  const indexTexte = Math.floor(Math.random() * texte.length);

  return texte[indexTexte];
}

function RandomMeter(level) {
  var meter;
  if (level == null || level === 1) {
    meter = new Array(2);
    meter[0] = "4/4 ";
    meter[1] = "3/4 ";
    meter[2] = "12/8 ";
  } else if (level === 2) {
    meter = new Array(4);
    meter[0] = "4/4 ";
    meter[1] = "3/4 ";
    meter[2] = "12/8 ";
    meter[3] = "6/8 ";
    meter[4] = "6/4 ";
  } else if (level === 3) {
    meter = new Array(3);
    meter[0] = "4/4 ";
    meter[1] = "3/4 ";
    meter[2] = "12/8 ";
    meter[3] = "6/8 ";
    meter[4] = "6/4 ";
    meter[5] = "5/4 ";
    meter[6] = "7/4 ";
    meter[7] = "9/4 ";
    meter[8] = "10/4 ";
  } else if (level === 4) {
    meter = new Array(3);
    meter[0] = "4/4 ";
    meter[1] = "3/4 ";
    meter[2] = "12/8 ";
    meter[3] = "6/8 ";
    meter[4] = "6/4 ";
    meter[5] = "5/4 ";
    meter[6] = "7/4 ";
    meter[7] = "9/4 ";
    meter[8] = "10/4 ";
    meter[9] = "7/8 ";
    meter[10] = "9/8 ";
    meter[11] = "11/8 ";
    meter[12] = "5/8 ";
    meter[13] = "10/8 ";
    meter[14] = "13/8 ";
    meter[15] = "14/8 ";
    meter[16] = "15/8 ";
  } else if (level >= 5) {
    meter = new Array(3);
    meter[0] = "4/4 ";
    meter[1] = "3/4 ";
    meter[2] = "12/8 ";
    meter[3] = "6/8 ";
    meter[4] = "6/4 ";
    meter[5] = "5/4 ";
    meter[6] = "7/4 ";
    meter[7] = "9/4 ";
    meter[8] = "10/4 ";
    meter[9] = "7/8 ";
    meter[10] = "9/8 ";
    meter[11] = "11/8 ";
    meter[12] = "5/8 ";
    meter[13] = "10/8 ";
    meter[14] = "13/8 ";
    meter[15] = "14/8 ";
    meter[16] = "15/8 ";
    meter[17] = "15/16 ";
    meter[18] = "17/16 ";
  }

  const meterIndex = Math.floor(Math.random() * meter.length);
  return meter[meterIndex];
}

function RandomBPM(level) {
  var tempo;
  if (level == null || level === 1) {
    tempo = new Array(6);
    tempo[0] = "60";
    tempo[1] = "65";
    tempo[2] = "70";
    tempo[3] = "75";
    tempo[4] = "80";
    tempo[5] = "85";
    tempo[6] = "90";
  } else if (level === 2) {
    tempo = new Array(13);
    tempo[0] = "60";
    tempo[1] = "65";
    tempo[2] = "70";
    tempo[3] = "75";
    tempo[4] = "80";
    tempo[5] = "85";
    tempo[6] = "90";

    tempo[7] = "95";
    tempo[8] = "100";
    tempo[9] = "105";
    tempo[10] = "110";
    tempo[11] = "115";
    tempo[12] = "120";
    tempo[13] = "125";
  } else if (level === 3) {
    tempo = new Array(20);
    tempo[0] = "60";
    tempo[1] = "65";
    tempo[2] = "70";
    tempo[3] = "75";
    tempo[4] = "80";
    tempo[5] = "85";
    tempo[6] = "90";

    tempo[7] = "95";
    tempo[8] = "100";
    tempo[9] = "105";
    tempo[10] = "110";
    tempo[11] = "115";
    tempo[12] = "120";
    tempo[13] = "125";

    tempo[14] = "130";
    tempo[15] = "135";
    tempo[16] = "140";
    tempo[17] = "145";
    tempo[18] = "150";
    tempo[19] = "155";
    tempo[20] = "160";
  } else if (level === 4) {
    tempo = new Array(27);
    tempo[0] = "60";
    tempo[1] = "65";
    tempo[2] = "70";
    tempo[3] = "75";
    tempo[4] = "80";
    tempo[5] = "85";
    tempo[6] = "90";

    tempo[7] = "95";
    tempo[8] = "100";
    tempo[9] = "105";
    tempo[10] = "110";
    tempo[11] = "115";
    tempo[12] = "120";
    tempo[13] = "125";

    tempo[14] = "130";
    tempo[15] = "135";
    tempo[16] = "140";
    tempo[17] = "145";
    tempo[18] = "150";
    tempo[19] = "155";
    tempo[20] = "160";

    tempo[21] = "165";
    tempo[22] = "170";
    tempo[23] = "175";
    tempo[24] = "180";
    tempo[25] = "185";
    tempo[26] = "190";
    tempo[27] = "195";
  } else if (level >= 5) {
    tempo = new Array(30);
    tempo[0] = "60";
    tempo[1] = "65";
    tempo[2] = "70";
    tempo[3] = "75";
    tempo[4] = "80";
    tempo[5] = "85";
    tempo[6] = "90";

    tempo[7] = "95";
    tempo[8] = "100";
    tempo[9] = "105";
    tempo[10] = "110";
    tempo[11] = "115";
    tempo[12] = "120";
    tempo[13] = "125";

    tempo[14] = "130";
    tempo[15] = "135";
    tempo[16] = "140";
    tempo[17] = "145";
    tempo[18] = "150";
    tempo[19] = "155";
    tempo[20] = "160";

    tempo[21] = "165";
    tempo[22] = "170";
    tempo[23] = "175";
    tempo[24] = "180";
    tempo[25] = "185";
    tempo[26] = "190";
    tempo[27] = "195";

    tempo[28] = "200";
    tempo[29] = "205";
    tempo[30] = "210";
  }

  const tempoIndex = Math.floor(Math.random() * tempo.length);
  return tempo[tempoIndex];
}
function RandomAxis(level) {
  var axis;
  if (level == null || level === 1) {
    axis = new Array(0);
    axis[0] = "";
  } else {
    axis = new Array(89);
    axis[0] = "Motifs en secondes";
    axis[1] = "Motifs en tierces";
    axis[2] = "Motifs en quartes";
    axis[3] = "Motifs en quintes";
    axis[4] = "Motifs en sixtes";
    axis[5] = "Motifs en septiemes";
    axis[6] = "Motifs en octaves";

    axis[7] = "Triades";
    axis[8] = "arpeges (4 sons)";
    axis[9] = "arpeges de 7 sons";
    axis[10] = "Double-notes";
    axis[11] = "rondes et silences";
    axis[12] = "blanches et silences";
    axis[13] = "noires et silences";

    axis[14] = "croches et silences";
    axis[15] = "double-croches et silences";
    axis[16] = "triolets et silences";
    axis[17] = "jeu en octaves (double-notes)";
    axis[18] = "jeu en accords de 3 sons";
    axis[19] = "jeu en accords de 4 sons";
    axis[20] = "jeu en accords de quartes";
    axis[21] = "chromatismes";

    axis[22] = <img className={`${style.axisImg}`} src={imageAxis1} alt="" />;
    axis[23] = <img className={`${style.axisImg}`} src={imageAxis2} alt="" />;
    axis[24] = <img className={`${style.axisImg}`} src={imageAxis3} alt="" />;
    axis[25] = <img className={`${style.axisImg}`} src={imageAxis4} alt="" />;
    axis[26] = <img className={`${style.axisImg}`} src={imageAxis5} alt="" />;
    axis[27] = <img className={`${style.axisImg}`} src={imageAxis6} alt="" />;
    axis[28] = <img className={`${style.axisImg}`} src={imageAxis7} alt="" />;

    axis[29] = <img className={`${style.axisImg}`} src={imageAxis8} alt="" />;
    axis[30] = <img className={`${style.axisImg}`} src={imageAxis9} alt="" />;
    axis[31] = <img className={`${style.axisImg}`} src={imageAxis10} alt="" />;
    axis[32] = <img className={`${style.axisImg}`} src={imageAxis11} alt="" />;
    axis[33] = <img className={`${style.axisImg}`} src={imageAxis12} alt="" />;
    axis[34] = <img className={`${style.axisImg}`} src={imageAxis13} alt="" />;
    axis[35] = <img className={`${style.axisImg}`} src={imageAxis14} alt="" />;

    axis[36] = <img className={`${style.axisImg}`} src={imageAxis15} alt="" />;
    axis[37] = <img className={`${style.axisImg}`} src={imageAxis16} alt="" />;
    axis[38] = <img className={`${style.axisImg}`} src={imageAxis17} alt="" />;
    axis[39] = <img className={`${style.axisImg}`} src={imageAxis18} alt="" />;
    axis[40] = <img className={`${style.axisImg}`} src={imageAxis19} alt="" />;
    axis[41] = <img className={`${style.axisImg}`} src={imageAxis20} alt="" />;
    axis[42] = <img className={`${style.axisImg}`} src={imageAxis21} alt="" />;
    axis[43] = <img className={`${style.axisImg}`} src={imageAxis22} alt="" />;

    axis[44] = "1 2 ";
    axis[45] = "1 2 3";
    axis[46] = "1 2 3 4";
    axis[47] = "1 2 3 4 5";

    axis[48] = "2 1";
    axis[49] = "3 2 1";
    axis[50] = "4 3 2 1";
    axis[51] = "5 4 3 2 1";

    axis[52] = "1 2 1";
    axis[53] = "1 3 4";
    axis[54] = "1 2 4";
    axis[55] = "1 2 5";
    axis[56] = "1 2 6";
    axis[57] = "1 4 5";
    axis[58] = "1 4 7";
    axis[59] = "1 5 6";
    axis[60] = "1 6 7";
    axis[61] = "1 2 5 6";
    axis[62] = "1 2 3 5";
    axis[63] = "1 2 4 5";
    axis[64] = "1 2 4 7";
    axis[65] = "1 3 4 5";
    axis[66] = "1 3 4 7";
    axis[67] = " 1 4 5 6";
    axis[68] = "1 4 5 7";
    axis[69] = "1 4 6 7";
    axis[70] = "1 5 6 7";

    axis[71] = "2 1 2";
    axis[72] = "4 3 1";
    axis[73] = "4 2 1";
    axis[74] = "5 2 1";
    axis[75] = "6 2 1";
    axis[76] = "5 4 1";
    axis[77] = "7 4 1";
    axis[78] = "6 5 1";
    axis[79] = "7 6 1";
    axis[80] = "6 5 2 1";
    axis[81] = "5 3 2 1";
    axis[82] = "5 4 2 1";
    axis[83] = "7 4 2 1";
    axis[84] = "5 4 3 1";
    axis[85] = "7 4 3 1";
    axis[86] = "6 5 4 1";
    axis[87] = "7 5 4 1";
    axis[88] = "7 6 4 1";
    axis[89] = "7 6 5 1";
  }
  const axisIndex = Math.floor(Math.random() * axis.length);
  return axis[axisIndex];
}

function RandomStructure(level) {
  var structure;
  if (level === 4) {
    structure = new Array(2);
    structure[0] = `16 mesures par mode `;
    structure[1] = `8 mesures par mode `;
    structure[2] = `4 mesures par mode `;
  } else if (level === 5) {
    structure = new Array(5);
    structure[0] = `16 mesures par mode `;
    structure[1] = `8 mesures par mode `;
    structure[2] = `4 mesures par mode `;

    structure[3] = `6 mesures par mode `;
    structure[4] = `7 mesures par mode `;
    structure[5] = `5 mesure par mode `;
  } else if (level === 6) {
    structure = new Array(8);
    structure[0] = `16 mesures par mode `;
    structure[1] = `8 mesures par mode `;
    structure[2] = `4 mesures par mode `;

    structure[3] = `6 mesures par mode `;
    structure[4] = `7 mesures par mode `;
    structure[5] = `5 mesure par mode `;

    structure[6] = `3 mesures par mode `;
    structure[7] = `2 mesures par mode `;
    structure[8] = `1 mesure par mode `;
  }
  const indexStructure = Math.floor(Math.random() * structure.length);

  return structure[indexStructure];
}

function RandomChord(level) {
  var chord;
  if (level == null || level === 1) {
    chord = new Array(1);
    chord[0] = "";
    chord[1] = "m";
  } else if (level === 2) {
    chord = new Array(7);
    chord[0] = "";
    chord[1] = "m";
    chord[2] = "Δ";
    chord[3] = "m7";
    chord[4] = "m7b5";
    chord[5] = "°7";
    chord[6] = "°";
    chord[7] = "7";
  } else if (level === 3) {
    chord = new Array(15);
    chord[0] = "";
    chord[1] = "m";
    chord[2] = "Δ";
    chord[3] = "m7";
    chord[4] = "m7b5";
    chord[5] = "°7";
    chord[6] = "°";
    chord[7] = "7";
    chord[8] = "#5";
    chord[9] = "Δ#5";
    chord[10] = "7#5";
    chord[11] = "sus4";
    chord[12] = "7sus4";
    chord[13] = "6";
    chord[14] = "m6";
    chord[15] = "mΔ";
  } else if (level === 4) {
    chord = new Array(28);
    chord[0] = "";
    chord[1] = "m";
    chord[2] = "Δ";
    chord[3] = "m7";
    chord[4] = "m7b5";
    chord[5] = "°7";
    chord[6] = "°";
    chord[7] = "7";
    chord[8] = "#5";
    chord[9] = "Δ#5";
    chord[10] = "7#5";
    chord[11] = "sus4";
    chord[12] = "7sus4";
    chord[13] = "6";
    chord[14] = "m6";
    chord[15] = "mΔ";
    chord[16] = "m9";
    chord[17] = "m11";
    chord[18] = "m13";
    chord[19] = "9";
    chord[20] = "7#11";
    chord[21] = "9#11";
    chord[22] = "13";
    chord[23] = "13#11";
    chord[24] = "Δ9";
    chord[25] = "Δ13";
    chord[26] = "Δ#11";
    chord[27] = "Δ13#11";
    chord[28] = "m7b5add11";
  } else if (level === 5) {
    chord = new Array(40);
    chord[0] = "";
    chord[1] = "m";
    chord[2] = "Δ";
    chord[3] = "m7";
    chord[4] = "m7b5";
    chord[5] = "°7";
    chord[6] = "°";
    chord[7] = "7";
    chord[8] = "#5";
    chord[9] = "Δ#5";
    chord[10] = "7#5";
    chord[11] = "sus4";
    chord[12] = "7sus4";
    chord[13] = "6";
    chord[14] = "m6";
    chord[15] = "mΔ";
    chord[16] = "m9";
    chord[17] = "m11";
    chord[18] = "m13";
    chord[19] = "9";
    chord[20] = "7#11";
    chord[21] = "9#11";
    chord[22] = "13";
    chord[23] = "13#11";
    chord[24] = "Δ9";
    chord[25] = "Δ13";
    chord[26] = "Δ#11";
    chord[27] = "Δ13#11";
    chord[28] = "m7b5add11";
    chord[29] = "6/9";
    chord[30] = "m6/9";
    chord[31] = "7#9";
    chord[32] = "7b9";
    chord[33] = "7alt";
    chord[34] = "7b9#9";
    chord[35] = "9sus4";
    chord[36] = "7sus4b9";
    chord[37] = "9#5";
    chord[38] = "7b9#5";
    chord[39] = "13#9";
    chord[40] = "Δ#9";
  } else if (level === 6) {
    chord = new Array(60);
    chord[0] = "";
    chord[1] = "m";
    chord[2] = "Δ";
    chord[3] = "m7";
    chord[4] = "m7b5";
    chord[5] = "°7";
    chord[6] = "°";
    chord[7] = "7";
    chord[8] = "#5";
    chord[9] = "Δ#5";
    chord[10] = "7#5";
    chord[11] = "sus4";
    chord[12] = "7sus4";
    chord[13] = "6";
    chord[14] = "m6";
    chord[15] = "mΔ";
    chord[16] = "m9";
    chord[17] = "m11";
    chord[18] = "m13";
    chord[19] = "9";
    chord[20] = "7#11";
    chord[21] = "9#11";
    chord[22] = "13";
    chord[23] = "13#11";
    chord[24] = "Δ9";
    chord[25] = "Δ13";
    chord[26] = "Δ#11";
    chord[27] = "Δ13#11";
    chord[28] = "m7b5add11";
    chord[29] = "6/9";
    chord[30] = "m6/9";
    chord[31] = "7#9";
    chord[32] = "7b9";
    chord[33] = "7alt";
    chord[34] = "7b9#9";
    chord[35] = "9sus4";
    chord[36] = "7sus4b9";
    chord[37] = "9#5";
    chord[38] = "7b9#5";
    chord[39] = "13#9";
    chord[40] = "Δ#9";
    chord[41] = "13b9";
    chord[42] = "7#9#5";
    chord[43] = "7#11b9";
    chord[44] = "add9";
    chord[45] = "Δadd13";
    chord[46] = "madd9";
    chord[47] = "m7add11";
    chord[48] = "m7add13";
    chord[49] = "mΔ9";
    chord[50] = "m9b5";
    chord[51] = "m11b5";
    chord[52] = "°7addΔ";
    chord[53] = "13sus4";
    chord[54] = "Δb5";
    chord[55] = "7b5";
    chord[56] = "9b5";
    chord[57] = "7b9b5";
    chord[58] = "7#11#9";
    chord[59] = "13b5";
    chord[60] = "7sus4b9";
  }
  const indexChord = Math.floor(Math.random() * chord.length);

  return chord[indexChord];
}

function RandomTwoFiveOneSix() {
  const tonalite = RandomCleanTonality();
  var accordII;
  var accordV;
  var accordI;
  var accordVI;

  if (tonalite === "C") {
    accordII = "D";
    accordV = "G";
    accordI = "C";
    accordVI = "A";
  } else if (tonalite === "D") {
    accordII = "E";
    accordV = "A";
    accordI = "D";
    accordVI = "B";
  } else if (tonalite === "E") {
    accordII = "F#";
    accordV = "B";
    accordI = "E";
    accordVI = "C#";
  } else if (tonalite === "F") {
    accordII = "G";
    accordV = "C";
    accordI = "F";
    accordVI = "D";
  } else if (tonalite === "G") {
    accordII = "A";
    accordV = "D";
    accordI = "G";
    accordVI = "E";
  } else if (tonalite === "A") {
    accordII = "B";
    accordV = "E";
    accordI = "A";
    accordVI = "F#";
  } else if (tonalite === "B") {
    accordII = "C#";
    accordV = "F#";
    accordI = "B";
    accordVI = "G#";
  } else if (tonalite === "Db") {
    accordII = "Eb";
    accordV = "Ab";
    accordI = "Db";
    accordVI = "Bb";
  } else if (tonalite === "Eb") {
    accordII = "F";
    accordV = "Bb";
    accordI = "Eb";
    accordVI = "C";
  } else if (tonalite === "Gb") {
    accordII = "Ab";
    accordV = "Db";
    accordI = "Gb";
    accordVI = "Eb";
  } else if (tonalite === "Ab") {
    accordII = "Bb";
    accordV = "Eb";
    accordI = "Ab";
    accordVI = "F";
  } else if (tonalite === "Bb") {
    accordII = "C";
    accordV = "F";
    accordI = "Bb";
    accordVI = "G";
  }

  var IIcolor = new Array(12);
  IIcolor[0] = "m7";
  IIcolor[1] = "m9";
  IIcolor[2] = "m11";
  IIcolor[3] = "m13";
  IIcolor[4] = "m7b5";
  IIcolor[5] = "7sus4";
  IIcolor[6] = "9sus4";
  IIcolor[7] = "13sus4";
  IIcolor[8] = "m7";
  IIcolor[9] = "m9";
  IIcolor[10] = "m7";
  IIcolor[11] = "m7";
  IIcolor[12] = "m13";

  const indexIIcolor = Math.floor(Math.random() * IIcolor.length);
  const couleurII = IIcolor[indexIIcolor];

  var Vcolor = new Array(18);
  Vcolor[0] = "7";
  Vcolor[1] = "9";
  Vcolor[2] = "13";
  Vcolor[3] = "7b9";
  Vcolor[4] = "7#9";
  Vcolor[5] = "7b5";
  Vcolor[6] = "7#5";
  Vcolor[7] = "7#5b9";
  Vcolor[8] = "7#5#9";
  Vcolor[9] = "13#9";
  Vcolor[10] = "13b9";
  Vcolor[11] = "7#11";
  Vcolor[12] = "9#11";
  Vcolor[13] = "13#11";
  Vcolor[14] = "7";
  Vcolor[15] = "7";
  Vcolor[16] = "7";
  Vcolor[17] = "9";
  Vcolor[18] = "13";

  const indexVcolor = Math.floor(Math.random() * Vcolor.length);
  const couleurV = Vcolor[indexVcolor];

  var Icolor = new Array(6);
  Icolor[0] = "Δ";
  Icolor[1] = "6";
  Icolor[2] = "6/9";
  Icolor[3] = "Δ9";
  Icolor[4] = "Δ13";
  Icolor[5] = "Δ";
  Icolor[6] = "Δ9";

  const indexIcolor = Math.floor(Math.random() * Icolor.length);
  const couleurI = Icolor[indexIcolor];

  var VIcolor = new Array(18);
  VIcolor[0] = "m7";
  VIcolor[1] = "7";
  VIcolor[2] = "13";
  VIcolor[3] = "7b9";
  VIcolor[4] = "7#9";
  VIcolor[5] = "7b5";
  VIcolor[6] = "7#5";
  VIcolor[7] = "7#5b9";
  VIcolor[8] = "7#5#9";
  VIcolor[9] = "13#9";
  VIcolor[10] = "13b9";
  VIcolor[11] = "7#11";
  VIcolor[12] = "9#11";
  VIcolor[13] = "13#11";
  VIcolor[14] = "7";
  VIcolor[15] = "7";
  VIcolor[16] = "7";
  VIcolor[17] = "7#9";
  VIcolor[18] = "m7";

  const indexVIcolor = Math.floor(Math.random() * VIcolor.length);
  const couleurVI = VIcolor[indexVIcolor];

  return [
    accordII + couleurII,
    accordV + couleurV,
    accordI + couleurI,
    accordVI + couleurVI,
  ];
}

function RandomTeenTown() {
  const tonalite = RandomCleanTonality();
  var accordI;
  var accordII;
  var accordIII;
  var accordIV;

  if (tonalite === "C") {
    accordI = "C13";
    accordII = "A13";
    accordIII = "F13";
    accordIV = "D13";
  } else if (tonalite === "D") {
    accordI = "D13";
    accordII = "B13";
    accordIII = "G13";
    accordIV = "E13";
  } else if (tonalite === "E") {
    accordI = "E13";
    accordII = "Db13";
    accordIII = "A13";
    accordIV = "F#13";
  } else if (tonalite === "F") {
    accordI = "F13";
    accordII = "D13";
    accordIII = "Bb13";
    accordIV = "G13";
  } else if (tonalite === "G") {
    accordI = "G13";
    accordII = "E13";
    accordIII = "C13";
    accordIV = "A13";
  } else if (tonalite === "A") {
    accordI = "A13";
    accordII = "F#13";
    accordIII = "D13";
    accordIV = "B13";
  } else if (tonalite === "B") {
    accordI = "B13";
    accordII = "Ab13";
    accordIII = "E13";
    accordIV = "Db13";
  } else if (tonalite === "Db") {
    accordI = "Db13";
    accordII = "Bb13";
    accordIII = "F#13";
    accordIV = "Eb13";
  } else if (tonalite === "Eb") {
    accordI = "Eb13";
    accordII = "C13";
    accordIII = "Ab13";
    accordIV = "F13";
  } else if (tonalite === "Gb") {
    accordI = "F#13";
    accordII = "Eb13";
    accordIII = "B13";
    accordIV = "Ab13";
  } else if (tonalite === "Ab") {
    accordI = "Ab13";
    accordII = "F13";
    accordIII = "Db13";
    accordIV = "Bb13";
  } else if (tonalite === "Bb") {
    accordI = "Bb13";
    accordII = "G13";
    accordIII = "Eb13";
    accordIV = "C13";
  }

  return [accordI, accordII, accordIII, accordIV];
}

// actual functions --------------------------------------------
function ImproGammes(level) {
  var secondScale = "";
  if (level > 3) {
    secondScale =
      "et " +
      RandomTonality() +
      " " +
      RandomScale(level) +
      ", " +
      RandomStructure(level);
  }
  var axisSentence = "";
  var conditionnedBr = "";
  if (level > 1) {
    axisSentence = "Axer l'improvisation sur :";
    conditionnedBr = <br />;
  }
  return (
    <table className={`${style.TableauGammes}`}>
      <tr className={`${style.TableauGammes}`}>
        <td className={`${style.TableauGammes}`}>
          <b>
            {RandomTonality()} {RandomScale(level)} {secondScale} en{" "}
            {RandomMeter(level)} à {RandomBPM(level)} BPM
          </b>
          <br />
          {axisSentence}
          {conditionnedBr}
          <b>{RandomAxis(level)}</b>
        </td>
      </tr>
    </table>
  );
}

function ImproVoicings(level) {
  return (
    <table className={`${style.PetiteGrilleAccords}`}>
      <tr className={`${style.PetiteGrilleAccords}`}>
        <td className={`${style.PetiteGrilleAccords}`}>
          {RandomTonality()}
          {RandomChord(level)}
        </td>
        <td className={`${style.PetiteGrilleAccords}`}>
          {RandomTonality()}
          {RandomChord(level)}
        </td>
      </tr>
      <tr className={`${style.PetiteGrilleAccords}`}>
        <td className={`${style.PetiteGrilleAccords}`}>
          {RandomTonality()}
          {RandomChord(level)}
        </td>
        <td className={`${style.PetiteGrilleAccords}`}>
          {RandomTonality()}
          {RandomChord(level)}
        </td>
      </tr>
    </table>
  );
}

function ImproIIVIVIColors() {
  const [two, five, one, six] = RandomTwoFiveOneSix();
  return (
    <table className={`${style.PetiteGrilleAccords}`}>
      <tr className={`${style.PetiteGrilleAccords}`}>
        <td className={`${style.PetiteGrilleAccords}`}>{two}</td>
        <td className={`${style.PetiteGrilleAccords}`}>{five}</td>
      </tr>
      <tr className={`${style.PetiteGrilleAccords}`}>
        <td className={`${style.PetiteGrilleAccords}`}>{one}</td>
        <td className={`${style.PetiteGrilleAccords}`}>{six}</td>
      </tr>
    </table>
  );
}

function RandomZetaReticuli() {
  const tonalite = RandomCleanTonality();
  var tableau;

  if (tonalite === "C") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Gb dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Gb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Db dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>Ab dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>Gb dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Db lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "D") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Ab dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Ab dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>Ab dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "E") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Ab min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>Gb dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Gb dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "F") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F# dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Ab lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>C# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F# lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "G") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Ab min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G# dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>Db dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G# lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "A") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "B") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>Db dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Db dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Db") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Gb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Gb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Gb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C# maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Eb") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C# éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Ab lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Ab min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Gb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Gb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>F dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Ab min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Gb") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>G# dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Ab dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>F# maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Ab") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>F# éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>D dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>A lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>C phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>A min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G# min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>G# maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Bb") {
    tableau = (
      <table className={`${style.GrandeGrilleAccords}`}>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>G# éolien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>E dorien #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B dorien b5</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Db dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>F# dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>E dim 2-1</td>
          <td className={`${style.GrandeGrilleAccords}`}>C dim 1-2</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>B lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D phrygien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>C dorien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>D phrygien majeur</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Eb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B lydien</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>B min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb dim 1-2</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>Bb min mélo #4</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
        <tr className={`${style.GrandeGrilleAccords}`}>
          <td className={`${style.GrandeGrilleAccords}`}>Bb maj harmo</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
          <td className={`${style.GrandeGrilleAccords}`}>%</td>
        </tr>
      </table>
    );
  }

  return tableau;
}

function ImproTeenTown() {
  const [two, five, one, six] = RandomTeenTown();
  return (
    <table className={`${style.PetiteGrilleAccords}`}>
      <tr className={`${style.PetiteGrilleAccords}`}>
        <td className={`${style.PetiteGrilleAccords}`}>{two}</td>
        <td className={`${style.PetiteGrilleAccords}`}>{five}</td>
      </tr>
      <tr className={`${style.PetiteGrilleAccords}`}>
        <td className={`${style.PetiteGrilleAccords}`}>{one}</td>
        <td className={`${style.PetiteGrilleAccords}`}>{six}</td>
      </tr>
    </table>
  );
}

function RandomGiantSteps() {
  const tonalite = RandomCleanTonality();
  var tableau;

  if (tonalite === "C") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>CΔ Eb7</td>
          <td className={`${style.GrilleAccords}`}>AbΔ B7</td>
          <td className={`${style.GrilleAccords}`}>EΔ</td>
          <td className={`${style.GrilleAccords}`}>Bbm7 Eb7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AbΔ B7</td>
          <td className={`${style.GrilleAccords}`}>EΔ G7</td>
          <td className={`${style.GrilleAccords}`}>CΔ</td>
          <td className={`${style.GrilleAccords}`}>F#m7 B7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EΔ</td>
          <td className={`${style.GrilleAccords}`}>Bbm7 Eb7</td>
          <td className={`${style.GrilleAccords}`}>AbΔ</td>
          <td className={`${style.GrilleAccords}`}>Dm7 G7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>CΔ</td>
          <td className={`${style.GrilleAccords}`}>F#m7 B7</td>
          <td className={`${style.GrilleAccords}`}>EΔ</td>
          <td className={`${style.GrilleAccords}`}>Dm7 G7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "D") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DΔ F7</td>
          <td className={`${style.GrilleAccords}`}>BbΔ Db7</td>
          <td className={`${style.GrilleAccords}`}>GbΔ</td>
          <td className={`${style.GrilleAccords}`}>Cm7 F7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BbΔ Db7</td>
          <td className={`${style.GrilleAccords}`}>GbΔ A7</td>
          <td className={`${style.GrilleAccords}`}>DΔ</td>
          <td className={`${style.GrilleAccords}`}>Abm7 Db7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GbΔ</td>
          <td className={`${style.GrilleAccords}`}>Cm7 F7</td>
          <td className={`${style.GrilleAccords}`}>BbΔ</td>
          <td className={`${style.GrilleAccords}`}>Em7 A7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DΔ</td>
          <td className={`${style.GrilleAccords}`}>Abm7 Db7</td>
          <td className={`${style.GrilleAccords}`}>GbΔ</td>
          <td className={`${style.GrilleAccords}`}>Em7 A7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "E") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EΔ G7</td>
          <td className={`${style.GrilleAccords}`}>CΔ Eb7</td>
          <td className={`${style.GrilleAccords}`}>AbΔ</td>
          <td className={`${style.GrilleAccords}`}>Dm7 G7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>CΔ Eb7</td>
          <td className={`${style.GrilleAccords}`}>AbΔ B7</td>
          <td className={`${style.GrilleAccords}`}>EΔ</td>
          <td className={`${style.GrilleAccords}`}>Bbm7 Eb7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AbΔ</td>
          <td className={`${style.GrilleAccords}`}>Dm7 G7</td>
          <td className={`${style.GrilleAccords}`}>CΔ</td>
          <td className={`${style.GrilleAccords}`}>F#m7 B7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EΔ</td>
          <td className={`${style.GrilleAccords}`}>Bbm7 Eb7</td>
          <td className={`${style.GrilleAccords}`}>AbΔ</td>
          <td className={`${style.GrilleAccords}`}>F#m7 B7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "F") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>FΔ Ab7</td>
          <td className={`${style.GrilleAccords}`}>DbΔ E7</td>
          <td className={`${style.GrilleAccords}`}>AΔ</td>
          <td className={`${style.GrilleAccords}`}>Ebm7 Ab7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DbΔ E7</td>
          <td className={`${style.GrilleAccords}`}>AΔ C7</td>
          <td className={`${style.GrilleAccords}`}>FΔ</td>
          <td className={`${style.GrilleAccords}`}>Bm7 E7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AΔ</td>
          <td className={`${style.GrilleAccords}`}>Ebm7 Ab7</td>
          <td className={`${style.GrilleAccords}`}>DbΔ</td>
          <td className={`${style.GrilleAccords}`}>Gm7 C7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>FΔ</td>
          <td className={`${style.GrilleAccords}`}>Bm7 E7</td>
          <td className={`${style.GrilleAccords}`}>AΔ</td>
          <td className={`${style.GrilleAccords}`}>Gm7 C7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "G") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GΔ Bb7</td>
          <td className={`${style.GrilleAccords}`}>EbΔ F#7</td>
          <td className={`${style.GrilleAccords}`}>BΔ</td>
          <td className={`${style.GrilleAccords}`}>Fm7 Bb7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EbΔ F#7</td>
          <td className={`${style.GrilleAccords}`}>BΔ D7</td>
          <td className={`${style.GrilleAccords}`}>GΔ</td>
          <td className={`${style.GrilleAccords}`}>C#m7 F#7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BΔ</td>
          <td className={`${style.GrilleAccords}`}>Fm7 Bb7</td>
          <td className={`${style.GrilleAccords}`}>EbΔ</td>
          <td className={`${style.GrilleAccords}`}>Am7 D7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GΔ</td>
          <td className={`${style.GrilleAccords}`}>C#m7 F#7</td>
          <td className={`${style.GrilleAccords}`}>BΔ</td>
          <td className={`${style.GrilleAccords}`}>Am7 D7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "A") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AΔ C7</td>
          <td className={`${style.GrilleAccords}`}>FΔ Ab7</td>
          <td className={`${style.GrilleAccords}`}>DbΔ</td>
          <td className={`${style.GrilleAccords}`}>Gm7 C7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>FΔ Ab7</td>
          <td className={`${style.GrilleAccords}`}>DbΔ E7</td>
          <td className={`${style.GrilleAccords}`}>AΔ</td>
          <td className={`${style.GrilleAccords}`}>Ebm7 Ab7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DbΔ</td>
          <td className={`${style.GrilleAccords}`}>Gm7 C7</td>
          <td className={`${style.GrilleAccords}`}>FΔ</td>
          <td className={`${style.GrilleAccords}`}>Bm7 E7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AΔ</td>
          <td className={`${style.GrilleAccords}`}>Ebm7 Ab7</td>
          <td className={`${style.GrilleAccords}`}>DbΔ</td>
          <td className={`${style.GrilleAccords}`}>Bm7 E7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "B") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BΔ D7</td>
          <td className={`${style.GrilleAccords}`}>GΔ Bb7</td>
          <td className={`${style.GrilleAccords}`}>EbΔ</td>
          <td className={`${style.GrilleAccords}`}>Am7 D7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GΔ Bb7</td>
          <td className={`${style.GrilleAccords}`}>EbΔ F#7</td>
          <td className={`${style.GrilleAccords}`}>BΔ</td>
          <td className={`${style.GrilleAccords}`}>Fm7 Bb7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EbΔ</td>
          <td className={`${style.GrilleAccords}`}>Am7 D7</td>
          <td className={`${style.GrilleAccords}`}>GΔ</td>
          <td className={`${style.GrilleAccords}`}>C#m7 F#7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BΔ</td>
          <td className={`${style.GrilleAccords}`}>Fm7 Bb7</td>
          <td className={`${style.GrilleAccords}`}>EbΔ</td>
          <td className={`${style.GrilleAccords}`}>C#m7 F#7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Db") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DbΔ E7</td>
          <td className={`${style.GrilleAccords}`}>AΔ C7</td>
          <td className={`${style.GrilleAccords}`}>FΔ</td>
          <td className={`${style.GrilleAccords}`}>Bm7 E7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AΔ C7</td>
          <td className={`${style.GrilleAccords}`}>FΔ Ab7</td>
          <td className={`${style.GrilleAccords}`}>DbΔ</td>
          <td className={`${style.GrilleAccords}`}>Gm7 C7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>FΔ</td>
          <td className={`${style.GrilleAccords}`}>Bm7 E7</td>
          <td className={`${style.GrilleAccords}`}>AΔ</td>
          <td className={`${style.GrilleAccords}`}>Ebm7 Ab7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DbΔ</td>
          <td className={`${style.GrilleAccords}`}>Gm7 C7</td>
          <td className={`${style.GrilleAccords}`}>FΔ</td>
          <td className={`${style.GrilleAccords}`}>Ebm7 Ab7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Eb") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EbΔ F#7</td>
          <td className={`${style.GrilleAccords}`}>BΔ D7</td>
          <td className={`${style.GrilleAccords}`}>GΔ</td>
          <td className={`${style.GrilleAccords}`}>C#m7 F#7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BΔ D7</td>
          <td className={`${style.GrilleAccords}`}>GΔ Bb7</td>
          <td className={`${style.GrilleAccords}`}>EbΔ</td>
          <td className={`${style.GrilleAccords}`}>Am7 D7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GΔ</td>
          <td className={`${style.GrilleAccords}`}>C#m7 F#7</td>
          <td className={`${style.GrilleAccords}`}>BΔ</td>
          <td className={`${style.GrilleAccords}`}>Fm7 Bb7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EbΔ</td>
          <td className={`${style.GrilleAccords}`}>Am7 D7</td>
          <td className={`${style.GrilleAccords}`}>GΔ</td>
          <td className={`${style.GrilleAccords}`}>Fm7 Bb7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Gb") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GbΔ A7</td>
          <td className={`${style.GrilleAccords}`}>DΔ F7</td>
          <td className={`${style.GrilleAccords}`}>BbΔ</td>
          <td className={`${style.GrilleAccords}`}>Em7 A7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DΔ F7</td>
          <td className={`${style.GrilleAccords}`}>BbΔ Db7</td>
          <td className={`${style.GrilleAccords}`}>GbΔ</td>
          <td className={`${style.GrilleAccords}`}>Cm7 F7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BbΔ</td>
          <td className={`${style.GrilleAccords}`}>Em7 A7</td>
          <td className={`${style.GrilleAccords}`}>DΔ</td>
          <td className={`${style.GrilleAccords}`}>Abm7 Db7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GbΔ</td>
          <td className={`${style.GrilleAccords}`}>Cm7 F7</td>
          <td className={`${style.GrilleAccords}`}>BbΔ</td>
          <td className={`${style.GrilleAccords}`}>Abm7 Db7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Ab") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AbΔ B7</td>
          <td className={`${style.GrilleAccords}`}>EΔ G7</td>
          <td className={`${style.GrilleAccords}`}>CΔ</td>
          <td className={`${style.GrilleAccords}`}>F#m7 B7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>EΔ G7</td>
          <td className={`${style.GrilleAccords}`}>CΔ Eb7</td>
          <td className={`${style.GrilleAccords}`}>AbΔ</td>
          <td className={`${style.GrilleAccords}`}>Dm7 G7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>CΔ</td>
          <td className={`${style.GrilleAccords}`}>F#m7 B7</td>
          <td className={`${style.GrilleAccords}`}>EΔ</td>
          <td className={`${style.GrilleAccords}`}>Bbm7 Eb7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>AbΔ</td>
          <td className={`${style.GrilleAccords}`}>Dm7 G7</td>
          <td className={`${style.GrilleAccords}`}>CΔ</td>
          <td className={`${style.GrilleAccords}`}>Bbm7 Eb7</td>
        </tr>
      </table>
    );
  } else if (tonalite === "Bb") {
    tableau = (
      <table className={`${style.GrilleAccords}`}>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BbΔ Db7</td>
          <td className={`${style.GrilleAccords}`}>GbΔ A7</td>
          <td className={`${style.GrilleAccords}`}>DΔ</td>
          <td className={`${style.GrilleAccords}`}>Abm7 Db7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>GbΔ A7</td>
          <td className={`${style.GrilleAccords}`}>DΔ F7</td>
          <td className={`${style.GrilleAccords}`}>BbΔ</td>
          <td className={`${style.GrilleAccords}`}>Em7 A7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>DΔ</td>
          <td className={`${style.GrilleAccords}`}>Abm7 Db7</td>
          <td className={`${style.GrilleAccords}`}>GbΔ</td>
          <td className={`${style.GrilleAccords}`}>Cm7 F7</td>
        </tr>
        <tr className={`${style.GrilleAccords}`}>
          <td className={`${style.GrilleAccords}`}>BbΔ</td>
          <td className={`${style.GrilleAccords}`}>Em7 A7</td>
          <td className={`${style.GrilleAccords}`}>DΔ</td>
          <td className={`${style.GrilleAccords}`}>Cm7 F7</td>
        </tr>
      </table>
    );
  }

  return tableau;
}

// default function --------------------------------------------
export default function Impro() {
  // déclarer le useState avec la valeur "" (ou null)
  // La premiere partie du useState est la const et la deuxieme partie est la fonction abrégée qui permet de modifier le state
  const [niveau, setNiveau] = useState("");

  // On utilise une fonction fléchée pour réinitialiser le useState, puis avec un setTimeout de 0.1s on retourne à nouveau le useState
  // Cela permet de rafraichir la value lorsqu'on clic sur le même bouton
  const handleClick = (value) => {
    setNiveau("");
    setTimeout(() => {
      setNiveau(value);
    }, 100);
  };

  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Interactif : improvisation</title>
      </Helmet>
      <main className={`${style.impro} d-flex`}>
        <section>
          <h1>IMPROVISATION QUOTIDIENNE</h1>
          <p>Une bonne trentaine de minutes/jour/exercice ! 😉</p>
          <div>
            {/* On appelle la fonction fléchée avec un valeur qui modifiera la value du useState */}
            {/* utilise un console.log(niveau) pour lire la valeur du useState */}
            <h3>Gammes & modes :</h3>
            <button
              className={`${
                (niveau === "1" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("1")}
            >
              Niveau 1
            </button>

            <button
              className={`${
                (niveau === "2" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("2")}
            >
              Niveau 2
            </button>

            <button
              className={`${
                (niveau === "3" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("3")}
            >
              Niveau 3
            </button>

            <button
              className={`${
                (niveau === "4" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("4")}
            >
              Niveau 4
            </button>

            <button
              className={`${
                (niveau === "5" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("5")}
            >
              Niveau 5
            </button>

            <button
              className={`${
                (niveau === "6" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("6")}
            >
              Niveau 6
            </button>
          </div>
          <div>
            <h3>Voicings :</h3>
            <button
              className={`${
                (niveau === "7" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("7")}
            >
              Niveau 1
            </button>

            <button
              className={`${
                (niveau === "8" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("8")}
            >
              Niveau 2
            </button>

            <button
              className={`${
                (niveau === "9" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("9")}
            >
              Niveau 3
            </button>

            <button
              className={`${
                (niveau === "10" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("10")}
            >
              Niveau 4
            </button>

            <button
              className={`${
                (niveau === "11" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("11")}
            >
              Niveau 5
            </button>

            <button
              className={`${
                (niveau === "12" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("12")}
            >
              Niveau 6
            </button>

            <button
              className={`${
                (niveau === "13" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("13")}
            >
              Couleurs des II V I VI
            </button>
          </div>
          <div>
            <h3>Morceaux "cheval de bataille" dans les 12 tonalités :</h3>
            <button
              className={`${
                (niveau === "14" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("14")}
            >
              Giant Steps
            </button>

            <button
              className={`${
                (niveau === "15" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("15")}
            >
              Teen Town
            </button>

            <button
              className={`${
                (niveau === "16" && style.active) || style.inactive
              }`}
              onClick={() => handleClick("16")}
            >
              Zeta x Reticuli
            </button>
          </div>

          <br />

          {/* On interroge si la valeur de state est strictement égale à "1" pour afficher la div suivante */}
          {niveau === "1" && (
            <div>
              {ImproGammes(1)} <br />
            </div>
          )}
          {niveau === "2" && (
            <div>
              {ImproGammes(2)} <br />
            </div>
          )}
          {niveau === "3" && (
            <div>
              {ImproGammes(3)} <br />
            </div>
          )}
          {niveau === "4" && (
            <div>
              {ImproGammes(4)} <br />
            </div>
          )}
          {niveau === "5" && (
            <div>
              {ImproGammes(5)} <br />
            </div>
          )}
          {niveau === "6" && (
            <div>
              {ImproGammes(6)} <br />
            </div>
          )}
          {niveau === "7" && (
            <div>
              {ImproVoicings(1)} <br />
            </div>
          )}
          {niveau === "8" && (
            <div>
              {ImproVoicings(2)} <br />
            </div>
          )}
          {niveau === "9" && (
            <div>
              {ImproVoicings(3)} <br />
            </div>
          )}
          {niveau === "10" && (
            <div>
              {ImproVoicings(4)} <br />
            </div>
          )}
          {niveau === "11" && (
            <div>
              {ImproVoicings(5)} <br />
            </div>
          )}
          {niveau === "12" && (
            <div>
              {ImproVoicings(6)} <br />
            </div>
          )}
          {niveau === "13" && (
            <div>
              {ImproIIVIVIColors()} <br />
            </div>
          )}
          {niveau === "14" && (
            <div>
              {RandomGiantSteps()} <br />
            </div>
          )}
          {niveau === "15" && (
            <div>
              {ImproTeenTown()} <br />
            </div>
          )}
          {niveau === "16" && (
            <div>
              {RandomZetaReticuli()} <br />
            </div>
          )}
          <br />
        </section>
      </main>
    </>
  );
}
