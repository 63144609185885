import { NavLink } from "react-router-dom";
import mainStyle from "../../homepage.module.scss";
import style from "./interaction.module.scss";
import Scroller from "../../../../components/scroller/scroller";
import img1 from "../../../../assests/images/impro.png";
import img2 from "../../../../assests/images/eartrainer.png";
import img3 from "../../../../assests/images/proba.png";
import ShortDivider from "../../../../components/divers/shortDiver";

export default function Interaction() {
  return (
    <section
      id="interactions"
      className={`${style.inter} ${mainStyle.section} d-flex f-column aic jcc`}
    >
      <h2
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-delay="100"
      >
        INTERACTIF
      </h2>
      <ShortDivider />
      <NavLink to={"/interactif"}>
        <p
          className={style.p}
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="100"
        >
          Progressez à travers ces trois interactions
        </p>
      </NavLink>
      <div className={`${style.controller} d-flex aic jcc`}>
        <article
          className={`${style.article} d-flex f-column`}
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="100"
        >
          <h3 className={style.title}>IMPROVISATION JOURNALIÈRE</h3>
          <NavLink to={"/interactif/improvisation"}>
            <button>
              <img src={img1} alt={img1} />
            </button>
          </NavLink>
        </article>
        <article
          className={`${style.article} d-flex f-column`}
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="100"
        >
          <h3 className={style.title}>EAR TRAINER</h3>
          <NavLink to={"/interactif/eartrainer"}>
            <button>
              <img src={img2} alt={img2} />
            </button>
          </NavLink>
        </article>
        <article
          className={`${style.article} d-flex f-column`}
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-delay="100"
        >
          <h3 className={style.title}>COMPOSITION & PROBABILITÉS</h3>
          <NavLink to={"/interactif/proba"}>
            <button>
              <img src={img3} alt={img3} />
            </button>
          </NavLink>
        </article>
      </div>
      <Scroller section={"avis"} text={"Les élèves en parlent !"} />
    </section>
  );
}
