import { useContext } from "react";
import style from "../cours.module.scss";
import { ArticlesContext } from "../../../services/context/articlesContext";
import Question from "./Question";

export default function FAQ() {
  const { FAQ } = useContext(ArticlesContext);

  return (
    <section className={`${style.FaQ}`}>
      <h2>FAQ - FOIRE AUX QUESTIONS</h2>
      <div>
        {FAQ.map((a, i) => (
          <Question key={i} data={a} />
        ))}
      </div>
    </section>
  );
}
