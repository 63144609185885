import { useReducer } from "react";
import { useContext } from "react";
import { ArticlesContext } from "../../../services/context/articlesContext";
import Card from "../../blog/components/card/card";

import A0 from "../eartrainer/sounds/A0.mp3";
import A1 from "../eartrainer/sounds/A1.mp3";
import A2 from "../eartrainer/sounds/A2.mp3";
import A3 from "../eartrainer/sounds/A3.mp3";
import A4 from "../eartrainer/sounds/A4.mp3";
import A5 from "../eartrainer/sounds/A5.mp3";
import A6 from "../eartrainer/sounds/A6.mp3";
import Amin1 from "../eartrainer/sounds/Amin1.mp3";
import Az0 from "../eartrainer/sounds/Az0.mp3";
import Az1 from "../eartrainer/sounds/Az1.mp3";
import Az2 from "../eartrainer/sounds/Az2.mp3";
import Az3 from "../eartrainer/sounds/Az3.mp3";
import Az4 from "../eartrainer/sounds/Az4.mp3";
import Az5 from "../eartrainer/sounds/Az5.mp3";
import Az6 from "../eartrainer/sounds/Az6.mp3";
import Azmin1 from "../eartrainer/sounds/Azmin1.mp3";
import B0 from "../eartrainer/sounds/B0.mp3";
import B1 from "../eartrainer/sounds/B1.mp3";
import B2 from "../eartrainer/sounds/B2.mp3";
import B3 from "../eartrainer/sounds/B3.mp3";
import B4 from "../eartrainer/sounds/B4.mp3";
import B5 from "../eartrainer/sounds/B5.mp3";
import B6 from "../eartrainer/sounds/B6.mp3";
import Bmin1 from "../eartrainer/sounds/Bmin1.mp3";
import C0 from "../eartrainer/sounds/C0.mp3";
import C1 from "../eartrainer/sounds/C1.mp3";
import C2 from "../eartrainer/sounds/C2.mp3";
import C3 from "../eartrainer/sounds/C3.mp3";
import C4 from "../eartrainer/sounds/C4.mp3";
import C5 from "../eartrainer/sounds/C5.mp3";
import C6 from "../eartrainer/sounds/C6.mp3";
import C7 from "../eartrainer/sounds/C7.mp3";
import Cz0 from "../eartrainer/sounds/Cz0.mp3";
import Cz1 from "../eartrainer/sounds/Cz1.mp3";
import Cz2 from "../eartrainer/sounds/Cz2.mp3";
import Cz3 from "../eartrainer/sounds/Cz3.mp3";
import Cz4 from "../eartrainer/sounds/Cz4.mp3";
import Cz5 from "../eartrainer/sounds/Cz5.mp3";
import Cz6 from "../eartrainer/sounds/Cz6.mp3";
import D0 from "../eartrainer/sounds/D0.mp3";
import D1 from "../eartrainer/sounds/D1.mp3";
import D2 from "../eartrainer/sounds/D2.mp3";
import D3 from "../eartrainer/sounds/D3.mp3";
import D4 from "../eartrainer/sounds/D4.mp3";
import D5 from "../eartrainer/sounds/D5.mp3";
import D6 from "../eartrainer/sounds/D6.mp3";
import Dz0 from "../eartrainer/sounds/Dz0.mp3";
import Dz1 from "../eartrainer/sounds/Dz1.mp3";
import Dz2 from "../eartrainer/sounds/Dz2.mp3";
import Dz3 from "../eartrainer/sounds/Dz3.mp3";
import Dz4 from "../eartrainer/sounds/Dz4.mp3";
import Dz5 from "../eartrainer/sounds/Dz5.mp3";
import Dz6 from "../eartrainer/sounds/Dz6.mp3";
import E0 from "../eartrainer/sounds/E0.mp3";
import E1 from "../eartrainer/sounds/E1.mp3";
import E2 from "../eartrainer/sounds/E2.mp3";
import E3 from "../eartrainer/sounds/E3.mp3";
import E4 from "../eartrainer/sounds/E4.mp3";
import E5 from "../eartrainer/sounds/E5.mp3";
import E6 from "../eartrainer/sounds/E6.mp3";
import F0 from "../eartrainer/sounds/F0.mp3";
import F1 from "../eartrainer/sounds/F1.mp3";
import F2 from "../eartrainer/sounds/F2.mp3";
import F3 from "../eartrainer/sounds/F3.mp3";
import F4 from "../eartrainer/sounds/F4.mp3";
import F5 from "../eartrainer/sounds/F5.mp3";
import F6 from "../eartrainer/sounds/F6.mp3";
import Fz0 from "../eartrainer/sounds/Fz0.mp3";
import Fz1 from "../eartrainer/sounds/Fz1.mp3";
import Fz2 from "../eartrainer/sounds/Fz2.mp3";
import Fz3 from "../eartrainer/sounds/Fz3.mp3";
import Fz4 from "../eartrainer/sounds/Fz4.mp3";
import Fz5 from "../eartrainer/sounds/Fz5.mp3";
import Fz6 from "../eartrainer/sounds/Fz6.mp3";
import G0 from "../eartrainer/sounds/G0.mp3";
import G1 from "../eartrainer/sounds/G1.mp3";
import G2 from "../eartrainer/sounds/G2.mp3";
import G3 from "../eartrainer/sounds/G3.mp3";
import G4 from "../eartrainer/sounds/G4.mp3";
import G5 from "../eartrainer/sounds/G5.mp3";
import G6 from "../eartrainer/sounds/G6.mp3";
import Gz0 from "../eartrainer/sounds/Gz0.mp3";
import Gz1 from "../eartrainer/sounds/Gz1.mp3";
import Gz2 from "../eartrainer/sounds/Gz2.mp3";
import Gz3 from "../eartrainer/sounds/Gz3.mp3";
import Gz4 from "../eartrainer/sounds/Gz4.mp3";
import Gz5 from "../eartrainer/sounds/Gz5.mp3";
import Gz6 from "../eartrainer/sounds/Gz6.mp3";

import style from "./eartrainer.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

var level = 1;
var score = 0;

// helper functions
function GetLevelSentence() {
  var sentence;

  switch (level) {
    case -6:
      sentence = "Niveau -6 : Unisson, Triton ou Octave juste.";
      break;
    case -5:
      sentence =
        "Niveau -5 : Unisson, Seconde mineure, Triton ou Quinte juste.";
      break;
    case -4:
      sentence =
        "Niveau -4 : Unisson, Quarte juste, Triton ou Septième majeure.";
      break;
    case -3:
      sentence =
        "Niveau -3 : Unisson, Tierce majeure, Triton ou Septième mineure.";
      break;
    case -2:
      sentence =
        "Niveau -2 : Unisson, Seconde majeure, Quinte juste ou Sixte majeure.";
      break;
    case -1:
      sentence =
        "Niveau -1 : Unisson, Tierce mineure, Quarte juste ou Sixte mineure.";
      break;
    case 0:
      sentence =
        "Niveau 0 : Unisson, Seconde Majeure, Quinte juste ou Quarte juste.";
      break;
    case 1:
      sentence =
        "Niveau 1 : Notes sur 1 octave, partie centrale du piano, 1 intervalle.";
      break;
    case 2:
      sentence =
        "Niveau 2 : Notes sur 1 octave, sur tout le piano, 1 intervalle.";
      break;
    case 3:
      sentence =
        "Niveau 3 : Notes sur 2 octaves, sur tout le piano, 1 intervalle.";
      break;
    case 4:
      sentence =
        "Niveau 4 : Notes sur 2 octaves, sur tout le piano, 2 intervalles.";
      break;
    case 5:
      sentence =
        "Niveau 5 : Notes sur 2 octaves, sur tout le piano, 3 intervalles.";
      break;
    case 6:
      sentence =
        "Niveau 6 : Notes sur 2 octaves, sur tout le piano, 4 intervalles.";
      break;
    default:
      sentence = "erreur";
      break;
  }

  return sentence;
}

function BoutonIntervalle(sound, name) {
  let audio = new Audio(sound);
  const start = () => {
    audio.play();
  };

  return (
    <button className={`${style.inactive}`} onClick={start}>
      note {name}
    </button>
  );
}

function GetAnswer(indexNote1, indexNote2) {
  const intervalle = indexNote2 - indexNote1;
  var soluce;

  switch (intervalle) {
    case -24:
      soluce = "Double octave descendante";
      break;
    case -23:
      soluce = "Quatorzième majeure descendante";
      break;
    case -22:
      soluce = "Quatorzième mineure descendante";
      break;
    case -21:
      soluce = "Treizième majeure descendante";
      break;
    case -20:
      soluce = "Treizième mineure descendante";
      break;
    case -19:
      soluce = "Douzième juste descendante";
      break;
    case -18:
      soluce = "Onzième augmentée descendante";
      break;
    case -17:
      soluce = "Onzième juste descendante";
      break;
    case -16:
      soluce = "Dixième majeure descendante";
      break;
    case -15:
      soluce = "Dixième mineure descendante";
      break;
    case -14:
      soluce = "Neuvième majeure descendante";
      break;
    case -13:
      soluce = "Neuvième mineure descendante";
      break;
    case -12:
      soluce = "Octave descendante";
      break;
    case -11:
      soluce = "Septième majeure descendante";
      break;
    case -10:
      soluce = "Septième mineure descendante";
      break;
    case -9:
      soluce = "Sixte majeure descendante";
      break;
    case -8:
      soluce = "Sixte mineure descendante";
      break;
    case -7:
      soluce = "Quinte juste descendante";
      break;
    case -6:
      soluce = "Triton descendant";
      break;
    case -5:
      soluce = "Quarte juste descendante";
      break;
    case -4:
      soluce = "Tierce majeure descendante";
      break;
    case -3:
      soluce = "Tierce mineure descendante";
      break;
    case -2:
      soluce = "Seconde majeure descendante";
      break;
    case -1:
      soluce = "Seconde mineure descendante";
      break;
    case 0:
      soluce = "Unisson";
      break;
    case 1:
      soluce = "Seconde mineure ascendante";
      break;
    case 2:
      soluce = "Seconde majeure ascendante";
      break;
    case 3:
      soluce = "Tierce mineure ascendante";
      break;
    case 4:
      soluce = "Tierce majeure ascendante";
      break;
    case 5:
      soluce = "Quarte juste ascendante";
      break;
    case 6:
      soluce = "Triton ascendant";
      break;
    case 7:
      soluce = "Quinte juste ascendante";
      break;
    case 8:
      soluce = "Sixte mineure ascendante";
      break;
    case 9:
      soluce = "Sixte majeure ascendante";
      break;
    case 10:
      soluce = "Septième mineure ascendante";
      break;
    case 11:
      soluce = "Septième majeure ascendante";
      break;
    case 12:
      soluce = "Octave ascendante";
      break;
    case 13:
      soluce = "Neuvième mineure ascendante";
      break;
    case 14:
      soluce = "Neuvième majeure ascendante";
      break;
    case 15:
      soluce = "Dixième mineure ascendante";
      break;
    case 16:
      soluce = "Dixième majeure ascendante";
      break;
    case 17:
      soluce = "Onzième juste ascendante";
      break;
    case 18:
      soluce = "Onzième augmentée ascendante";
      break;
    case 19:
      soluce = "Douzième juste ascendante";
      break;
    case 20:
      soluce = "Treizième mineure ascendante";
      break;
    case 21:
      soluce = "Treizième majeure ascendante";
      break;
    case 22:
      soluce = "Quatorzième mineure ascendante";
      break;
    case 23:
      soluce = "Quatorzième majeure ascendante";
      break;
    case 24:
      soluce = "Double octave ascendante";
      break;
    default:
      soluce = "erreur";
      break;
  }

  return [soluce, intervalle];
}

function GetNoteIndex(previousNoteIndex, length) {
  var index;
  if (level === 0) {
    const intervalles = [0, 5, 7];
    const random = Math.floor(Math.random() * intervalles.length);
    return intervalles[random];
  } else if (level === -1) {
    const intervalles = [0, 3, 8];
    const random = Math.floor(Math.random() * intervalles.length);
    return intervalles[random];
  } else if (level === -2) {
    const intervalles = [0, 2, 9];
    const random = Math.floor(Math.random() * intervalles.length);
    return intervalles[random];
  } else if (level === -3) {
    const intervalles = [0, 4, 10];
    const random = Math.floor(Math.random() * intervalles.length);
    return intervalles[random];
  } else if (level === -4) {
    const intervalles = [0, 5, 11];
    const random = Math.floor(Math.random() * intervalles.length);
    return intervalles[random];
  } else if (level === -5) {
    const intervalles = [0, 1, 7];
    const random = Math.floor(Math.random() * intervalles.length);
    return intervalles[random];
  } else if (level === -6) {
    const intervalles = [0, 6, 12];
    const random = Math.floor(Math.random() * intervalles.length);
    return intervalles[random];
  } else if (!previousNoteIndex || previousNoteIndex === null || level === 1) {
    // 1ère note de la série ou niveau 1
    index = Math.floor(Math.random() * length);
  } else if (level === 2) {
    // 1 octave d'écart max pour le niveau 2
    const limitebasse = previousNoteIndex - 12;
    index = Math.floor(Math.random() * 24 + limitebasse);
  } else {
    // 2 octaves d'écart max
    const limitebasse = previousNoteIndex - 24;
    index = Math.floor(Math.random() * 48 + limitebasse);
  }

  if (index > 87) {
    // let's avoid being out of bounds!
    index = 87;
  } else if (index < 0) {
    index = 0;
  }

  return index;
}

function GenerateButtons() {
  var note;

  if (level === 1) {
    // niveau 1: notes sur un octave central du piano
    note = new Array(12);
    note[0] = C3;
    note[1] = Cz3;
    note[2] = D3;
    note[3] = Dz3;
    note[4] = E3;
    note[5] = F3;
    note[6] = Fz3;
    note[7] = G3;
    note[8] = Gz3;
    note[9] = A3;
    note[10] = Az3;
    note[11] = B3;
    note[12] = C4;
  } else if (level === 0) {
    note = new Array(3);
    note[0] = C3;
    note[5] = F3;
    note[7] = G3;
  } else if (level === -1) {
    note = new Array(3);
    note[0] = C3;
    note[3] = Dz3;
    note[8] = Gz3;
  } else if (level === -2) {
    note = new Array(3);
    note[0] = C3;
    note[2] = D3;
    note[9] = A3;
  } else if (level === -3) {
    note = new Array(3);
    note[0] = C3;
    note[4] = E3;
    note[10] = Az3;
  } else if (level === -4) {
    note = new Array(3);
    note[0] = C3;
    note[5] = F3;
    note[11] = B3;
  } else if (level === -5) {
    note = new Array(3);
    note[0] = C3;
    note[1] = Cz3;
    note[7] = G3;
  } else if (level === -6) {
    note = new Array(3);
    note[0] = C3;
    note[6] = Fz3;
    note[12] = C4;
  } else {
    // niveau > 1: notes sur tout le piano
    note = new Array(88);

    note[0] = Amin1;
    note[1] = Azmin1;
    note[2] = Bmin1;
    note[3] = C0;
    note[4] = Cz0;
    note[5] = D0;
    note[6] = Dz0;
    note[7] = E0;
    note[8] = F0;
    note[9] = Fz0;
    note[10] = G0;
    note[11] = Gz0;
    note[12] = A0;
    note[13] = Az0;
    note[14] = B0;
    note[15] = C1;
    note[16] = Cz1;
    note[17] = D1;
    note[18] = Dz1;
    note[19] = E1;
    note[20] = F1;
    note[21] = Fz1;
    note[22] = G1;
    note[23] = Gz1;
    note[24] = A1;
    note[25] = Az1;
    note[26] = B1;

    note[27] = C2;
    note[28] = Cz2;
    note[29] = D2;
    note[30] = Dz2;
    note[31] = E2;
    note[32] = F2;
    note[33] = Fz2;
    note[34] = G2;
    note[35] = Gz2;
    note[36] = A2;
    note[37] = Az2;
    note[38] = B2;

    note[39] = C3;
    note[40] = Cz3;
    note[41] = D3;
    note[42] = Dz3;
    note[43] = E3;
    note[44] = F3;
    note[45] = Fz3;
    note[46] = G3;
    note[47] = Gz3;
    note[48] = A3;
    note[49] = Az3;
    note[50] = B3;

    note[51] = C4;
    note[52] = Cz4;
    note[53] = D4;
    note[54] = Dz4;
    note[55] = E4;
    note[56] = F4;
    note[57] = Fz4;
    note[58] = G4;
    note[59] = Gz4;
    note[60] = A4;
    note[61] = Az4;
    note[62] = B4;

    note[63] = C5;
    note[64] = Cz5;
    note[65] = D5;
    note[66] = Dz5;
    note[67] = E5;
    note[68] = F5;
    note[69] = Fz5;
    note[70] = G5;
    note[71] = Gz5;
    note[72] = A5;
    note[73] = Az5;
    note[74] = B5;

    note[75] = C6;
    note[76] = Cz6;
    note[77] = D6;
    note[78] = Dz6;
    note[79] = E6;
    note[80] = F6;
    note[81] = Fz6;
    note[82] = G6;
    note[83] = Gz6;
    note[84] = A6;
    note[85] = Az6;
    note[86] = B6;

    note[87] = C7;
  }

  const indexNote = GetNoteIndex(null, note.length);
  const indexNote2 = GetNoteIndex(indexNote, note.length);
  const indexNote3 = GetNoteIndex(indexNote2, note.length);
  const indexNote4 = GetNoteIndex(indexNote3, note.length);
  const indexNote5 = GetNoteIndex(indexNote4, note.length);

  const Button1 = BoutonIntervalle(note[indexNote], "1");
  const Button2 = BoutonIntervalle(note[indexNote2], "2");
  const [answer, intervalle] = GetAnswer(indexNote, indexNote2);

  const Button3 = BoutonIntervalle(note[indexNote3], "3");
  const [answer2, intervalle2] = GetAnswer(indexNote2, indexNote3);

  const Button4 = BoutonIntervalle(note[indexNote4], "4");
  const [answer3, intervalle3] = GetAnswer(indexNote3, indexNote4);

  const Button5 = BoutonIntervalle(note[indexNote5], "5");
  const [answer4, intervalle4] = GetAnswer(indexNote4, indexNote5);

  return [
    Button1,
    Button2,
    answer,
    intervalle,
    Button3,
    answer2,
    intervalle2,
    Button4,
    answer3,
    intervalle3,
    Button5,
    answer4,
    intervalle4,
  ];
}

function toastify({ response }) {
  const notify = () => toast(response);
  return (
    <ToastContainer
      position="top-center"
      //autoClose={2000} // hereherehere
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      // transition: Bounce,
    >
      {response}
    </ToastContainer>
  );
}

function GetRandomToastAnswer() {
  var success = new Array(10);

  success[0] = "Bonne réponse.";
  success[1] = "Bonne réponse !";
  success[2] = "C'est correct !";
  success[3] = "C'est correct.";
  success[4] = "Correct !";
  success[5] = "Bravo !";
  success[6] = "Super !";
  success[7] = "Oui !";
  success[8] = "C'est bon.";
  success[9] = "C'est bon !";

  var fail = new Array(10);

  fail[0] = "Mauvaise réponse.";
  fail[1] = "Mauvaise réponse !";
  fail[2] = "C'est faux !";
  fail[3] = "C'est faux.";
  fail[4] = "Inexact !";
  fail[5] = "Raté !";
  fail[6] = "C'est inexact.";
  fail[7] = "Non !";
  fail[8] = "Essaie encore.";
  fail[9] = "C'est pas bon !";

  const index = Math.floor(Math.random() * success.length);
  return [success[index], fail[index]];
}

function GetScore(
  intervalle,
  intervalle2,
  intervalle3,
  intervalle4,
  { forceUpdate }
) {
  var update = false;
  const ddi = document.getElementById("ddi");
  const ddiValue = ddi.value;
  const dds = document.getElementById("dds");
  const ddsValue = dds.value;
  if (!ddiValue || !ddsValue) {
    return;
  }

  var userAnswer = ddiValue;

  if (ddsValue == -1) {
    userAnswer = 0 - userAnswer;
  }

  var ddi2;
  var ddiValue2;
  var dds2;
  var ddsValue2;
  var userAnswer2;

  var ddi3;
  var ddiValue3;
  var dds3;
  var ddsValue3;
  var userAnswer3;

  var ddi4;
  var ddiValue4;
  var dds4;
  var ddsValue4;
  var userAnswer4;

  if (level > 3) {
    ddi2 = document.getElementById("ddi2");
    ddiValue2 = ddi2.value;
    dds2 = document.getElementById("dds2");
    ddsValue2 = dds2.value;
    userAnswer2 = ddiValue2;
    if (ddsValue2 == -1) {
      userAnswer2 = 0 - userAnswer2;
    }
    if (level > 4) {
      ddi3 = document.getElementById("ddi3");
      ddiValue3 = ddi3.value;
      dds3 = document.getElementById("dds3");
      ddsValue3 = dds3.value;
      userAnswer3 = ddiValue3;
      if (ddsValue3 == -1) {
        userAnswer3 = 0 - userAnswer3;
      }
      if (level > 5) {
        ddi4 = document.getElementById("ddi4");
        ddiValue4 = ddi4.value;
        dds4 = document.getElementById("dds4");
        ddsValue4 = dds4.value;
        userAnswer4 = ddiValue4;
        if (ddsValue4 == -1) {
          userAnswer4 = 0 - userAnswer4;
        }
      }
    }
  }

  const [SuccessAnswer, FailAnswer] = GetRandomToastAnswer();

  if (level < 4) {
    if (userAnswer == intervalle) {
      // pas de triple égalité ici malheureux !
      score = score + 1;
      toast.success(SuccessAnswer, { autoClose: 2500 });
      update = true;
    } else {
      score = score - 1;
      toast.error(FailAnswer, { autoClose: 2500 });
    }
  } else if (level === 4) {
    if (userAnswer == intervalle && userAnswer2 == intervalle2) {
      // pas de triple égalité ici malheureux !
      score = score + 1;
      toast.success(SuccessAnswer, { autoClose: 2500 });
      update = true;
    } else {
      score = score - 1;
      toast.error(FailAnswer, { autoClose: 2500 });
    }
  } else if (level === 5) {
    if (
      userAnswer == intervalle &&
      userAnswer2 == intervalle2 &&
      userAnswer3 == intervalle3
    ) {
      // pas de triple égalité ici malheureux !
      score = score + 1;
      toast.success(SuccessAnswer, { autoClose: 2500 });
      update = true;
    } else {
      score = score - 1;
      toast.error(FailAnswer, { autoClose: 2500 });
    }
  } else if (level === 6) {
    if (
      userAnswer == intervalle &&
      userAnswer2 == intervalle2 &&
      userAnswer3 == intervalle3 &&
      userAnswer4 == intervalle4
    ) {
      // pas de triple égalité ici malheureux !
      score = score + 1;
      toast.success(SuccessAnswer, { autoClose: 2500 });
      update = true;
    } else {
      score = score - 1;
      toast.error(FailAnswer, { autoClose: 2500 });
    }
  }

  if (score >= 10) {
    level = level + 1;
    score = 0;
  } else if (score <= -10) {
    level = level - 1;
    score = 0;
    update = true;
  }

  if (level > 6) {
    level = 6;
  } else if (level < -6) {
    level = -6;
  }

  if (update) {
    forceUpdate();
  } else if (score > 0) {
    score = 0;
  }
}

function GenerateDropdown(
  intervalle,
  intervalle2,
  intervalle3,
  intervalle4,
  { forceUpdate }
) {
  var dropdownIntervalles;
  var dropdownIntervalles2;
  var dropdownIntervalles3;
  var dropdownIntervalles4;

  if (level < 2) {
    dropdownIntervalles = (
      <select id="ddi">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
      </select>
    );
    dropdownIntervalles2 = (
      <select id="ddi2">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
      </select>
    );
    dropdownIntervalles3 = (
      <select id="ddi3">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
      </select>
    );
    dropdownIntervalles4 = (
      <select id="ddi4">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
      </select>
    );
  } else {
    dropdownIntervalles = (
      <select id="ddi">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
        <option value="13">Neuvième mineure</option>
        <option value="14">Neuvième majeure</option>
        <option value="15">Dixième mineure</option>
        <option value="16">Dixième majeure</option>
        <option value="17">Onzième juste</option>
        <option value="18">Onzième augmentée</option>
        <option value="19">Douzième juste</option>
        <option value="20">Treizième mineure</option>
        <option value="21">Treizième majeure</option>
        <option value="22">Quatorzième mineure</option>
        <option value="23">Quatorzième majeure</option>
        <option value="24">Double octave juste</option>
      </select>
    );
    dropdownIntervalles2 = (
      <select id="ddi2">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
        <option value="13">Neuvième mineure</option>
        <option value="14">Neuvième majeure</option>
        <option value="15">Dixième mineure</option>
        <option value="16">Dixième majeure</option>
        <option value="17">Onzième juste</option>
        <option value="18">Onzième augmentée</option>
        <option value="19">Douzième juste</option>
        <option value="20">Treizième mineure</option>
        <option value="21">Treizième majeure</option>
        <option value="22">Quatorzième mineure</option>
        <option value="23">Quatorzième majeure</option>
        <option value="24">Double octave juste</option>
      </select>
    );
    dropdownIntervalles3 = (
      <select id="ddi3">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
        <option value="13">Neuvième mineure</option>
        <option value="14">Neuvième majeure</option>
        <option value="15">Dixième mineure</option>
        <option value="16">Dixième majeure</option>
        <option value="17">Onzième juste</option>
        <option value="18">Onzième augmentée</option>
        <option value="19">Douzième juste</option>
        <option value="20">Treizième mineure</option>
        <option value="21">Treizième majeure</option>
        <option value="22">Quatorzième mineure</option>
        <option value="23">Quatorzième majeure</option>
        <option value="24">Double octave juste</option>
      </select>
    );
    dropdownIntervalles4 = (
      <select id="ddi4">
        <option value="0">Unisson</option>
        <option value="1">Seconde mineure</option>
        <option value="2">Seconde majeure</option>
        <option value="3">Tierce mineure</option>
        <option value="4">Tierce majeure</option>
        <option value="5">Quarte juste</option>
        <option value="6">Triton</option>
        <option value="7">Quinte juste</option>
        <option value="8">Sixte mineure</option>
        <option value="9">Sixte majeure</option>
        <option value="10">Septième mineure</option>
        <option value="11">Septième majeure</option>
        <option value="12">Octave juste</option>
        <option value="13">Neuvième mineure</option>
        <option value="14">Neuvième majeure</option>
        <option value="15">Dixième mineure</option>
        <option value="16">Dixième majeure</option>
        <option value="17">Onzième juste</option>
        <option value="18">Onzième augmentée</option>
        <option value="19">Douzième juste</option>
        <option value="20">Treizième mineure</option>
        <option value="21">Treizième majeure</option>
        <option value="22">Quatorzième mineure</option>
        <option value="23">Quatorzième majeure</option>
        <option value="24">Double octave juste</option>
      </select>
    );
  }

  const dropdownSens = (
    <select id="dds">
      <option value="1">Ascendante</option>
      <option value="-1">Descendante</option>
    </select>
  );

  const dropdownSens2 = (
    <select id="dds2">
      <option value="1">Ascendante</option>
      <option value="-1">Descendante</option>
    </select>
  );

  const dropdownSens3 = (
    <select id="dds3">
      <option value="1">Ascendante</option>
      <option value="-1">Descendante</option>
    </select>
  );

  const dropdownSens4 = (
    <select id="dds4">
      <option value="1">Ascendante</option>
      <option value="-1">Descendante</option>
    </select>
  );

  const start = ({ forceUpdate }) => {
    GetScore(intervalle, intervalle2, intervalle3, intervalle4, {
      forceUpdate,
    });
  };

  const dropdownButton = (
    <button
      className={`${style.inactive}`}
      onClick={() => start({ forceUpdate })}
    >
      Répondre
    </button>
  );

  return [
    dropdownIntervalles,
    dropdownSens,
    dropdownIntervalles2,
    dropdownSens2,
    dropdownIntervalles3,
    dropdownSens3,
    dropdownIntervalles4,
    dropdownSens4,
    dropdownButton,
  ];
}

export default function EarTrainer() {
  const { blog } = useContext(ArticlesContext);
  // RANDOM EAR TRAINER ARTICLE
  const shuffleEar = blog.filter(
    (obj) => obj.category === "Oreille" && obj.enable === true
  );
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  shuffleArray(shuffleEar);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  var [
    Button1,
    Button2,
    answer,
    intervalle,
    Button3,
    answer2,
    intervalle2,
    Button4,
    answer3,
    intervalle3,
    Button5,
    answer4,
    intervalle4,
  ] = GenerateButtons();
  var [
    dropdownIntervalles,
    dropdownSens,
    dropdownIntervalles2,
    dropdownSens2,
    dropdownIntervalles3,
    dropdownSens3,
    dropdownIntervalles4,
    dropdownSens4,
    dropdownButton,
  ] = GenerateDropdown(intervalle, intervalle2, intervalle3, intervalle4, {
    forceUpdate,
  });
  var levelSentence = GetLevelSentence();
  var hide = "";

  if (level < 6) {
    Button5 = hide;
    dropdownIntervalles4 = hide;
    dropdownSens4 = hide;
  }
  if (level < 5) {
    Button4 = hide;
    dropdownIntervalles3 = hide;
    dropdownSens3 = hide;
  }
  if (level < 4) {
    Button3 = hide;
    dropdownIntervalles2 = hide;
    dropdownSens2 = hide;
  }

  return (
    <>
      <Helmet>
        <title>Frédéric Szymañski - Interactif : eartrainer</title>
      </Helmet>
      <main className={`${style.earTrainer} d-flex f-column jcc`}>
        <section className={style.section}>
          <h1>EAR TRAINER</h1>
          <p> {levelSentence} </p>
          {Button1} {Button2} {Button3} {Button4} {Button5}
          <br />
          {dropdownIntervalles} {dropdownSens} {dropdownIntervalles2}{" "}
          {dropdownSens2} {dropdownIntervalles3} {dropdownSens3}{" "}
          {dropdownIntervalles4} {dropdownSens4}
          {dropdownButton}
        </section>
        <section>
          <div className={`${style.navigationFooter} d-flex f-column aic jcc`}>
            <h2>Quelques articles traitant de l'oreille musicale</h2>
            <div className={`d-flex aic jcc f-wrap`}>
              {shuffleEar.slice(0, 3).map((a, i) => (
                <Card key={i} data={a} />
              ))}
            </div>
          </div>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </main>
    </>
  );
}
