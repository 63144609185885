const URL_API = process.env.REACT_APP_URL_API_ARTICLES;

export async function readArticles() {
  try {
    const response = await fetch(`${URL_API}/read`);
    if (response.ok) {
      const responseFromBack = await response.json();
      return responseFromBack;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function readArticlesById(id) {
  try {
    const response = await fetch(`${URL_API}/readById?id=${id}`);
    if (response.ok) {
      const responseFromBack = await response.json();
      return responseFromBack;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function counterView(id, counter) {
  try {
    await fetch(`${URL_API}/counterView?id=${id}&counter=${counter}`);
  } catch (error) {
    console.error(error);
  }
}
